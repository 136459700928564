import styled, { css } from 'styled-components';
import { color } from '../../styles/variables';
import { fonts } from '../../styles/typography';
import { hover, media } from '../../styles/mixins';

export const PaymentParent = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin: 25px 0 10px;

  ${media.FBP`
    margin: 25px 100px 10px 0;
  `};
  ${media.PM`
    margin: 25px 0 10px;
  `};
  ${media.IE`
    width: 400px;
  `};
`;

export const PaymentSvgContainer = styled.span`
  margin: 0 10px 15px;
  ${media.FBP`    
    &:last-child {
      margin-right: 0;
    }
  `};
  > svg {
    width: auto;
    height: 24px;
  }
  ${media.IE`
    flex: 1;
  `};
`;

export const BottomArea = styled.div`
  width: 100%;
  background-color: #fff;
  > button + div {
    display: block;
    border: none;
    ${media.FBP`
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      flex-direction: row-reverse;  
    `};
  }
`;

export const CopyrightTag = styled.p`
  ${fonts.GoudyOldStyleItalic};
  font-size: 16px;
  text-align: center;
  margin-top: 0;
  margin-bottom: 25px;
  ${media.FBP`
    margin-bottom: 0;
  `};
  &:before {
    content: '\00a9';
    margin-right: 5px;
  }
`;

export const BackToTopButton = styled.button`
  border: none;
  cursor: pointer;
  &:focus {
    outline: none;
  }
  width: 100%;
  padding: 19px 20px 17px;
  background-color: #cabfb1;
  color: #fff;
  ${fonts.NeoSansRegular};
  font-size: 14px;
  display: block;
  ${media.FBP`
    display: none;
  `};
`;

export const ListItemLink = styled.a`
  color: black;
  text-decoration: none;
  opacity: 1;
  transition: opacity 0.1s ease-in-out;
  ${hover`
    opacity: 0.45;
  `};
`;

export const ListItem = styled.li`
  ${fonts.NeoSansLight};
  margin-bottom: 5px;
  font-size: 14px;
  ${props =>
    props.heading &&
    css`
      ${fonts.NeoSansRegular};
      font-size: 14px;
      margin-bottom: 30px;
    `};

  ${props =>
    !props.heading &&
    css`
      display: none;
    `};

  ${media.FBP`
    margin-bottom: 5px;
    ${props =>
      !props.heading &&
      css`
        display: list-item;
        margin-bottom: 10px;
      `};
    ${props =>
      props.heading &&
      css`
        margin-bottom: 15px;
      `};
    ${props =>
      props.lastChild &&
      css`
        margin-bottom: 15px;
      `};
  `};
`;

export const FooterDataList = styled.div`
  list-style: none;
  width: 50%;
  padding: 0;
  margin: 0;
  ${media.L`
    width: calc(100% / 3);
  `};
  text-align: center;
  ${media.FBP`
    text-align: left;
  `};
`;

export const LogoArea = styled.div`
  width: 33%;
  > svg {
    height: 25px;
  }
  display: none;
  ${media.L`
    display: flex;
    justify-content: flex-start;
  `};
`;

export const IconContainerLink = styled.a`
  background-color: #cabfb1;
  border: 1px solid #cabfb1;
  border-radius: 50px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.1s ease-in-out;
  svg {
    height: '14px';
    width: '20px';
  }
  svg path {
    fill: #ffffff;
    transition: fill 0.1s ease-in-out;
  }
  ${hover`
    background-color: transparent;
    border: 1px solid #cabfb1;
    svg path {
      fill: #cabfb1 !important;
    }
  `};
`;

export const IconItem = styled.li`
  margin-right: 11px;
  &:last-child {
    margin-right: 0;
  }
  svg {
    width: 20px;
  }
`;

export const SocialIconsList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  margin-bottom: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const InnerRightArea = styled.div`
  padding-left: 0;
  border-left: none;
  max-width: 350px;
  text-align: center;
  p {
    ${fonts.GoudyOldStyleRegular};
    font-size: 16px;
    line-height: 1.5;
    margin: 0 0 20px;
  }
  ${media.FBP`
    text-align: left;
    padding-left: 50px;
    border-left: 1px solid ${color.borderGrey};
  `};
`;

export const LeftArea = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;

  ${media.FBP`
    align-items: flex-start;
    flex-direction: row;
    margin-bottom: 0;
  `};
`;

export const RightArea = styled.div`
  max-width: 428px;
  margin-bottom: 0;
  min-width: auto;
  ${media.XL`
    min-width: 350px;
  `};
`;

export const FooterContainer = styled.div`
  flex-shrink: 0;
  padding: 60px 0 0;
  background-color: #f4f4ef;
  ${media.FBP`
    padding: 80px 0 0;
  `};
  > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    &:first-child {
      margin-bottom: 60px !important;
    }
    ${media.FBP`
      align-items: flex-start;
      flex-direction: row;   
      &:first-child{
        margin-bottom: 80px !important;
      }
    `};
  }
`;

export const SubscribeLink = styled.button`
  ${fonts.NeoSansRegular};
  font-size: 14px;
  position: relative;
  background-color: transparent;
  border: none;
  cursor: pointer;
  &:focus {
    outline: none;
  }
  width: 100%;
  padding: 18px 20px;
  margin: 0;
  color: ${color.black};
  border: 1px solid ${color.hoverGrey};
  transition: background-color 0.25s ease 0s, color 0.25s ease 0s, border-color 0.25s ease 0s;

  p {
    font-size: 14px;
    font-family: inherit;
    margin: 0;
    padding: 0;
  }

  ${hover`    
    color: #fff;
    background-color: #000;
    border-color: #000;
  `};
`;

export const MobileNewsletterImageWrapper = styled.div`
  height: calc(100vh - 560px); // default height value
  min-height: 160px; // fallback value

  @supports (height: 100dvh) {
    // if dvh is supported, use it
    // better solution for mobile devices
    height: calc(28dvh);
  }
`;
