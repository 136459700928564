const EU_COUNTRY_CODES = [
  'at',
  'be',
  'bg',
  'hr',
  'cy',
  'cz',
  'dk',
  'ee',
  'fi',
  'fr',
  'de',
  'gr',
  'hu',
  'ie',
  'it',
  'lv',
  'lt',
  'lu',
  'mt',
  'nl',
  'pl',
  'pt',
  'ro',
  'sk',
  'si',
  'es'
];

/**
 * Create correct hreflangs list for EU.
 * @param {string[]} markets - list of markets
 * @return {{market: string, locale: string}[]} array with langs
 */

export const createHrefLangs = markets => {
  return markets.flatMap((market, _idx, arr) => {
    if (market.toLowerCase().includes('eu')) {
      const languageCode = market.split('-')[0];

      return EU_COUNTRY_CODES.reduce((acc, code) => {
        const locale = `${languageCode}-${code}`;
        if (!arr.includes(locale)) acc.push({ market, locale });
        return acc;
      }, []);
    }
    return { market, locale: market };
  });
};
