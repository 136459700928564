import React from 'react';
import { useDispatch } from 'react-redux';
import { color } from '../../styles/variables';
import { Icon } from '../Icon/Icon';
import { ICONS } from '../../constants/icons';
import { useWebsiteString } from '../../hooks/useWebsiteString';
import { setViewstate } from '../../store/actions/actions';
import { BannerBackgroundColor, BannerContainer, BannerContent, BannerText, CloseButton } from './styles';

export const CampaignCodeBanner = () => {
  const dispatch = useDispatch();

  return (
    <BannerBackgroundColor>
      <BannerContainer noMargin>
        <BannerContent>
          <BannerText>{useWebsiteString('campaignCodeBanner')}</BannerText>
          <CloseButton
            onClick={() => dispatch(setViewstate('campaignBannerHidden', true))}
            aria-label={useWebsiteString('ariaLabelClose')}
            title={useWebsiteString('ariaLabelClose')}
          >
            <Icon icon={ICONS.CLOSE_SMALL} size={12} color={color.white}/>
          </CloseButton>
        </BannerContent>
      </BannerContainer>
    </BannerBackgroundColor>
  );
};

