import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { Conditional } from '../Conditional/Conditional';
import { fonts } from '../../styles/typography';
import { color } from '../../styles/variables';
import { Icon } from '../Icon/Icon';
import { ICONS } from '../../constants/icons';
import { LocalizedLink } from '../LocalizedLink/LocalizedLink';

export class CategoryItem extends React.Component {
  render() {
    const { categoryItem, onClick, showCount } = this.props;

    if (categoryItem.children && categoryItem.children.length > 0) {
      return (
        <Container onClick={() => onClick(categoryItem)}>
          <CategoryName>
            <CategoryText red={categoryItem.title === 'Sale'}>{categoryItem.title}</CategoryText>
            <Conditional show={showCount && categoryItem.count}>
              <CategoryCount>{`(${categoryItem.count})`}</CategoryCount>
            </Conditional>
            <Arrow>
              <Icon icon={ICONS.ARROW} width={12} height={12} />
            </Arrow>
          </CategoryName>
        </Container>
      );
    }
    return (
      <Container>
        <LocalizedLink as={`${categoryItem.id}`} page={`${categoryItem.type}`} data={{ id: categoryItem.id }}>
          <CategoryName>
            <CategoryText red={categoryItem.title === 'Sale' || categoryItem.title === 'Deals'}>
              {categoryItem.title}
            </CategoryText>
          </CategoryName>
        </LocalizedLink>
      </Container>
    );
  }
}

const Container = styled.div`
  padding: 0 15px;
  background-color: #fff;
  &:last-of-type {
    > a {
      border-bottom: none !important;
    }
  }
`;

const CategoryName = styled.a`
  display: flex;
  align-items: center;
  height: 56px;
  border-bottom: 1px solid ${color.borderGrey};
  color: #000;
  text-decoration: none;
`;

const CategoryText = styled.div`
  ${fonts.NeoSansRegular};
  font-size: 14px;
  ${props =>
    props.red &&
    css`
      color: ${color.red};
    `};
`;

const CategoryCount = styled.div`
  font-size: 15px;
  font-style: italic;
  font-weight: 300;
`;

const Arrow = styled.div`
  width: 20px;
  height: 20px;
  margin-left: auto;
  margin-right: 5px;

  > svg {
    transform: rotate(90deg);
    height: auto;
    width: 12px;
  }
`;

CategoryItem.defaultProps = {
  categoryItem: {},
  onClick: null,
  showCount: 0
};

CategoryItem.propTypes = {
  onClick: PropTypes.func,
  categoryItem: PropTypes.object,
  showCount: PropTypes.number
};
