// pages
export const EDITORIAL_PAGE = 'EditorialPage';
export const EDITORIAL_LIST_PAGE = 'EditorialListPage';
export const MAIN_CATEGORY_PAGE = 'MainCategoryPage';
export const CATEGORY_PAGE = 'CategoryPage';
export const BLOCK_PAGE = 'BlockPage';
export const EXTERNAL_PAGE = 'ExternalLink';
export const NEWS_PAGE = 'NewsPage';
export const MY_PAGES_START_PAGE = 'MyPagesStartPage';
export const MY_PAGES_ORDERS_PAGE = 'MyPagesOrdersPage';
export const MY_PAGES_USER_DETAILS_PAGE = 'MyPagesUserDetailsPage';
export const MY_PAGES_ORDER_DETAILS_PAGE = 'MyPagesOrderDetailsPage';
export const MY_PAGES_REMOVE_ACCOUNT_PAGE = 'MyPagesRemoveAccountPage';
export const NOT_FOUND_PAGE = 'NotFoundPage';

export const START_PAGE = 'StartPage';

// blocks
export const HERO_IMAGE_BLOCK = 'HeroImageBlock';
export const HERO_TEXT_BLOCK = 'HeroTextBlock';
export const MAGIC_BLOCK = 'MagicBlock';
export const GROUPED_PRODUCT_BLOCK = 'GroupedProductBlock';
export const THREE_PUFF_BLOCK = 'ThreePuffBlock';
export const FEATURED_PRODUCTS_BLOCK = 'FeaturedProductsBlock';
export const FAQ_BLOCK = 'FaqBlock';
export const EDITOR_BLOCK = 'EditorBlock';
export const NEWSLETTER_BLOCK = 'NewsletterBlock';
export const PRODUCT_CATEGORY_LISTING_BLOCK = 'ProductCategoryListingBlock';
export const PRODUCT_LIST_LISTING_BLOCK = 'ProductListListingBlock';
export const GROUPED_PRODUCT_WITH_IMAGES_BLOCK = 'GroupedProductWithImageBlock';
export const CTA_BLOCK = 'CTABlock';
export const VIDEO_BLOCK = 'VideoBlock';
export const HERO_SWIPE_BLOCK = 'HeroSwipeBlock';

// presentation modes (on blocks)
export const NON_FILTER_LIST_PRESENTATION = 'NonFilterList';
export const FILTER_LIST_PRESENTATION = 'FilterList';
export const CAROUSEL_PRESENTATION = 'Carousel';

// products
export const HOUDINI_PRODUCT = 'HoudiniProduct';
export const REUSE_PRODUCT = 'ReuseProduct';
export const GIFT_CARD = 'GiftCard';
export const MADE_TO_ORDER_PRODUCT = 'MadeToOrderProduct';
