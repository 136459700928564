/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash/fp';
import { LocalizedLink } from '../LocalizedLink/LocalizedLink';
import { Conditional } from '../Conditional/Conditional';
import { String } from '../String/String';
import { RocketImage } from '../RocketImage/RocketImage';
import styles from './Navigation.module.scss';

export const MegaMenuProduct = (props) => {
  const { product, onLinkClicked } = props;
  const { title, images, nColors, price, id, type } = product || {};

  return (
    <div className={styles.megaMenuProductRoot}>
      {title && (
        <>
          <LocalizedLink as={id} page={type} data={{ id }}>
            <a onClick={onLinkClicked}>
              <RocketImage
                className={styles.productImage}
                loadInstantly
                src={get([0, 'urlPath'], images)}
                alt={get([0, 'alt'], images)}
                heightRatio={1.5}
                quality={90}
                sizes="300px"
              />
            </a>
          </LocalizedLink>

          <LocalizedLink as={id} page={type} data={{ id }}>
            <a className={styles.productTitle} onClick={onLinkClicked}>
              {title}
            </a>
          </LocalizedLink>

          <p className={styles.productVariantsText}>
            {nColors} <String id="colorsAvailable" />
          </p>

          <div className={styles.productPriceContainer}>
            <Conditional show={!!get('reduced', price)}>
              <p
                className={`${styles.productPrice} ${styles.productPriceReduced}`}
              >
                {get('list', price)}
              </p>
              <p className={styles.productPrice}>{get('reduced', price)}</p>
            </Conditional>
            <Conditional show={!get('reduced', price)}>
              <p className={styles.productPrice}>{get('list', price)}</p>
            </Conditional>
          </div>
        </>
      )}
    </div>
  );
};

MegaMenuProduct.defaultProps = {
  product: {},
};

MegaMenuProduct.propTypes = {
  product: PropTypes.object,
  onLinkClicked: PropTypes.func.isRequired,
};
