import styled, { css } from 'styled-components';

export const PlaceholderImage = styled.img<{
  relative?: boolean;
}>`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: blur(20px);
  transform: scale(1.04);

  ${({ relative }) => relative && `position: relative;`}
`;

export const PlaceholderColor = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${(props) => props.color};
`;

export const RocketImageWrapper = styled.div<{
  heightRatio?: number;
}>`
  position: relative;
  overflow: hidden;
  display: flex;
  ${(props) =>
    props.heightRatio &&
    css`
      height: 0;
      padding-bottom: calc(100% * ${props.heightRatio});
    `};
`;

export const ToggleableImage = styled.img<{
  visible?: boolean;
  heightRatio?: number;
  objectPosition?: string;
  transitionDuration?: number;
}>`
  opacity: 0;
  width: 100%;
  transition: opacity ${(props) => props.transitionDuration}ms ease-out;

  ${(props) =>
    props.visible &&
    css`
      opacity: 1;
    `};

  ${(props) =>
    props.heightRatio &&
    css`
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    `};

  ${(props) =>
    !props.heightRatio &&
    css`
      position: relative;
      z-index: 1;
      object-fit: cover;
      object-position: center;
      height: 100%;
    `};

  ${(props) =>
    !props.heightRatio &&
    props.objectPosition &&
    css`
      object-position: ${props.objectPosition};
    `};
`;

export const ToggleableImageWrapper = styled.div<{
  heightRatio?: number;
  visible?: boolean;
  transitionDuration?: number;
  objectPosition?: string;
}>`
  opacity: 0;
  width: 100%;
  transition: opacity ${(props) => props.transitionDuration}ms ease-out;

  ${(props) =>
    props.visible &&
    css`
      opacity: 1;
    `};

  ${(props) =>
    props.heightRatio &&
    css`
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    `};

  ${(props) =>
    !props.heightRatio &&
    css`
      position: relative;
      z-index: 1;
      height: 100%;
    `};

  ${(props) =>
    !props.heightRatio &&
    props.objectPosition &&
    css`
      object-position: ${props.objectPosition};
    `};
`;

export const PlaceholderVideo = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  &:before {
    z-index: 1;
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.3);
  }

  > * {
    z-index: 2;
  }
`;
