import Flag, { type ReactCountryFlagProps } from 'react-country-flag';

type Props = {
  countryCode: ReactCountryFlagProps['countryCode'];
  width?: ReactCountryFlagProps['style']['width'];
  alt?: string;
  svg?: ReactCountryFlagProps['svg'];
  libConfig?: ReactCountryFlagProps;
  className?: string;
};

export const FlagComponent: React.FC<Props> = (props) => {
  const {
    className,
    width = 18,
    countryCode,
    alt,
    svg = true,
    libConfig,
  } = props;

  return (
    <Flag
      className={className}
      countryCode={countryCode}
      style={{ width }}
      alt={alt || `${countryCode.toUpperCase()} flag}`}
      svg={svg}
      {...libConfig}
    />
  );
};
