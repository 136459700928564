import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { media } from '../../styles/mixins';

export const OldBrowserMsg = props => (
  <Msg>
    <p>{props.message}</p>
  </Msg>
);

const Msg = styled.div`
  display: none;
  width: 460px;
  position: absolute;
  top: 125px;
  right: 20px;
  z-index: 100;
  text-align: center;
  background-color: transparent;
  opacity: 0.6;
  p {
    background-color: #fff;
    padding: 10px;
    font-size: 12px;
    border: 1px solid #000;
  }

  ${media.IE`
    display: block;
  `};
`;

OldBrowserMsg.defaultProps = {
  message: null
};

OldBrowserMsg.propTypes = {
  message: PropTypes.string
};
