import React from 'react';
import { get } from 'lodash/fp';
import PropTypes from 'prop-types';
import { LocalizedLink } from '../LocalizedLink/LocalizedLink';
import { ICONS } from '../../constants/icons';
import { Icon } from '../Icon/Icon';
import { LogoSvg } from '../Icon/reactIcons';
import { Container } from '../Container/Container';
import { BackToPage, EqualWidthContainer, LogoLink, LogoContainer, HeaderContainer } from './styles';
import { connect } from '../../store/store';
import { Conditional } from '../Conditional/Conditional';

const SimpleHeaderComponent = props => {
  const { scrollAtTop, websiteStrings, isMobile } = props;

  return (
    <HeaderContainer belowTop={!scrollAtTop} isMobile={isMobile} simplified>
      <Container noMargin>
        <div style={{ height: '60px' }}>
          <Conditional show={!isMobile}>
            <LocalizedLink as="" page="StartPage" data={{ id: '' }}>
              <BackToPage>
                <Icon icon={ICONS.ARROWLEFT} size={12} />
                <span>{get('continueShoppingLabel', websiteStrings)}</span>
              </BackToPage>
            </LocalizedLink>
          </Conditional>

          <EqualWidthContainer simple>
            <LocalizedLink as="" page="StartPage" data={{ id: '' }}>
              <LogoLink>
                <LogoContainer isMobile={isMobile} simplified belowTop={!scrollAtTop}>
                  <LogoSvg width="100%" height="25px" />
                </LogoContainer>
              </LogoLink>
            </LocalizedLink>
          </EqualWidthContainer>
        </div>
      </Container>
    </HeaderContainer>
  );
};

SimpleHeaderComponent.defaultProps = {
  scrollAtTop: false,
  websiteStrings: {},
  isMobile: false
};

SimpleHeaderComponent.propTypes = {
  scrollAtTop: PropTypes.bool,
  websiteStrings: PropTypes.object,
  isMobile: PropTypes.bool
};

export const SimpleHeader = connect(
  SimpleHeaderComponent,
  {},
  store => ({
    websiteStrings: get(['bootstrap', 'data', 'websiteStrings'], store),
    isMobile: get('viewState.isMobile', store)
  })
);
