import React from 'react';
import PropTypes from 'prop-types';
import { LocalizedLink } from '../LocalizedLink/LocalizedLink';
import { LogoSvg } from '../Icon/reactIcons';
import DepictSearch from './DepictSearch';
import { CartButton } from './CartButton';
import { Navigation } from '../Navigation/Navigation';
import { LogoContainer } from './styles';
import styles from './HeaderDesktop.module.scss';

export const HeaderDesktop = (props) => {
  const { scrollAtTop } = props;

  return (
    <div className={styles.headerDesktopContainer}>
      <div className={styles.headerDesktopSideColumns}>
        <LocalizedLink as="" page="StartPage" data={{ id: '' }}>
          <LogoContainer belowTop={!scrollAtTop}>
            <LogoSvg width="100%" height="25px" />
          </LogoContainer>
        </LocalizedLink>
      </div>

      <Navigation />

      <div className={`${styles.headerDesktopSideColumns} ${styles.right}`}>
        <DepictSearch />
        <CartButton />
      </div>
    </div>
  );
};

HeaderDesktop.defaultProps = {
  scrollAtTop: false,
};

HeaderDesktop.propTypes = {
  scrollAtTop: PropTypes.bool,
};
