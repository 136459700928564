import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';

import { setViewstate } from '../../../store/actions/actions';
import { resetPassword } from '../../../api/myPages';
import { Input } from '../../FormElements/Input';
import ErrorMessage from '../ErrorMessage';

import { RESET_PASSWORD_PARAM } from '../../../constants/queryStrings';
import { LOGIN_PWD_RESET } from '../../../constants/modals';

import { Wrapper, Title, Form, SendButton } from '../styles';

// eslint-disable-next-line no-unused-vars
const ResetPassword = () => {
  const dispatch = useDispatch();
  const market = useSelector(state => state?.viewState?.market);
  const websiteStrings = useSelector(state => state?.bootstrap?.data?.websiteStrings);

  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    mode: 'onChange'
  });

  const resetUrl = () => window.history.replaceState(null, null, window.location.pathname);

  const onSubmit = formData => {
    const params = new URLSearchParams(window.location.search);
    const token = params.get(RESET_PASSWORD_PARAM);
    if (token) {
      resetPassword({ ...formData, token }, { market })
        .then(response => {
          if (response?.status === 200) {
            dispatch(setViewstate('activeModal', LOGIN_PWD_RESET));
            resetUrl();
          }
        })
        .catch(({ errorMessages }) => {
          setErrorMessage(errorMessages);
          setShowError(true);
        });
    }
  };

  if (showError) {
    return <ErrorMessage errorMessage={errorMessage} />;
  }

  return (
    <Wrapper>
      <Title>{websiteStrings?.myPagesResetPassword || 'Reset your password'}</Title>

      <Form onSubmit={handleSubmit(onSubmit)}>
        <Input
          name="newPassword"
          type="password"
          label="New password"
          placeholder=""
          register={register('newPassword', {
            required: 'Required',
            onChange: val => {
              // eslint-disable-next-line no-undef
              newPassword.current = val;
            }
          })}
          error={errors?.newPassword}
        />
        <Input
          name="confirmNewPassword"
          type="password"
          label="Retype new password"
          placeholder=""
          register={register('confirmNewPassword', {
            required: 'Required',
            validate: value => {
              return (
                // eslint-disable-next-line no-undef
                value === newPassword.value ||
                websiteStrings?.myPagesRegisterValidationPasswordNoMatch ||
                "Passwords don't match"
              );
            }
          })}
          error={errors?.confirmNewPassword}
        />
        <SendButton>{websiteStrings?.myPagesResetPasswordSetPassword || 'Set new password'}</SendButton>
      </Form>
    </Wrapper>
  );
};

export default ResetPassword;
