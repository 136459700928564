import React from 'react';
import PropTypes from 'prop-types';
import { MegaMenuLinkList } from './MegaMenuLinkList';
import { MegaMenuProduct } from './MegaMenuProduct';
import styles from './Navigation.module.scss';

export const MainCategoryMegaMenu = props => {
  const { navItem, onLinkClicked } = props;
  const { leftColumn, centerColumn, rightColumn, product } = navItem;

  return (
    <div className={`${styles.megaMenuContainer} ${styles.mainCategoryMegaMenuContainer}`}>
      <div className={`${styles.megaMenuColumn} ${styles.left}`}>
        {leftColumn?.length &&
          leftColumn.map(list => (
            <MegaMenuLinkList key={list.id} list={list} maxNumberOfLinks={15} onLinkClicked={onLinkClicked} />
          ))}
      </div>

      <div className={`${styles.megaMenuColumn} ${styles.left}`}>
        {centerColumn?.length &&
          centerColumn.map(list => (
            <MegaMenuLinkList key={list.id} list={list} maxNumberOfLinks={15} onLinkClicked={onLinkClicked} />
          ))}
      </div>

      <div className={styles.product}>
        <MegaMenuProduct
          product={product && Object.keys(product)?.length > 0 && product}
          onLinkClicked={onLinkClicked}
        />
      </div>

      {rightColumn?.length > 0 && (
        <>
          <div className={styles.divider} />

          <div className={styles.megaMenuColumn}>
            {rightColumn?.length &&
              rightColumn.map(list => (
                <MegaMenuLinkList key={list.id} list={list} maxNumberOfLinks={5} onLinkClicked={onLinkClicked} />
              ))}
          </div>
        </>
      )}
    </div>
  );
};

MainCategoryMegaMenu.defaultProps = {
  navItem: {}
};

MainCategoryMegaMenu.propTypes = {
  navItem: PropTypes.object,
  onLinkClicked: PropTypes.func.isRequired
};
