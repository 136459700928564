import { useFetchRecommendations } from '@depict-ai/react-ui';

import {
  DepictProduct,
  ProductCardProductType,
  ProductCardProps,
} from '../types';
import { useEffect, useState } from 'react';
import { parseProductData } from '../helpers/depict';

type CartRecommendationParams = {
  productIds: string[];
  locale: string;
  currency: string;
};

export const useCartRecommendations = ({
  productIds,
  locale = 'en',
  currency = 'EUR',
}: CartRecommendationParams) => {
  const [products, setProducts] = useState<
    Partial<Partial<ProductCardProductType>>[]
  >([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const { fetchRecommendations } = useFetchRecommendations<DepictProduct>();

  useEffect(() => {
    if (!productIds.length || !fetchRecommendations) return;
    setLoading(true);
    fetchRecommendations({
      // keep type 'checkout' for multiple products and 'cross_sell' for single product
      type: productIds.length > 1 ? 'checkout' : 'cross_sell',
      ...(productIds.length > 1
        ? { productIds }
        : { productId: productIds[0] }),
    })
      .then((products) => {
        setProducts(
          products.map((product) => ({
            ...parseProductData(product, locale, currency),
            depictConfig: {
              recommendationId: product.recommendation_id,
            },
          }))
        );
      })
      .catch(setError)
      .finally(() => setLoading(false));
  }, [currency, fetchRecommendations, locale, productIds]);

  return { products, loading, error };
};

type ProductPageRecommendationParams = {
  productId: string;
  locale: string;
  currency: string;
};

export const useProductPageRecommendation = ({
  productId,
  locale = 'en',
  currency = 'EUR',
}: ProductPageRecommendationParams) => {
  const [products, setProducts] = useState<
    Partial<ProductCardProps['product']>[]
  >([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const { fetchRecommendations } = useFetchRecommendations<DepictProduct>();

  useEffect(() => {
    if (!productId || !fetchRecommendations) return;
    setLoading(true);
    fetchRecommendations({
      type: 'normal',
      productId,
    })
      .then((data) => {
        setProducts(
          data.map((product) => ({
            ...parseProductData(product, locale, currency),
            depictConfig: {
              recommendationId: product.recommendation_id,
            },
          }))
        );
      })
      .catch(setError)
      .finally(() => setLoading(false));
  }, [currency, fetchRecommendations, locale, productId]);

  return { products, loading, error };
};
