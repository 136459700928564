import React from 'react';
import { useSelector } from 'react-redux';
import { NavigationItem } from './NavigationItem';
import styles from './Navigation.module.scss';

export const Navigation = () => {
  const mainMenu = useSelector(state => state?.menu?.data?.mainMenu);

  return (
    <nav className={styles.navigationContainer}>
      <ul className={styles.navigationList}>
        {mainMenu?.length &&
          mainMenu
            .filter(x => x)
            .map(navItem => (navItem.id !== null ? <NavigationItem key={navItem.id} navItem={navItem} /> : null))}
      </ul>
    </nav>
  );
};
