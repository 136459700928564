import React, { useState, memo, useEffect, useRef } from 'react';
import { ImageProps } from 'next/image';
import {
  RocketImageWrapper,
  PlaceholderColor,
  PlaceholderVideo,
} from './styles';

import { buildURL } from '../../helpers/url';
import Video from '../Video/Video';
import Image from './Image';

const getClosestUpper = (width: number, deviceSizes: number[]) => {
  return (
    deviceSizes.find((d) => d >= width) || deviceSizes[deviceSizes.length - 1]
  );
};

const TARGETS = [180, 320, 640, 750, 1080, 1920, 2048];
const PREVIEW_COLOR = '#f3f4ef';

const keyStr =
  'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=';

const triplet = (e1: number, e2: number, e3: number) =>
  keyStr.charAt(e1 >> 2) +
  keyStr.charAt(((e1 & 3) << 4) | (e2 >> 4)) +
  keyStr.charAt(((e2 & 15) << 2) | (e3 >> 6)) +
  keyStr.charAt(e3 & 63);

const rgbDataURL = (r: number, g: number, b: number) =>
  `data:image/gif;base64,R0lGODlhAQABAPAA${
    triplet(0, r, g) + triplet(b, 255, 255)
  }/yH5BAAAAAAALAAAAAABAAEAAAICRAEAOw==`;

type OldRocketImageProps = {
  src: string;
  alt?: string;
  className?: string;
  previewBase64?: string;

  height?: number;
  width?: number;
  quality?: number;

  rootMargin?: string;
  objectPosition?: string;
  coverImage?: string;

  fractionOfDeviceWidth?: number;
  heightRatio?: number;

  loadInstantly?: boolean;

  isVideoThumb?: boolean;

  relative?: boolean;

  mediaType?: string;

  activeSlide?: number;
  activeModal?: string;

  layout?: ImageProps['layout'];

  onClick?: (e: React.MouseEvent<HTMLImageElement, MouseEvent>) => void;
};

type RocketImageProps = {
  sizes?: ImageProps['sizes'];
} & OldRocketImageProps;

export const RocketImage: React.FC<RocketImageProps> = memo((props) => {
  const {
    src = undefined,
    alt = '',
    coverImage = '',
    className = '',
    heightRatio = undefined,
    previewBase64 = undefined,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onClick = () => {},
    loadInstantly = false,
    mediaType = '',
    activeSlide = 0,
    activeModal = '',
    isVideoThumb = false,
    layout = 'fill',
    width: widthProp,
    height: heightProp,
    sizes,
    quality = 90,
  } = props;

  const [error, setError] = useState(!src);
  const [containerWidth, setContainerWidth] = useState(null);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!ref.current) return;

    const elementWidth =
      ref.current.getBoundingClientRect().width * devicePixelRatio;
    const standardWidth = getClosestUpper(elementWidth, TARGETS);
    setContainerWidth(standardWidth);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [src]);

  const preview = previewBase64 || rgbDataURL(243, 244, 239);

  if (mediaType !== 'Movie') {
    // ? If not a video, use the Image component
    if (error) {
      return (
        <RocketImageWrapper
          heightRatio={heightRatio}
          className={className}
          onClick={onClick}
        >
          <PlaceholderColor color={PREVIEW_COLOR} />
        </RocketImageWrapper>
      );
    }

    return (
      <RocketImageWrapper
        heightRatio={heightRatio}
        className={className}
        onClick={onClick}
        ref={ref}
      >
        {isVideoThumb && <PlaceholderVideo />}
        <Image
          src={src}
          alt={alt}
          sizes={sizes}
          priority={loadInstantly}
          width={layout !== 'fill' ? widthProp || containerWidth : undefined}
          height={
            layout !== 'fill'
              ? heightProp || (widthProp || containerWidth) * heightRatio
              : undefined
          }
          fill={layout === 'fill' || !(widthProp && heightProp)}
          blurString={preview}
          onError={() => setError(true)}
          quality={quality}
        />
      </RocketImageWrapper>
    );
  }

  return (
    <RocketImageWrapper
      ref={ref}
      heightRatio={heightRatio}
      className={className}
      onClick={mediaType !== 'Movie' ? onClick : null}
    >
      {ref && mediaType === 'Movie' && (
        <Video
          cover={buildURL(coverImage, {
            heightRatio,
            mode: 'crop',
            width: containerWidth,
          })}
          src={src}
          activeSlide={activeSlide}
          activeModal={activeModal}
          absolute
          muted
          loop
        />
      )}
    </RocketImageWrapper>
  );
});

RocketImage.displayName = 'RocketImage';
