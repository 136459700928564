import React from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';

import { ICONS } from '../../constants/icons';
import { SlideIn, FadeIn } from '../../styles/animations';
import { Icon } from '../Icon/Icon';

import {
  Portal,
  FadedBackground,
  ModalContainer,
  SlideInContainer,
  CloseModalButton,
} from './styles';

export class Modal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      scrollPosition: null,
    };
    this.modalRoot = null;
    this.escFunction = this.escFunction.bind(this);
  }

  componentDidMount() {
    this.modalRoot =
      typeof document !== 'undefined'
        ? document.getElementById('portal-root')
        : null;
    document.addEventListener('keydown', this.escFunction, false);
  }

  static getDerivedStateFromProps(props) {
    if (props.in === true) {
      return { scrollPosition: window.pageYOffset };
    }
    return null;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.in === false && this.props.in === true) {
      this.activate(this.state.scrollPosition);
    } else if (prevProps.in === true && this.props.in === false) {
      this.deactivate(this.state.scrollPosition);
    }
  }

  componentWillUnmount() {
    this.deactivate(this.state.scrollPosition);
  }

  closeModal(event, targetCheck) {
    if (!targetCheck || (targetCheck && event.currentTarget === event.target)) {
      this.props.onClose();
    }
  }

  escFunction(event) {
    if (event.keyCode === 27) {
      this.closeModal();
    }
  }

  activate(scrollPosition) {
    document.body.style.left = 0;
    document.body.style.right = 0;
    document.body.style.top = `-${scrollPosition}px`;
    document.body.style.overflowY = 'scroll';
    document.body.style.position = 'fixed';
  }

  deactivate(scrollPosition) {
    document.body.removeAttribute('style');
    if (!scrollPosition) return;
    window.scrollTo(0, scrollPosition);
  }

  render() {
    if (!this.modalRoot) {
      return null;
    }

    if (!this.props.in) {
      return null;
    }

    if (this.props.slideIn) {
      // return a slide in modal
      return createPortal(
        <Portal
          active={this.props.in}
          mobileMenu={this.props.mobileMenu}
          campaignCodeBannerActive={this.props.campaignCodeBannerActive}
          belowTop={this.props.belowTop}
        >
          <FadeIn in={this.props.in} timeout={this.props.duration}>
            <FadedBackground onClick={(e) => this.closeModal(e, true)} />
          </FadeIn>
          <SlideIn
            in={this.props.in}
            fromLeft={this.props.fromLeft}
            timeout={this.props.duration}
          >
            <SlideInContainer
              minWidth={this.props.minWidth}
              width={this.props.width}
              maxWidth={this.props.maxWidth}
              noSidePadding={this.props.noSidePadding}
              cart={this.props.cart}
              mobileMenu={this.props.mobileMenu}
            >
              <CloseModalButton
                onClick={(e) => this.closeModal(e)}
                mobileMenu={this.props.mobileMenu}
              >
                <Icon icon={ICONS.CLOSE} size={15} />
              </CloseModalButton>
              {this.props.children}
            </SlideInContainer>
          </SlideIn>
        </Portal>,
        this.modalRoot
      );
    }

    // return a classic modal
    return createPortal(
      <Portal active={this.props.in}>
        <FadeIn in={this.props.in} timeout={this.props.duration}>
          <FadedBackground onClick={(e) => this.closeModal(e, true)}>
            <ModalContainer
              onClick={() => {}}
              minWidth={this.props.minWidth}
              width={this.props.width}
              maxWidth={this.props.maxWidth}
              noSidePadding={this.props.noSidePadding}
              noTopBottomPadding={this.props.noTopBottomPadding}
            >
              <CloseModalButton onClick={(e) => this.closeModal(e)}>
                <Icon icon={ICONS.CLOSE} size={15} />
              </CloseModalButton>
              {this.props.children}
            </ModalContainer>
          </FadedBackground>
        </FadeIn>
      </Portal>,
      this.modalRoot
    );
  }
}

Modal.defaultProps = {
  duration: 400,
  minWidth: '290px',
  width: '540px',
  maxWidth: '90vw',
  slideIn: false,
  fromLeft: false,
  mobileMenu: false,
  noSidePadding: false,
  noTopBottomPadding: false,
  cart: false,
  campaignCodeBannerActive: false,
  belowTop: false,
};

Modal.propTypes = {
  in: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  duration: PropTypes.number,
  minWidth: PropTypes.string,
  width: PropTypes.string,
  maxWidth: PropTypes.string,
  slideIn: PropTypes.bool,
  fromLeft: PropTypes.bool,
  mobileMenu: PropTypes.bool,
  noSidePadding: PropTypes.bool,
  noTopBottomPadding: PropTypes.bool,
  cart: PropTypes.bool,
  campaignCodeBannerActive: PropTypes.bool,
  belowTop: PropTypes.bool,
};
