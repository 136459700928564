/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import PropTypes from 'prop-types';
import { take } from 'lodash/fp';
import { LocalizedLink } from '../LocalizedLink/LocalizedLink';
import { String } from '../String/String';
import styles from './Navigation.module.scss';

export const MegaMenuLinkList = (props) => {
  const { list, maxNumberOfLinks, onLinkClicked } = props;
  const { id, type, title, children } = list;

  if (!id && !children?.length) return null;

  const toManyLinks = children.length > maxNumberOfLinks;
  const displayLinks = toManyLinks
    ? take(maxNumberOfLinks - 2, children)
    : children;
  return (
    <>
      <LocalizedLink as={id} page={type} data={{ id }}>
        <a className={styles.mainLink} onClick={onLinkClicked}>
          {title}
        </a>
      </LocalizedLink>

      <ul className={styles.megaMenuList}>
        {displayLinks?.length &&
          displayLinks.map((item, idx) => (
            <li
              key={item.id || item.title || idx}
              className={styles.megaMenuListItem}
            >
              <LocalizedLink
                as={item.id}
                page={item.type}
                data={{ id: item.id }}
              >
                <a className={styles.megaMenuListLink} onClick={onLinkClicked}>
                  {item.title}
                </a>
              </LocalizedLink>
            </li>
          ))}
      </ul>

      {toManyLinks && (
        <div>
          <LocalizedLink as={id} page={type} data={{ id }}>
            <a className={styles.seeAllLinks} onClick={onLinkClicked}>
              <String id="seeAllArticles" />
            </a>
          </LocalizedLink>
        </div>
      )}
    </>
  );
};

MegaMenuLinkList.defaultProps = {
  list: {},
  maxNumberOfLinks: 10,
};

MegaMenuLinkList.propTypes = {
  list: PropTypes.object,
  maxNumberOfLinks: PropTypes.number,
  onLinkClicked: PropTypes.func.isRequired,
};
