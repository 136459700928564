import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { get, size } from 'lodash/fp';
import { LocalizedLink } from '../LocalizedLink/LocalizedLink';
import { ProductCardPrice } from '../ProductCardPrice/ProductCardPrice';
import { String } from '../String/String';
import { RocketImage } from '../RocketImage/RocketImage';
import {
  SideImage,
  CartItemContainer,
  Increase,
  Decrease,
  ModifyArea,
  CurrentNumOfProducts,
  IncrementArea,
  Heading,
  ModelAndSize,
  PriceHolder,
  PriceRightArea,
  RightContainer,
  TrashCan,
  ReuseInformation,
  RightContainerRoot,
  CartItemContent,
  CartProductMessage,
  CloseMessageButton,
} from './styles';
import { Conditional } from '../Conditional/Conditional';
import { color } from '../../styles/variables';
import { Icon } from '../Icon/Icon';
import { ICONS } from '../../constants/icons';
import { Reuse } from '../Icon/reactIcons';
import { ERROR_ADD_TO_CART } from '../../constants/requestStatuses';
import { GIFT_CARD } from '../../constants/cmsTypes';

export const CartItem = (props) => {
  const { orderRow, orderRowsSize, noninteractive, reuseProduct, request } =
    props;
  const hasUpdated = useRef(false);
  const [showMessage, setShowMessage] = useState(false);
  const [message, setMessage] = useState(request?.message);

  const showTooLargeAmountMessage = () => {
    setShowMessage(true);
  };

  useEffect(() => {
    if (!message && request.message) setMessage(request.message);
    if (
      request.status === ERROR_ADD_TO_CART &&
      request.productId === get(['product', 'size', 'articleId'], orderRow) &&
      hasUpdated.current
    ) {
      showTooLargeAmountMessage();
    }

    if (!hasUpdated.current) hasUpdated.current = true;
  }, [request.status]);

  return (
    <CartItemContainer border={orderRowsSize !== 1} removed={props.removed}>
      <CartItemContent reuseProduct={reuseProduct}>
        <SideImage>
          <RocketImage
            src={get(['product', 'images', 0, 'urlPath'], orderRow)}
            alt={get(['product', 'images', 0, 'alt'], orderRow)}
            heightRatio={1.5}
            width={66}
            height={99}
            quality={50}
            layout="fixed"
          />
        </SideImage>
        <RightContainerRoot>
          <RightContainer>
            <IncrementArea>
              <LocalizedLink
                as={get(['product', 'id'], orderRow)}
                data={{ id: get(['product', 'id'], orderRow) }}
                page="HoudiniProduct"
              >
                <Heading>{get(['product', 'title'], orderRow)}</Heading>
              </LocalizedLink>
              {orderRow.product?.type !== GIFT_CARD && (
                <ModelAndSize>{`${get(
                  ['product', 'color', 'title'],
                  orderRow
                )}, Size ${get(
                  ['product', 'size', 'shortTitle'],
                  orderRow
                )}`}</ModelAndSize>
              )}
              <PriceHolder mobile>
                {size(get(['rowPrice', 'totalReduced'], orderRow)) > 0 ? (
                  <React.Fragment>
                    <ProductCardPrice oldPrice>
                      {get(['rowPrice', 'totalList'], orderRow)}
                    </ProductCardPrice>
                    <ProductCardPrice>
                      {get(['rowPrice', 'totalReduced'], orderRow)}
                    </ProductCardPrice>
                  </React.Fragment>
                ) : (
                  <ProductCardPrice>
                    {get(['rowPrice', 'totalList'], orderRow)}
                  </ProductCardPrice>
                )}
              </PriceHolder>
              {orderRow.product?.type !== GIFT_CARD ? (
                noninteractive ? (
                  <ModelAndSize>
                    <String id="cartQuantityLabel" />{' '}
                    {get(['quantity'], orderRow)}
                  </ModelAndSize>
                ) : (
                  <ModifyArea>
                    {!noninteractive && (
                      <Decrease
                        onClick={() => {
                          props.subtract(orderRow);
                          setShowMessage(false);
                        }}
                        disabled={orderRow.quantity === 1}
                      >
                        <svg width="16" height="16" viewBox="0 0 16 16">
                          <g fillRule="evenodd">
                            <path d="M4.6 7.56a.43.43 0 1 0 0 .85h6.8a.41.41 0 0 0 .43-.41.46.46 0 0 0-.43-.46l-6.8.02z" />
                            <path
                              fillRule="nonzero"
                              d="M8 16A8 8 0 1 1 8 0a8 8 0 0 1 0 16M8 .7A7.3 7.3 0 1 0 15.31 8 7.31 7.31 0 0 0 8 .7"
                            />
                          </g>
                        </svg>
                      </Decrease>
                    )}
                    <CurrentNumOfProducts>
                      {orderRow.quantity}
                    </CurrentNumOfProducts>
                    {!noninteractive && (
                      <Increase
                        onClick={() => {
                          props.add(
                            get(['product', 'size', 'articleId'], orderRow)
                          );
                        }}
                      >
                        <svg width="16" height="16" viewBox="0 0 16 16">
                          <g fillRule="evenodd">
                            <path
                              fillRule="nonzero"
                              d="M8 16A8 8 0 1 1 8 0a8 8 0 0 1 0 16M8 .7A7.3 7.3 0 1 0 15.31 8 7.31 7.31 0 0 0 8 .7"
                            />
                            <path d="M7.56 4.6v3h-3a.43.43 0 1 0 0 .85h3v3a.43.43 0 1 0 .85 0v-3h3a.43.43 0 1 0 0-.85h-3v-3a.43.43 0 1 0-.85 0" />
                          </g>
                        </svg>
                      </Increase>
                    )}
                  </ModifyArea>
                )
              ) : (
                ''
              )}
            </IncrementArea>
            <PriceRightArea>
              <PriceHolder mobile={false}>
                {size(get(['rowPrice', 'totalReduced'], orderRow)) > 0 ? (
                  <React.Fragment>
                    <ProductCardPrice oldPrice>
                      {get(['rowPrice', 'totalList'], orderRow)}
                    </ProductCardPrice>
                    <ProductCardPrice>
                      {get(['rowPrice', 'totalReduced'], orderRow)}
                    </ProductCardPrice>
                  </React.Fragment>
                ) : (
                  <ProductCardPrice>
                    {get(['rowPrice', 'totalList'], orderRow)}
                  </ProductCardPrice>
                )}
              </PriceHolder>
              {noninteractive ? (
                false
              ) : (
                <TrashCan onClick={() => props.removeItem(orderRow)}>
                  <svg width="13" height="15" viewBox="0 0 13 15">
                    <g fillRule="evenodd">
                      <path d="M6.46 12.84a.48.48 0 0 0 .48-.46V4.93a.45.45 0 0 0-.44-.47.46.46 0 0 0-.44.47v7.45a.42.42 0 0 0 .4.46zm1.75 0a.48.48 0 0 0 .5-.44l.4-7.4a.47.47 0 0 0-.42-.49.45.45 0 0 0-.47.44l-.39 7.44a.43.43 0 0 0 .38.45zm-3.46 0a.46.46 0 0 0 .42-.5l-.39-7.43a.48.48 0 0 0-.5-.44.44.44 0 0 0-.39.53l.4 7.44a.45.45 0 0 0 .46.4z" />
                      <path
                        fillRule="nonzero"
                        d="M12.56 2.29H9.31v-1A1.29 1.29 0 0 0 8 0H5a1.29 1.29 0 0 0-1.31 1.31v1H.44a.45.45 0 0 0-.44.44.46.46 0 0 0 .44.47h1.19l.63 10.42A1.42 1.42 0 0 0 3.64 15h5.72a1.42 1.42 0 0 0 1.38-1.35l.64-10.43h1.18a.42.42 0 0 0 .44-.43.49.49 0 0 0-.44-.5zm-7.93-1A.33.33 0 0 1 5 1h3a.33.33 0 0 1 .33.33v1h-3.7V1.29zm5.23 12.3a.51.51 0 0 1-.5.48H3.64a.5.5 0 0 1-.49-.49L2.52 3.22h8l-.66 10.37z"
                      />
                    </g>
                  </svg>
                </TrashCan>
              )}
            </PriceRightArea>
          </RightContainer>
        </RightContainerRoot>
      </CartItemContent>

      <Conditional show={reuseProduct}>
        <ReuseInformation>
          <Reuse width={20} heigth={20} />
          <div>
            <span>
              <String id="cartReuseLabel1" />{' '}
            </span>
            <span>
              <String id="cartReuseLabel2" />
            </span>
          </div>
        </ReuseInformation>
      </Conditional>

      <CartProductMessage in={showMessage ? 1 : 0}>
        <String id="cartOutOfStockMessage" />
        <CloseMessageButton
          in={showMessage ? 1 : 0}
          onClick={() => setShowMessage(false)}
        >
          <Icon icon={ICONS.CLOSE_SMALL} size={8} color={color.black} />
        </CloseMessageButton>
      </CartProductMessage>
    </CartItemContainer>
  );
};

CartItem.defaultProps = {
  noninteractive: false,
  removed: false,
  add: () => {},
  subtract: () => {},
  removeItem: () => {},
  reuseProduct: false,
  request: {},
};

CartItem.propTypes = {
  noninteractive: PropTypes.bool,
  orderRow: PropTypes.object.isRequired,
  orderRowsSize: PropTypes.number.isRequired,
  add: PropTypes.func,
  subtract: PropTypes.func,
  removeItem: PropTypes.func,
  removed: PropTypes.bool,
  reuseProduct: PropTypes.bool,
  request: PropTypes.object,
};
