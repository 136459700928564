import React, { Component } from 'react';
import { compose, get, map, getOr, slice, size } from 'lodash/fp';
import { withRouter } from 'next/router';
import PropTypes from 'prop-types';
import { animateScroll } from 'react-scroll';

import { connect } from 'store/store';
import { setViewstate, removeViewstate } from 'store/actions/actions';

import { MARKET_SELECT, NEWSLETTER } from 'constants/modals';
import { ICONS } from 'constants/icons';
import { MARKETS } from 'constants/serverConstants';

import * as GTM from 'helpers/gtm';

import { Modal } from 'components/Modal/Modal';
import { Container } from 'components/Container/Container';
import { NewsletterSignup } from 'components/NewsletterSignup/NewsletterSignup';
import { Icon } from 'components/Icon/Icon';
import Image from 'components/RocketImage/Image';
import { String } from 'components/String/String';
import {
  LogoSvg,
  PaymentAmericanExpressSvg,
  PaymentKlarnaSvg,
  PaymentMasterCardSvg,
  PaymentPayPalSvg,
  PaymentVisaSvg,
  PaymentGiroPaySvg,
  PaymentSepaSvg,
  PaymentSofortSvg,
} from 'components/Icon/reactIcons';
import { LocalizedLink } from 'components/LocalizedLink/LocalizedLink';
import { ChangeLanguage } from 'components/Header/styles';
import { FlagComponent } from 'components/FlagComponent/FlagComponent';

import {
  PaymentParent,
  PaymentSvgContainer,
  BottomArea,
  CopyrightTag,
  BackToTopButton,
  ListItemLink,
  ListItem,
  FooterDataList,
  LogoArea,
  IconContainerLink,
  IconItem,
  SocialIconsList,
  InnerRightArea,
  LeftArea,
  RightArea,
  FooterContainer,
  SubscribeLink,
  MobileNewsletterImageWrapper,
} from './styles';
import { FlexContainer, FlexItem } from 'styles/grid';
import { RocketImage } from '../RocketImage/RocketImage';

class FooterComponent extends Component {
  constructor(props) {
    super(props);

    this.toggleNewsletterModal = this.toggleNewsletterModal.bind(this);
    this.closeNewsletterModal = this.closeNewsletterModal.bind(this);
    this.showMarketSelectModal = this.showMarketSelectModal.bind(this);

    this.state = {
      countryCode: '',
      countryNativeName: '',
    };
  }

  componentDidMount() {
    this.setState({
      countryCode:
        window?.sessionStorage?.getItem('countryCode') || this.props.country,
      countryNativeName:
        window?.sessionStorage?.getItem('countryNativeName') || '',
    });
  }

  onSocialLinkClick(key) {
    GTM.socialLinkClickEvent(key);
  }

  toggleNewsletterModal() {
    if (get('activeModal', this.props) === NEWSLETTER) {
      this.closeNewsletterModal();
    } else {
      GTM.newsletterManualOpenEvent();
      this.props.setViewstate('activeModal', NEWSLETTER);
    }
  }

  showMarketSelectModal() {
    this.props.setViewstate('activeModal', MARKET_SELECT);
  }

  closeNewsletterModal() {
    this.props.removeViewstate('activeModal');
  }

  scrollToTop() {
    animateScroll.scrollToTop();
  }

  renderFooterLinks(footerItems) {
    const convertMap = map.convert({ cap: false });
    return map(
      (item) => (
        <React.Fragment key={get('id', item)}>
          <ListItem heading>
            <LocalizedLink
              as={get('id', item)}
              page={get('type', item)}
              data={{ id: get('id', item) }}
            >
              <ListItemLink>{get('title', item)}</ListItemLink>
            </LocalizedLink>
          </ListItem>
          {convertMap(
            (child, index) => (
              <ListItem
                heading={false}
                key={get('id', child)}
                lastChild={size(getOr([], 'children', item)) === index + 1}
              >
                <LocalizedLink
                  as={get('id', child)}
                  page={get('type', child)}
                  data={{ id: get('id', child) }}
                >
                  <ListItemLink>{get('title', child)}</ListItemLink>
                </LocalizedLink>
              </ListItem>
            ),
            getOr([], 'children', item)
          )}
        </React.Fragment>
      ),
      footerItems
    );
  }

  render() {
    const {
      footerMenu,
      socialLinks,
      websiteStrings,
      newsletter,
      activeModal,
      market,
      isMobile,
      country,
      language,
    } = this.props;

    const socialIconLinks = Object.keys(socialLinks)
      .filter((key) => get(key, socialLinks))
      .map((key) => (
        <IconItem key={`social-footer-icon_${key}`}>
          <IconContainerLink
            href={get(key, socialLinks)}
            target="_blank"
            onClick={() => this.onSocialLinkClick(key)}
          >
            <Icon icon={ICONS[key.toUpperCase()]} size={14} color="#ffffff" />
          </IconContainerLink>
        </IconItem>
      ));

    const firstColumn = this.renderFooterLinks(slice(0, 1, footerMenu));
    const secondColumn = this.renderFooterLinks(
      slice(1, size(footerMenu), footerMenu)
    );

    return (
      <FooterContainer>
        <Container noMargin>
          <LeftArea>
            <LogoArea>
              <LogoSvg />
            </LogoArea>
            <FooterDataList>{firstColumn}</FooterDataList>
            <FooterDataList>
              {secondColumn}
              {this.state.countryCode && (
                <ChangeLanguage onClick={this.showMarketSelectModal} footer>
                  <FlagComponent
                    countryCode={this.state.countryCode.toUpperCase()}
                  />
                  <p>
                    {this.state.countryCode === 'gl'
                      ? 'Global'
                      : this.state.countryNativeName ||
                        `${country.toUpperCase()}/${language.toUpperCase()}`}
                  </p>
                </ChangeLanguage>
              )}
            </FooterDataList>
          </LeftArea>
          <RightArea>
            <InnerRightArea>
              <SocialIconsList>{socialIconLinks}</SocialIconsList>
              <div
                dangerouslySetInnerHTML={{
                  __html: get('footerText', newsletter),
                }}
              />
              <SubscribeLink onClick={this.toggleNewsletterModal}>
                <p>
                  {get('newsletterSubscriptionButtonLabel', websiteStrings)}
                </p>
              </SubscribeLink>
              <Modal
                onClose={this.closeNewsletterModal}
                in={activeModal === NEWSLETTER}
                width="80vw"
                maxWidth="720px"
                noSidePadding
                noTopBottomPadding
              >
                <FlexContainer flexDirection={isMobile ? 'column' : ''}>
                  {get('image', newsletter) ? (
                    <>
                      <FlexItem col={isMobile ? 12 : 4}>
                        {isMobile ? (
                          <MobileNewsletterImageWrapper>
                            <Image
                              src={get('mobileImage.urlPath', newsletter)}
                              fill
                            />
                          </MobileNewsletterImageWrapper>
                        ) : (
                          <Image src={get('image.urlPath', newsletter)} fill />
                        )}
                      </FlexItem>
                      <FlexItem col={isMobile ? 12 : 8} padding={40}>
                        <NewsletterSignup
                          heading={get('heading', newsletter)}
                          body={get('newsletterTextbox', newsletter)}
                          openInModal
                        />
                      </FlexItem>
                    </>
                  ) : (
                    <FlexItem col={12} padding={isMobile ? 24 : 40}>
                      <NewsletterSignup
                        heading={get('heading', newsletter)}
                        body={get('newsletterTextbox', newsletter)}
                        openInModal
                      />
                    </FlexItem>
                  )}
                </FlexContainer>
              </Modal>
            </InnerRightArea>
          </RightArea>
        </Container>
        <BottomArea>
          <BackToTopButton
            onClick={() => {
              this.scrollToTop();
            }}
          >
            <String id="scrollToTop" />
          </BackToTopButton>
          <Container noMargin>
            <PaymentParent>
              <PaymentSvgContainer>
                <PaymentMasterCardSvg />
              </PaymentSvgContainer>
              <PaymentSvgContainer>
                <PaymentVisaSvg />
              </PaymentSvgContainer>
              <PaymentSvgContainer>
                <PaymentAmericanExpressSvg />
              </PaymentSvgContainer>

              {(market === MARKETS.SV_SE || market === MARKETS.EN_NO) && (
                <PaymentSvgContainer>
                  <PaymentKlarnaSvg />
                </PaymentSvgContainer>
              )}

              {market !== MARKETS.SV_SE && market !== MARKETS.EN_NO && (
                <PaymentSvgContainer>
                  <PaymentPayPalSvg />
                </PaymentSvgContainer>
              )}

              {(market === MARKETS.DE_DE || market === MARKETS.EN_DE) && (
                <>
                  <PaymentSvgContainer>
                    <PaymentSofortSvg />
                  </PaymentSvgContainer>

                  <PaymentSvgContainer>
                    <PaymentGiroPaySvg />
                  </PaymentSvgContainer>

                  <PaymentSvgContainer>
                    <PaymentSepaSvg />
                  </PaymentSvgContainer>
                </>
              )}
            </PaymentParent>
            <CopyrightTag>
              {get('copyrightNoticeLabel', websiteStrings)}
            </CopyrightTag>
          </Container>
        </BottomArea>
      </FooterContainer>
    );
  }
}

FooterComponent.defaultProps = {
  footerMenu: [],
  socialLinks: {},
  newsletter: {},
  activeModal: null,
  market: null,
  isMobile: false,
  country: null,
  language: null,
};

FooterComponent.propTypes = {
  setViewstate: PropTypes.func.isRequired,
  removeViewstate: PropTypes.func.isRequired,
  footerMenu: PropTypes.array,
  socialLinks: PropTypes.object,
  websiteStrings: PropTypes.object.isRequired,
  newsletter: PropTypes.object,
  activeModal: PropTypes.string,
  market: PropTypes.string,
  isMobile: PropTypes.bool,
  country: PropTypes.string,
  language: PropTypes.string,
};

export const Footer = compose(withRouter, connect)(
  FooterComponent,
  { setViewstate, removeViewstate },
  (store) => ({
    footerMenu: get('bootstrap.data.navigation.footerMenu', store),
    socialLinks: get('bootstrap.data.socialLinks', store),
    websiteStrings: get(['bootstrap', 'data', 'websiteStrings'], store),
    newsletter: get(['bootstrap', 'data', 'newsletter'], store),
    activeModal: get('viewState.activeModal', store),
    market: get('viewState.market', store),
    country: get('viewState.country', store),
    language: get('viewState.language', store),
    isMobile: get('viewState.isMobile', store),
  })
);
