import styled, { css } from 'styled-components';
import { ContainerRoot } from '../Container/styles';
import { fonts } from '../../styles/typography';
import { color } from '../../styles/variables';
import { media, opacityHover } from '../../styles/mixins';

export const BannerBackgroundColor = styled.div`
  background-color: ${color.activeGreen};
`;

export const BannerContainer = styled(ContainerRoot)`
  width: 100%;
  height: auto;
  ${props =>
    props.hidden &&
    css`
      display: none;
    `}
`;

export const BannerContent = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center !important;
  align-items: center;
  p {
    margin: 0;
    font-size: 14px;
    line-height: 1.25;
  }
`;

export const BannerText = styled.span`
  ${fonts.NeoSansRegular};
  text-align: center;
  font-size: 12px;
  line-height: 1.25;
  color: ${color.white};
  padding: 10px 20px 11px;

  ${media.S`
    font-size: 14px;
    padding: 10px 0 11px;
  `}

  a {
    color: inherit;
  }
`;

export const CloseButton = styled.button`
  ${opacityHover};
  width: 37px;
  border: none;
  background-color: transparent;
  position: absolute;
  top: 0;
  right: -12px;
  bottom: 0;
  cursor: pointer;
  outline: none;
  border-radius: 0;
`;
