import styled, { css } from 'styled-components';
import { hover } from '../../styles/mixins';
import { fonts } from '../../styles/typography';
import { color } from '../../styles/variables';

export const ChangeLanguage = styled.button`
  display: flex;
  align-items: center;
  width: 100%;
  height: 56px;
  color: ${color.black};
  ${fonts.GoudyOldStyleRegular};
  font-size: 16px;
  position: relative;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #e5e5e5;
  cursor: pointer;
  padding: 0;

  &:focus {
    outline: none;
  }

  > p {
    ${fonts.GoudyOldStyleRegular};
    font-size: 16px;
    margin: 0;
    opacity: 1;
    transition: opacity 0.1s ease-in-out;
    ${hover`
    opacity: .45;
  `};
  }

  > * :first-child {
    width: 14px;
    height: auto;
    margin-right: 8px;
  }
`;

export const Container = styled.div`
  overflow-x: hidden;
  min-height: 100%;
  width: 100%;
  transform: translate3d(0, 0, 0);
`;

export const Slider = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  left: ${props => `calc((${props.level} * -100) * 1%)`};
  width: ${props => `calc(${props.itemslength} * 100%)`};
  min-height: 100%;
  transition: left 400ms;
  transform: translate3d(0, 0, 0);
`;

export const Level = styled.div`
  flex-basis: ${props => `calc(100/${props.level + 1} * 1%)`};
  padding-bottom: 0;
  background-color: #fff;
  ${props =>
    props.isBaseLevel &&
    css`
      background-color: ${color.primary};
    `};
`;

export const BackButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 52px;
  background-color: ${color.primary};
  padding: 0 15px;
  width: 100%;
  border-bottom: none;
  > svg {
    transform: rotate(-90deg);
    height: auto;
    width: 12px;
  }
`;

export const BackButtonText = styled.div`
  ${fonts.NeoSansRegular};
  font-size: 14px;
  margin-left: 10px;
`;

export const CurrentCategory = styled.div`
  padding: 0 15px;
  background-color: #fff;
`;

export const CurrentCategoryLink = styled.a`
  display: flex;
  align-items: center;
  height: 70px;
  border-bottom: 1px solid ${color.borderGrey};
  ${fonts.NeoSansMedium};
  font-size: 16px;
  color: #000;
  text-decoration: none;
`;

export const CurrentCategoryText = styled.div`
  margin-right: 5px;
`;

export const CurrentCategoryCount = styled.div`
  font-size: 15px;
  font-style: italic;
  font-weight: 300;
`;

export const HeaderPages = styled.nav`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 0;
`;

export const HeaderPagesLinkTag = styled.a`
  ${fonts.GoudyOldStyleRegular};
  text-decoration: none;
  font-size: 16px;
  color: #000;
  height: 56px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e5e5e5;
`;

export const HeaderLogin = styled(HeaderPagesLinkTag)`
  cursor: pointer;
`;

export const HeaderPageContainer = styled.div`
  padding: 0 15px;
  width: 100%;
`;
