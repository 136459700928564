import styled, { css } from 'styled-components';
import { media, hover, dynamicPadding } from '../../styles/mixins';

export const Portal = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: 0;
  pointer-events: ${props => (props.active ? 'auto' : 'none')};

  ${props =>
    props.mobileMenu &&
    css`
      top: 98px;
    `};

  ${props =>
    props.mobileMenu &&
    props.belowTop &&
    css`
      top: 61px;
    `};

  ${props =>
    props.mobileMenu &&
    props.campaignCodeBannerActive &&
    css`
      top: 134px;
    `};

  ${props =>
    props.mobileMenu &&
    props.belowTop &&
    props.campaignCodeBannerActive &&
    css`
      top: 97px;
    `};
`;

export const FadedBackground = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.3);
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
`;

export const ModalContainer = styled.div`
  position: relative;
  height: auto;
  max-height: 100vh;

  ${media.XS`
    max-height: 95vh;
  `};

  @supports (height: 100dvh) {
    // if dvh is supported, use it
    // better solution for mobile devices
    max-height: 95dvh;
  }

  box-sizing: border-box;
  background: #fff;
  min-width: ${props => props.minWidth};
  max-width: ${props => props.maxWidth};
  pointer-events: all;
  overflow-y: auto;
  cursor: default;
  -webkit-overflow-scrolling: touch;
  padding: 50px 20px 40px;
  width: 95vw;

  ${media.S`
    padding: 70px 50px;
    width: ${props => props.width};
  `};

  ${dynamicPadding(20, 50)}; /*400 to 768*/

  ${props =>
    props.noSidePadding &&
    css`
      padding-left: 0 !important;
      padding-right: 0 !important;
    `};

  ${props =>
    props.noTopBottomPadding &&
    css`
      padding-top: 0 !important;
      padding-bottom: 0 !important;
    `};
`;

export const LargeModalContainer = styled.div`
  position: relative;
  padding: 0;
  height: auto;
  width: 100%;
  text-align: center;
  margin: 0 auto;
  background-color: #f3f4ee;
  max-height: 100vh;
  box-sizing: border-box;
  pointer-events: all;
  overflow-y: scroll;
  cursor: default;
  -webkit-overflow-scrolling: touch;
`;

export const SlideInContainer = styled.div`
  position: relative;
  height: 100%;
  padding: 40px 15px;
  ${media.S`
    padding: 50px;
  `};

  ${dynamicPadding(15, 50)}; /*400 to 768*/

  box-sizing: border-box;
  background: #fff;
  min-width: ${props => props.minWidth};
  width: ${props => props.width};
  max-width: ${props => props.maxWidth};
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  ${props =>
    props.CART &&
    css`
      padding-bottom: 0;
    `};

  ${props =>
    props.noSidePadding &&
    css`
      padding-left: 0 !important;
      padding-right: 0 !important;
    `};

  ${props =>
    props.mobileMenu &&
    css`
      padding: 0 !important;
    `};
`;

export const CloseModalButton = styled.button`
  position: absolute;
  ${props =>
    props.fixed &&
    css`
      position: fixed;
    `};
  right: 0;
  z-index: 1;
  width: 48px;
  height: 48px;
  border: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  outline: 0;
  cursor: pointer;
  top: 0;
  background: rgba(255, 255, 255, 0.5);
  box-shadow: 0 4px 30px rgb(0 0 0 / 10%);
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);

  ${media.M`
    top: 20px;
    right: 20px;
    background: none;
    box-shadow: none;
    backdrop-filter: none;
    -webkit-backdrop-filter: none;
  `};
  opacity: 1;
  transition: all 0.1s ease-in-out;
  ${hover`
    opacity: 0.45;
  `};
  ${props =>
    props.mobileMenu &&
    css`
      display: none;
    `};
`;
