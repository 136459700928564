import styled from 'styled-components';

export const FlexContainer = styled.div`
  display: flex;
  flex-direction: ${props => props.flexDirection && props.flexDirection};
  flex-wrap: ${({ wrap }) => (wrap ? 'wrap' : 'nowrap')};
  justify-content: ${({ justify }) => justify || 'auto'};
`;

export const FlexItem = styled.div`
  width: calc((100% / 12) * ${props => (props.col ? props.col : 1)});
  position: relative;
  padding: ${props => (props.padding ? props.padding : 0)}px;
`;
