import React from 'react';
import { useCookies } from 'react-cookie';
import PropTypes from 'prop-types';
import { color } from '../../styles/variables';
import { Icon } from '../Icon/Icon';
import { ICONS } from '../../constants/icons';
import { useWebsiteString } from '../../hooks/useWebsiteString';
import { BannerContainer, BannerContent, BannerText, CloseButton } from './styles';

export const BannerMessage = ({ id, type, html }) => {
  // Get cookies and helper functions for persistence.
  const [cookie, setCookie] = useCookies(); // eslint-disable-line

  // Map the banner type to a color from the style guide.
  const typeToColorMap = {
    negative: color.red,
    positive: color.activeGreen
  };

  // Default the message type to positive.
  const backgroundColor = typeToColorMap[type] || typeToColorMap.positive;

  // The content is sent has html for link support
  const dangerousInnerHTML = { __html: html };

  return (
    <div style={{ backgroundColor }}>
      <BannerContainer noMargin>
        <BannerContent>
          <BannerText dangerouslySetInnerHTML={dangerousInnerHTML} />
          <CloseButton
            onClick={() => setCookie(id, true)}
            aria-label={useWebsiteString('ariaLabelClose')}
            title={useWebsiteString('ariaLabelClose')}
          >
            <Icon icon={ICONS.CLOSE_SMALL} size={12} color={color.white} />
          </CloseButton>
        </BannerContent>
      </BannerContainer>
    </div>
  );
};

BannerMessage.propTypes = {
  id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  html: PropTypes.string.isRequired
};
