const buildQuery = data =>
  Object.keys(data)
    .map(key => {
      const value = data[key];
      return value ? `${encodeURIComponent(key)}=${encodeURIComponent(value)}` : false;
    })
    .filter(x => x)
    .join('&');

export const buildURL = (baseUrl, data) => {
  const query = buildQuery(data);
  return `${baseUrl}?${query}`;
};
