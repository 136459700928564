import styled, { css } from 'styled-components';
import { fonts } from '../../styles/typography';
import { dynamicFontSize, media } from '../../styles/mixins';
import { color } from '../../styles/variables';

export const SubHeading = styled.h2<{
  center?: boolean;
  centerMobile?: boolean;
  noUnderline?: boolean;
  mt?: number;
  mb?: number;
  maxSize?: number;
  wideUnderline?: boolean;
  as?: string;
}>`
  ${fonts.GoudyOldStyleRegular};
  ${dynamicFontSize(28, 35)};
  ${({ maxSize }) =>
    maxSize &&
    css`
      ${dynamicFontSize(28, maxSize)};
    `}
  margin: 2em 0 1em;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.14;
  letter-spacing: -0.2px;
  color: #000000;

  ${(props) =>
    typeof props.mt !== 'undefined' &&
    css`
      margin-top: ${props.mt}px !important;
    `};

  ${(props) =>
    typeof props.mb !== 'undefined' &&
    css`
      margin-bottom: ${props.mb}px !important;
    `};

  ${(props) =>
    !props.noUnderline &&
    css`
      &:after {
        content: '';
        display: block;
        margin: 18px 0 10px;
        height: 1px;
        width: 120px;
        background-color: black;
        ${props.wideUnderline &&
        css`
          width: 100%;
          margin: 16px 0 16px;
          background-color: ${color.grey};
        `}
      }
    `};

  ${(props) =>
    props.center &&
    css`
      margin-left: auto;
      margin-right: auto;
      text-align: center;
      &:after {
        margin: 18px auto 0;
      }
    `};

  ${(props) =>
    props.centerMobile &&
    css`
      text-align: center;
      &:after {
        margin: 18px auto 0;
      }
      ${media.M`
      text-align: left;
      &:after {
        margin: 18px 0 10px;
      }
    `};
    `};
`;
