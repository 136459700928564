import React from 'react';
import { compose, get, getOr, size } from 'lodash/fp';
import { withRouter } from 'next/router';
import PropTypes from 'prop-types';
import { connect } from '../../store/store';
import { Container } from '../Container/Container';
import { removeViewstate } from '../../store/actions/actions';
import { HeaderMobile } from './HeaderMobile';
import { HeaderDesktop } from './HeaderDesktop';
import { OldBrowserMsg } from '../OldBrowserMsg/OldBrowserMsg';
import { UspArea } from './UspArea';
import { SimpleHeader } from './SimpleHeader';
import { Conditional } from '../Conditional/Conditional';
import { isIE } from '../../styles/variables';
import { HeaderContainer, Overlay } from './styles';
import { CampaignCodeBanner } from '../Banners/CampaignCodeBanner';
import { BannerMessageList } from '../Banners/BannerMessageList';
import { fetchMessages } from '../../api/houdini';
import GeoLocationArea from './GeoLocationArea';

export class HeaderComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      scrollAtTop: true,
      internetExplorer: false,
      bannerMessages: [],
    };

    this.fetchBannerMessages = this.fetchBannerMessages.bind(this);
    this.closeSearch = this.closeSearch.bind(this);
    this.listenScrollEvent = this.listenScrollEvent.bind(this);
  }

  componentDidMount() {
    window.addEventListener('scroll', this.listenScrollEvent);
    this.fetchBannerMessages();

    if (isIE) {
      this.setState({
        internetExplorer: isIE,
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.market !== this.props.market) {
      this.fetchBannerMessages();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.listenScrollEvent);
  }

  // Fetch market specific messages.
  async fetchBannerMessages() {
    const { market } = this.props;
    let isCancelled = false;

    if (process.env.NEXT_PUBLIC_ENVIRONMENT === 'development') return;

    const response = await fetchMessages({ market });

    if (response && !isCancelled) {
      const { bannerMessages } = response;
      this.setState({ bannerMessages });
    }

    isCancelled = true;
  }

  listenScrollEvent() {
    // these 4 lines solve them problem with our header animating height when we set position fixed on body to prevent scrolling on iOS when a modal i active.
    const bodyElement = document.querySelector('body');
    const bodyStyle = window.getComputedStyle(bodyElement);
    const bodyTop = bodyStyle.getPropertyValue('top');
    const isScrolledAndLocked =
      typeof bodyTop === 'string' && bodyTop.startsWith('-');

    if (
      (window.pageYOffset > 0 && this.state.scrollAtTop) ||
      isScrolledAndLocked
    ) {
      this.setState({ scrollAtTop: false });
    } else if (window.pageYOffset === 0 && !this.state.scrollAtTop) {
      this.setState({ scrollAtTop: true });
    }
  }

  closeSearch() {
    this.props.removeViewstate('activeModal');
  }

  render() {
    const {
      simplified,
      activeMegaMenu,
      isMobile,
      activeCampaignCode,
      routerHistory,
      campaignBannerHidden,
      myPagesEnabled,
    } = this.props;
    const { scrollAtTop, bannerMessages } = this.state;

    if (simplified) {
      return <SimpleHeader />;
    }

    const campaignCodeBannerActive =
      !!activeCampaignCode && size(routerHistory) > 1 && !campaignBannerHidden;

    return (
      <>
        <GeoLocationArea />

        <UspArea scrollAtTop={scrollAtTop} myPagesEnabled={myPagesEnabled} />

        <HeaderContainer
          belowTop={!scrollAtTop}
          isMobile={isMobile}
          simplified={simplified}
        >
          <Conditional show={campaignCodeBannerActive}>
            <CampaignCodeBanner />
          </Conditional>

          <BannerMessageList messages={bannerMessages} isMobile={isMobile} />

          <Container noMargin>
            {isMobile ? (
              <HeaderMobile
                scrollAtTop={scrollAtTop}
                campaignCodeBannerActive={campaignCodeBannerActive}
                myPagesEnabled={myPagesEnabled}
              />
            ) : (
              <HeaderDesktop scrollAtTop={scrollAtTop} />
            )}
          </Container>
        </HeaderContainer>

        <Conditional show={this.state.internetExplorer}>
          <OldBrowserMsg message="This is an old browser. You are welcome to look around, but we really advice you to choose a more modern one, like chrome or firefox." />
        </Conditional>

        <Overlay active={!!activeMegaMenu} />
      </>
    );
  }
}

HeaderComponent.defaultProps = {
  simplified: false,
  isMobile: false,
  activeMegaMenu: null,
  market: null,
  activeCampaignCode: null,
  routerHistory: [],
  campaignBannerHidden: false,
  myPagesEnabled: false,
};

HeaderComponent.propTypes = {
  isMobile: PropTypes.bool,
  market: PropTypes.string,
  simplified: PropTypes.bool,
  removeViewstate: PropTypes.func.isRequired,
  activeMegaMenu: PropTypes.string,
  activeCampaignCode: PropTypes.string,
  routerHistory: PropTypes.arrayOf(PropTypes.string),
  campaignBannerHidden: PropTypes.bool,
  myPagesEnabled: PropTypes.bool,
};

export const Header = compose(withRouter, connect)(
  HeaderComponent,
  { removeViewstate },
  (store) => ({
    activeMegaMenu: get('viewState.activeMegaMenu', store),
    market: get('viewState.market', store),
    activeModal: get('viewState.activeModal', store),
    isMobile: get('viewState.isMobile', store),
    activeCampaignCode: get('cart.data.campaignCode', store),
    routerHistory: get('viewState.routerHistory', store),
    campaignBannerHidden: get('viewState.campaignBannerHidden', store),
    myPagesEnabled: getOr(
      false,
      ['bootstrap', 'data', 'myPagesEnabled'],
      store
    ),
  })
);
