import styled from 'styled-components';
import { TextInput, SendButton } from '../CampaignCodeInput/CampaignCodeInput';
import { fonts } from '../../styles/typography';
import { hover, media } from '../../styles/mixins';
import { HtmlContainer } from '../HtmlWrapper/HtmlWrapper';

export const Root = styled.div`
  max-width: 450px;
  margin: 0 auto;
`;

export const PrivacyPolicyLink = styled.a`
  padding-left: 10px;
`;

export const CenteredRoot = styled(Root)`
  min-height: 320px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const InputGroup = styled.div`
  margin: 17px 0 22px;
`;

export const NewsletterHtmlContainer = styled(HtmlContainer)`
  p {
    ${fonts.GoudyOldStyleRegular};
    font-size: 16px;
    line-height: 1.5;
    text-align: center;
    color: ${props => props.theme.color.black};
    padding: 0;

    ${media.M`
      padding: 0 25px;
    `};
  }
`;

export const ValidationError = styled.p`
  ${fonts.NeoSansRegular};
  font-size: 13px;
  color: #5f2d3a;
`;

export const NewsletterInput = styled(TextInput)`
  display: block;
  width: 100%;
  margin: 2em 0 12px;
  &::-webkit-input-placeholder {
    line-height: 22px !important;
  }
`;

export const NewsletterButton = styled(SendButton)`
  display: block;
  width: 100%;
  margin: 2em 0 0;
  cursor: pointer;
  transition: opacity 250ms ease-out;

  ${hover`
    opacity: .8;
  `};
`;
