import React from 'react';

type Props = {
  show: boolean;
  children: React.ReactNode;
};

export const Conditional: React.FC<Props> = ({ show, children }) => {
  if (!show || React.Children.count(children) === 0) {
    return null;
  }
  return <>{children}</>;
};
