import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';

import { createAccount } from '../../../api/myPages';

import { setViewstate } from '../../../store/actions/actions';

import { LOGIN } from '../../../constants/modals';
import { ICONS } from '../../../constants/icons';

import { Icon } from '../../Icon/Icon';
import { Input } from '../../FormElements/Input';

import { color } from '../../../styles/variables';
import {
  Wrapper,
  Form,
  Title,
  SendButton,
  Action,
  ActionButton,
  NoticeHeading,
  InfoText,
  ErrorText,
  Benefit
} from '../styles';

const CreateAccount = () => {
  const dispatch = useDispatch();
  const market = useSelector(state => state?.viewState?.market);
  const websiteStrings = useSelector(state => state?.bootstrap?.data?.websiteStrings);

  const [accountCreated, setAccountCreated] = useState(false);
  const [error, setError] = useState(false);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors }
  } = useForm();

  const password = useRef({});
  password.current = watch('password', '');

  const onSubmit = formData => {
    setError(false);
    createAccount(formData, { market })
      .then(({ status }) => {
        if (status === 200) setAccountCreated(true);
      })
      .catch(() => {
        setError(true);
        // TODO: Proper error handling with BE / get setError from useForm()
      });
  };

  const openLoginLink = () => dispatch(setViewstate('activeModal', LOGIN));

  return (
    <Wrapper>
      <Title>{websiteStrings?.myPagesCreateAccount || 'Create an account'}</Title>
      <Benefit>{websiteStrings?.myPagesCreateAccountBenefit1 || 'Benefit 1'}</Benefit>
      <Benefit>{websiteStrings?.myPagesCreateAccountBenefit2 || 'Benefit 2'}</Benefit>
      <Benefit>{websiteStrings?.myPagesCreateAccountBenefit3 || 'Benefit 3'}</Benefit>
      {!accountCreated && (
        <>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Input
              name="firstName"
              type="firstName"
              label="First name"
              register={register('firstName', { required: 'Required' })}
              error={errors?.firstName}
            />
            <Input
              name="lastName"
              type="lastName"
              label="Last name"
              register={register('lastName', { required: 'Required' })}
              error={errors?.lastName}
            />
            <Input
              name="email"
              type="email"
              label="Email"
              placeholder="example@email.com"
              register={register('email', {
                required: 'Required',
                pattern: {
                  value: /^([a-zA-Z0-9_\-+.]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,5})$/,
                  message: websiteStrings?.myPagesRegisterValidationEmail || 'Not an email'
                }
              })}
              error={errors?.email}
            />
            <Input
              name="password"
              type="password"
              label="Password"
              placeholder=""
              register={register('password', {
                required: 'Required',
                onChange: val => {
                  password.current = val;
                }
              })}
              error={errors?.password}
            />
            <Input
              name="confirmPassword"
              type="password"
              label="Retype password"
              placeholder=""
              register={register('confirmPassword', {
                required: 'Required',
                validate: value =>
                  value === password.current ||
                  websiteStrings?.myPagesRegisterValidationPasswordNoMatch || "Passwords don't match"
              })}
              error={errors?.confirmPassword}
            />
            {error && <ErrorText>{websiteStrings?.myPagesCreateAccountError || 'Something went wrong'}</ErrorText>}
            <SendButton>{websiteStrings?.myPagesCreateAccount || 'Create an account'}</SendButton>
            <Action>
              {websiteStrings?.myPagesRegisterAlreadyExists || 'Already got an account?'} &nbsp;
              <ActionButton type="button" onClick={openLoginLink}>
                {websiteStrings?.myPagesLogIn || 'Log In'}
              </ActionButton>
            </Action>
          </Form>
        </>
      )}
      {accountCreated && (
        <>
          <NoticeHeading>
            <Icon icon={ICONS.INFO} size={36} color={color.darkSepia} />
            <p>{websiteStrings?.myPagesRegisterAccountCreated || 'Your account has been created!'}</p>
          </NoticeHeading>
          <InfoText>
            {websiteStrings?.myPagesRegisterConfirmEmail ||
              `Confirm your email address in the email we just sent you! If you haven't received the email shortly, please check to see if its gone to your spam folder.`}
          </InfoText>
        </>
      )}
    </Wrapper>
  );
};

export default CreateAccount;
