import styled, { css, keyframes } from 'styled-components';
import { fonts } from '../../styles/typography';
import { hover, media } from '../../styles/mixins';
import { color } from '../../styles/variables';

export const PriceHolder = styled.div`
  text-align: right;
  min-width: 140px;
  margin-bottom: 4px;
  display: none;

  @media screen and (min-width: 560px) {
    margin-bottom: 0;
    display: block;
    position: relative;
    top: -2px;
  }

  ${(props) =>
    props.mobile &&
    css`
      text-align: left;
      display: block;
      @media screen and (min-width: 560px) {
        display: none;
      }
    `}

  ${({ both }) =>
    both &&
    css`
      display: block;
    `}

  ${({ left }) =>
    left &&
    css`
      text-align: left;
    `}
  
  p {
    margin-top: 0px;
  }

  @media screen and (min-width: 560px) {
    margin-bottom: 5px;
  }
`;

export const CurrentNumOfProducts = styled.span`
  ${fonts.NeoSansRegular};
  font-size: 13px;
  position: relative;
  top: 1px;
`;

export const Increase = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  &:focus {
    outline: none;
  }
  margin: 0;
  margin-left: 7px;
  padding: 5px;

  svg {
    opacity: 1;
    transition: opacity 0.1s ease-in-out;
    ${hover`
      opacity: .45;
    `};
    width: 22px;
    height: auto;

    ${media.M`
      width: 16px;
    `};
    ${media.IE`
      height: 16px;
    `};
  }

  ${(props) =>
    props.disabled &&
    css`
      ${hover`
        svg {opacity: 1};
      `};
      opacity: 0.2;
      cursor: default;
    `};

  @media screen and (min-width: 560px) {
    margin-left: 5px;
  }
`;

export const Decrease = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  &:focus {
    outline: none;
  }
  margin: 0;
  margin-right: 7px;
  padding: 5px;

  svg {
    opacity: 1;
    transition: opacity 0.1s ease-in-out;
    ${hover`
      opacity: .45;
    `};
    width: 22px;
    height: auto;

    ${media.M`
      width: 16px;
    `};

    ${media.IE`
      height: 16px;
    `};
  }

  ${(props) =>
    props.disabled &&
    css`
      ${hover`
        svg {opacity: 1};
      `};
      opacity: 0.2;
      cursor: default;
    `};

  @media screen and (min-width: 560px) {
    margin-right: 5px;
  }
`;

export const TrashCan = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  &:focus {
    outline: none;
  }
  margin: 21.5px 0 0;
  padding: 5px;
  padding-right: 0;

  svg {
    opacity: 1;
    transition: opacity 0.1s ease-in-out;
    ${hover`
      opacity: .45;
    `};
    width: auto;
    height: 22px;

    ${media.M`
      height: 16px;
      width: auto;
    `};

    ${media.IE`
      width: 16px;
    `};
  }

  ${(props) =>
    props.disabled &&
    css`
      ${hover`
        svg {opacity: 1};
      `};
      opacity: 0.2;
      cursor: default;
    `};
`;

export const ModifyArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-left: -5px;
  svg {
    display: block;
  }
`;

export const ModelAndSize = styled.div`
  ${fonts.NeoSansLight};
  font-size: 13px;
  margin-bottom: 6px;

  @media screen and (min-width: 560px) {
    margin-bottom: 9px;
  }
`;

export const Heading = styled.a`
  text-decoration: none;
  color: #000;
  display: block;
  ${fonts.NeoSansRegular};
  font-size: 14px;
  margin-bottom: 10px;
  opacity: 1;
  transition: opacity 0.1s ease-in-out 0s;
  ${hover`
    opacity: .45;
  `};
`;

export const PriceRightArea = styled.div`
  text-align: right;
  position: absolute;
  bottom: 0;
  right: 0;
  @media screen and (min-width: 560px) {
    position: static;
  }
`;

export const IncrementArea = styled.div`
  width: 100%;
  text-align: left;

  @media screen and (min-width: 560px) {
    width: auto;
  }
`;

export const RightContainerRoot = styled.div`
  width: calc(
    100% - 88px
  ); // 100% - {image width} - {margin between content and image}
  margin-left: 20px;
`;

export const RightContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
`;

export const SideImage = styled.div`
  width: ${({ width }) => (width ? `${width}px` : '68px')};
`;

export const CartEmptyItem = styled.p`
  width: auto;
  height: 120px;
  margin: 12px 0 0;
  position: absolute;

  ${fonts.GoudyOldStyleRegular};
  font-size: 16px;

  &.fadein-enter {
    opacity: 0.01;
  }

  &.fadein-enter-active {
    opacity: 1;
    transition: opacity 900ms ease-out;
  }
`;

export const ReuseInformation = styled.div`
  padding: 8px;
  background-color: #f9f9f9;
  font-size: 11px;
  line-height: 1.4;
  color: ${color.black};
  margin: 10px 0 18px;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  & > div {
    margin-left: 8px;
  }

  ${media.S`
    font-size: 12px;
    span {
      display: block;
    }
  `}
`;

export const CartItemContainer = styled.li`
  width: 100%;
  background-color: #fff;
  margin: 0 0 20px;
  border-bottom: 1px solid transparent;
  opacity: 1;

  &:last-of-type {
    margin-bottom: 0 !important;
  }

  ${(props) =>
    props.border &&
    css`
      border-bottom: 1px solid ${color.borderGrey};
      &:last-child {
        border-bottom: 0;
      }
    `};

  ${(props) =>
    props.removed &&
    css`
      opacity: 0.01;
      max-height: 0 !important;
      margin-top: 0 !important;
      margin-bottom: 0 !important;
      border: none;
      transition: opacity 500ms ease-out, margin-top 500ms ease-out,
        margin-bottom 500ms ease-out, max-height 500ms ease-out;
    `};

  &.fadeout-leave {
    opacity: 1;
    max-height: 120px;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  &.fadeout-leave-active {
    opacity: 0.01;
    max-height: 0;
    margin-top: 0;
    margin-bottom: 0;
    transition: opacity 500ms ease-out, margin-top 500ms ease-out,
      margin-bottom 500ms ease-out, max-height 500ms ease-out;
  }
`;

export const CartItemContent = styled.div`
  position: relative;
  display: flex;
  align-items: flex-start;

  ${(props) =>
    !props.reuseProduct &&
    css`
      margin-bottom: 20px;
    `}
`;

export const CartProductMessage = styled.div`
  ${fonts.GoudyOldStyleRegular};
  font-size: 16px;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #000000;
  background-color: ${(props) => props.theme.color.lightGrey};
  padding: 0;
  transition: margin-bottom 250ms ease-in-out, max-height 250ms ease-in-out,
    opacity 250ms ease-in-out, padding 250ms ease-in-out;
  max-height: 0;
  opacity: 0;
  padding: 0 45px 0 20px;

  ${(props) =>
    props.in &&
    css`
      position: relative;
      margin-bottom: 10px;
      max-height: 300px;
      opacity: 1;
      padding: 15px 45px 15px 20px;
    `};

  ${(props) =>
    props.mobile &&
    css`
      background: ${props.theme.color.white};
    `};
`;

export const CloseMessageButton = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  width: 38px;
  height: 0;
  margin: 0;
  padding: 0;
  border: none;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: opacity 250ms ease-out;
  cursor: none;
  &:focus {
    outline: none;
  }

  ${(props) =>
    props.in &&
    css`
      height: 38px;
      cursor: pointer;
    `};

  ${hover`
    opacity: .6;
  `};
`;

export const GotoCheckoutButton = styled.a`
  &:focus {
    outline: none;
  }
  ${fonts.NeoSansRegular};
  font-size: 14px;
  color: #fff;
  width: 100%;
  text-decoration: none;
  height: 51px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #000;
  margin-top: 30px;
  transition: opacity 250ms ease-in-out;
  cursor: pointer;
  ${hover`
    opacity: .8;
  `};
`;

export const TotalRow = styled.ul`
  padding-left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  list-style: none;
  margin: 20px 0 16px;
  li {
    ${fonts.NeoSansRegular};
    font-size: 14px;
  }
`;

export const UpperSummaryList = styled.ul`
  display: flex;
  align-items: center;
  justify-content: space-between;
  list-style: none;
  padding: 0;
  margin: 0 0 10px 0;
  li {
    ${fonts.NeoSansLight};
    font-size: 13px;
  }
  + hr {
    margin-top: 20px;
  }
`;

export const InnerSummaryArea = styled.div``;

export const CartItems = styled.ul`
  list-style: none;
  padding: 0;
  margin-top: 50px;

  ${({ disable }) =>
    disable &&
    css`
      opacity: 0.5;
      pointer-events: none;
    `}
`;

export const LoadingWrapper = styled.div`
  position: absolute;

  ${(props) =>
    props.relative &&
    `
    position: relative;
    margin: 2em 0;
  `};

  ${(props) =>
    props.minHeight &&
    `
    min-height: calc(100vh - 98px);
    margin: 0;
  `};

  ${(props) =>
    props.minHeight &&
    media.M`
    min-height: calc(100vh - 113px);
    margin: 0;
  `};

  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const spinAnimation = keyframes`
  to {
    transform: rotate(360deg);
  }
`;

export const LoadingIndicator = styled.div`
  border-radius: 50%;
  height: ${({ size }) => size || 75}px;
  width: ${({ size }) => size || 75}px;
  border: 1px solid ${(props) => props.theme.color.grey};
  border-top: 1px solid ${(props) => props.theme.color.black};
  animation: ${spinAnimation} 1s linear infinite;
`;

export const SummaryContainer = styled.div`
  position: static;
  overflow: hidden;
  bottom: 0;
  left: 0;
  right: 0;

  max-height: 280px;
  width: 100%;
  padding: 40px 15px 30px;
  background-color: #f4f4ef;

  opacity: 1;
  transition: opacity 600ms ease-out, max-height 900ms ease-out;

  @media screen and (min-width: 560px) {
    position: absolute;
    padding: 40px 50px 30px;
  }

  ${(props) =>
    props.vanish &&
    css`
      opacity: 0.01;
      max-height: 0;
    `};

  ${(props) =>
    props.noninteractive &&
    css`
      position: absolute;
      bottom: 0;
      background-color: ${color.white};
      border-top: 1px solid ${color.grey};
    `};
`;

export const UpperScrollArea = styled.div`
  position: relative;
`;

export const UpperCartContainer = styled.div`
  padding: 15px;
  height: auto;

  display: flex;
  flex-direction: column;

  @media screen and (min-width: 560px) {
    padding: 0 50px 15px;
    height: calc(100vh - 330px);
    overflow-y: auto;
  }

  ${(props) =>
    props.noninteractive &&
    css`
      height: calc(100vh - 330px);
      padding-top: 40px;
      overflow-y: scroll;

      @media screen and (min-width: 560px) {
        padding: 40px 50px 20px;
      }
    `};
`;

export const CartContainer = styled.div`
  ${(props) =>
    props.sticky &&
    css`
      position: sticky;
      top: 60px;
      height: calc(100vh - 60px);
    `};
`;
