import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { fonts } from '../../styles/typography';
import { media, dynamicFontSize } from '../../styles/mixins';

export class HtmlWrapper extends React.Component {
  render() {
    const { html, bodyFontSize, widerMaxWidthSet, htmlIn } = this.props;

    if (!html) return null;

    const getHtml = () => ({ __html: html });
    return (
      <HtmlContainer
        widerMaxWidthSet={widerMaxWidthSet}
        bodyFontSize={bodyFontSize}
        dangerouslySetInnerHTML={getHtml()}
        htmlIn={htmlIn}
      />
    );
  }
}

export const HtmlContainer = styled.div`
  * {
    color: #000;
    max-width: 750px;
    margin-left: auto;
    margin-right: auto;

    ${props =>
      props.widerMaxWidthSet &&
      css`
        margin-left: 0;
      `};
  }

  h2 {
    ${fonts.NeoSansRegular};
    margin-top: 30px;
    margin-bottom: 10px;
    font-size: 25px !important;
    line-height: 32px;

    & + p,
    & + ol,
    & + ul {
      margin-top: 0;
    }
  }
  h3,
  .h3 {
    ${fonts.NeoSansMedium};
    margin-top: 30px;
    margin-bottom: 6px;
    font-size: 16px !important;
    font-weight: 500 !important;
    line-height: 24px;

    & + p,
    & + ol,
    & + ul {
      margin-top: 0;
    }
  }
  p {
    margin-top: 30px;
    margin-bottom: 0;
  }
  ol,
  ul {
    margin-top: 30px;
    margin-bottom: 0;
    padding-left: 20px;
  }
  p,
  li {
    ${fonts.GoudyOldStyleRegular};
    color: #000;
    font-size: ${props => props.bodyFontSize}px;
    line-height: 1.48;
  }
  li {
    padding-left: 8px;
  }
  a {
    position: relative;
    background-image: linear-gradient(#000000, #000000);
    background-position: left bottom 2px;
    background-repeat: no-repeat;
    background-size: 100% 1px;
    text-decoration: none;
    transition: opacity 0.25s ease-out;
    &:hover {
      opacity: 0.45;
    }
  }

  img,
  iframe {
    width: 100%;
    max-width: 100%;
    height: auto;
    margin: 40px 0;

    ${media.M`
      margin: 50px 0;
    `};
  }

  iframe {
    margin: 40px 0;
    ${media.M`
      margin: 50px 0;
    `};
  }

  .preamble {
    ${fonts.GoudyOldStyleRegular};
    ${dynamicFontSize(22, 26)};
    text-align: center;
    color: ${props => props.theme.color.black};
    line-height: 1.31;
    margin: 40px auto;

    ${media.M`
      margin: 45px auto 55px;
    `};
  }

  .image-container {
    width: 100%;
    max-width: none;
    font-size: 0;
    margin: 40px 0;
    border: none !important;
    padding: 0 !important;

    ${media.M`
      margin: 50px 0;
    `};

    ${props =>
      props.widerMaxWidthSet &&
      css`
        max-width: 750px;
      `};

    img {
      margin: 0;
    }
  }

  .youtube-container {
    margin: 40px 0;
    ${media.M`
      margin: 50px 0;
    `};
    position: relative;
    height: 0;
    width: 100%;
    max-width: none;
    border: none !important;
    padding: 0 0 56.25% !important;

    ${props =>
      props.widerMaxWidthSet &&
      css`
        max-width: 750px;
      `};

    iframe {
      margin: 0;
      position: absolute;
      top: 0;
      bottom: 0;
      height: 100%;
    }
  }

  q,
  .blockquote {
    ${fonts.GoudyOldStyleItalic};
    font-size: 26px !important;
    font-weight: 400 !important;
    line-height: normal;
    text-align: center;
    margin: 40px auto;

    ${media.M`
      margin: 50px auto;
      font-size: 36px !important;
    `};

    &:before {
      content: '“';
    }
    &:after {
      content: '”';
    }
  }

  q + .blockquote-caption,
  .blockquote + .blockquote-caption,
  .image-caption {
    ${fonts.GoudyOldStyleItalic};
    font-size: 16px;
    line-height: 1.38;
    text-align: center;
    margin-top: -30px;
    margin-bottom: 40px;

    ${media.M`
        margin-top: -40px;
        margin-bottom: 50px;
      `};
  }

  .blockquote + .blockquote-caption {
    margin-top: -25px;

    &:before {
      content: '– ';
    }

    ${media.M`
      margin-top: -35px;
    `};
  }

  ${props =>
    props.htmlIn &&
    css`
      > p:first-child {
        margin-top: 0;
      }
    `};
`;

HtmlWrapper.defaultProps = {
  bodyFontSize: 18,
  widerMaxWidthSet: null,
  htmlIn: false,
  html: null
};

HtmlWrapper.propTypes = {
  html: PropTypes.string,
  bodyFontSize: PropTypes.number,
  widerMaxWidthSet: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  htmlIn: PropTypes.bool
};
