import React from 'react';
import PropTypes from 'prop-types';
import { get, compose, take, map } from 'lodash/fp';
import styles from './Navigation.module.scss';
import { MegaMenuLinkList } from './MegaMenuLinkList';
import { MegaMenuImageList } from './MegaMenuImageList';

const getImageListParent = (children, hasMultipleChildren) => {
  if (!hasMultipleChildren) return null;

  return {
    id: get([1, 'id'], children),
    type: get([1, 'type'], children),
    title: get([1, 'title'], children)
  };
};

const getImageListChildren = (children, hasMultipleChildren, numberOfImages) => {
  const target = hasMultipleChildren ? get([1, 'children'], children) : get([0, 'children'], children);

  return compose(
    map(child => ({
      id: get('id', child),
      type: get('type', child),
      title: get('title', child),
      preamble: get('preamble', child),
      images: get('images', child)
    })),
    take(numberOfImages)
  )(target);
};

export const EditorialMegaMenu = props => {
  const { data, onLinkClicked } = props;
  const { children } = data;

  if (!children?.length) return false;

  const hasMultipleChildren = children.length > 1;

  const numberOfImages = typeof window !== 'undefined' && window.innerWidth < 1200 ? 2 : 3;

  const imageListParentLink = getImageListParent(children, hasMultipleChildren);
  const imageListChildren = getImageListChildren(children, hasMultipleChildren, numberOfImages);

  return (
    <div className={styles.megaMenuContainer}>
      <div className={styles.megaMenuColumn}>
        {children?.length &&
          children.map(list => <MegaMenuLinkList key={list.id} list={list} onLinkClicked={onLinkClicked} />)}
      </div>

      <div className={`${styles.divider} ${!hasMultipleChildren && styles.transparent}`} />

      <MegaMenuImageList
        imageListParentLink={imageListParentLink}
        imageListChildren={imageListChildren}
        onLinkClicked={onLinkClicked}
      />
    </div>
  );
};

EditorialMegaMenu.defaultProps = {
  data: {}
};

EditorialMegaMenu.propTypes = {
  data: PropTypes.object,
  onLinkClicked: PropTypes.func.isRequired
};
