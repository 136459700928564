import React, { useState, useEffect, useRef, Fragment } from 'react';
import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import { fonts } from '../../styles/typography';
import { media } from '../../styles/mixins';
import { Icon } from '../Icon/Icon';
import { ICONS } from '../../constants/icons';
import * as GTM from '../../helpers/gtm';
import { setCartCountry } from '../../api/cart';
import { FlagComponent } from '../FlagComponent/FlagComponent';

export const MarketSelect = () => {
  const ref = useRef();

  const markets = useSelector((state) => state?.markets);
  const websiteStrings = useSelector(
    (state) => state?.bootstrap?.data?.websiteStrings
  );
  const currentMarket = useSelector((state) => state?.viewState.market);

  const [continents, setContinents] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState('');
  const [selectedMarket, setSelectedMarket] = useState({});

  useEffect(() => {
    if (markets.request.statusCode === 200)
      setContinents([
        ...new Set(markets?.data?.map((market) => market.countryGroup)),
      ]);
  }, [markets]);

  useEffect(() => {
    ref.current.scrollTop = 0;
  }, [selectedCountry]);

  const handleLanguageChange = (market, countryCode) => {
    const pathName = window.location.pathname.split('/');
    pathName[1] = market;
    const newPathName = pathName.join('/');

    return setCartCountry({ market, countryCode }).then(() => {
      window.sessionStorage.setItem(
        'countryNativeName',
        markets?.data?.find((m) => m.countryCode === countryCode)
          .countryNameEnglish
      );
      window.sessionStorage.setItem('countryCode', countryCode);
      window.sessionStorage.setItem('marketSelection', market);

      GTM.changeMarketEvent({
        from: currentMarket,
        to: market,
        eventCallback() {
          if (
            window.location.href === `${window.location.origin}${newPathName}`
          )
            window.location.reload();
          else window.location.href = `${window.location.origin}${newPathName}`;
        },
      });
    });
  };

  const handleCountrySelection = (countryCode) => {
    const tempMarket = markets?.data?.find(
      (market) => market.countryCode === countryCode
    );

    if (tempMarket.languages.length > 1) {
      setSelectedMarket(tempMarket);
      setSelectedCountry(countryCode);
    } else
      handleLanguageChange(tempMarket.languages[0].id, tempMarket.countryCode);
  };

  return (
    <MarketSelectWrapper ref={ref} isSecondStep={!!selectedCountry}>
      <Step transform={selectedCountry ? '-100%' : '0'}>
        <MarketSelectTitle>
          {websiteStrings?.marketSelectHeading || '{{marketSelectHeading}}'}
        </MarketSelectTitle>
        <MarketSelectDescription>
          {websiteStrings?.marketSelectInformation ||
            '{{marketSelectInformation}}'}
        </MarketSelectDescription>
        {continents?.map((continent, idx) => (
          <Fragment key={idx}>
            <ContinentTitle>{continent}</ContinentTitle>
            <ContinentGrid>
              {markets?.data
                ?.filter((market) => market.countryGroup === continent)
                .map(({ countryCode, countryName }) => (
                  <Country
                    key={countryCode}
                    onClick={() => handleCountrySelection(countryCode)}
                  >
                    <CountryFlag countryCode={countryCode.toUpperCase()} />
                    <CountryName>{countryName}</CountryName>
                  </Country>
                ))}
            </ContinentGrid>
          </Fragment>
        ))}
      </Step>
      <Step transform={selectedCountry ? '0' : '100%'}>
        {selectedMarket?.languages && (
          <>
            <MarketSelectBack onClick={() => setSelectedCountry('')}>
              <Icon icon={ICONS.ARROWLEFT} size={12} />
              {websiteStrings?.marketSelectBack || '{{marketSelectBack}}'}
            </MarketSelectBack>
            <MarketSelectTitle>
              {websiteStrings?.marketSelectLanguageTitle ||
                '{{marketSelectLanguageTitle}}'}
            </MarketSelectTitle>
            <MarketSelectGap />
            {selectedMarket?.languages.map(({ title, id }) => (
              <CountryName
                key={id}
                onClick={() =>
                  handleLanguageChange(id, selectedMarket?.countryCode)
                }
              >
                {title}
              </CountryName>
            ))}
          </>
        )}
      </Step>
    </MarketSelectWrapper>
  );
};

export const MarketSelectWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 90vh;
  max-height: 700px;
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;

  ${({ isSecondStep }) =>
    isSecondStep &&
    css`
      max-height: 250px;
      overflow-y: hidden;
    `};
`;

export const MarketSelectTitle = styled.h2`
  position: relative;
  font-size: 32px;
  margin: 0;
  padding-bottom: 20px;
  font-weight: 400;

  &:before {
    content: '';
    position: absolute;
    width: 120px;
    height: 1px;
    background: #000000;
    left: 0;
    bottom: 0;
  }
`;

export const MarketSelectDescription = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: 0.02em;
  color: #000000;
  margin: 24px 0 0 0;
`;

export const ContinentTitle = styled.h3`
  font-size: 20px;
  margin-bottom: 8px;
  margin-top: 32px;
  font-weight: 400;
`;

export const ContinentGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  max-height: 650px;

  ${media.L`
    max-height: 400px;
  `};
`;

export const Country = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin: 4px 0;
  width: 50%;
  flex-basis: 50%;

  ${media.L`
    width: 25%;
    flex-basis: 25%;
  `};
`;

export const CountryFlag = styled(FlagComponent)`
  margin-right: 8px;
`;

export const CountryName = styled.span`
  ${fonts.GoudyOldStyleRegular};
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  display: flex;
  align-items: center;
  letter-spacing: 0.02em;
  color: #070707;
  cursor: pointer;

  &:hover {
    color: black;
  }
`;

export const Step = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  transform: translateX(${({ transform }) => transform});
  transition: transform 0.2s ease-out;
`;

export const MarketSelectBack = styled.div`
  margin-bottom: 32px;
  position: relative;
  cursor: pointer;

  svg {
    margin-right: 16px;
  }
`;

export const MarketSelectGap = styled.div`
  height: 20px;
`;
