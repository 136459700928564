import { useSelector } from 'react-redux';
import get from 'lodash/fp/get';

export const useWebsiteString = (id) => {
  const channelString = useSelector((state) =>
    get(['bootstrap', 'data', 'channelStrings', id], state)
  );

  const websiteString = useSelector((state) =>
    get(['bootstrap', 'data', 'websiteStrings', id], state)
  );

  if (channelString) return channelString;
  if (websiteString) return websiteString;

  return `{{ ${id} }}`;
};
