import React from 'react';
import PropTypes from 'prop-types';
import { get, getOr } from 'lodash/fp';
import { LocalizedLink } from '../LocalizedLink/LocalizedLink';
import { RocketImage } from '../RocketImage/RocketImage';
import styles from './Navigation.module.scss';

export const MegaMenuImageList = (props) => {
  const { imageListChildren, imageListParentLink, onLinkClicked } = props;
  const defaultImageUrl = '/images/defaultimage-3x2.jpg';

  const [show, setShow] = React.useState(false);

  React.useEffect(() => {
    if (imageListChildren?.length) {
      setShow(true);
    }
  }, [imageListChildren]);

  if (!show) return false;

  return (
    <div className={styles.megaMenuImageListContainer}>
      {imageListParentLink?.length && (
        <LocalizedLink
          as={imageListParentLink.id}
          page={imageListParentLink.type}
          data={{ id: imageListParentLink.id }}
        >
          <a className={styles.imageListHeading} onClick={onLinkClicked}>
            {imageListParentLink.title}
          </a>
        </LocalizedLink>
      )}

      <ul className={styles.imageList}>
        {imageListChildren.map((child) => (
          <li
            className={styles.imageListItem}
            key={`image-list-item__${child.id}`}
          >
            <LocalizedLink
              as={child.id}
              page={child.type}
              data={{ id: child.id }}
            >
              <a className={styles.imageContainer} onClick={onLinkClicked}>
                <RocketImage
                  className={styles.imageListItemImage}
                  src={getOr(defaultImageUrl, ['images', 0, 'urlPath'], child)}
                  alt={get(['images', 0, 'alt'], child)}
                  heightRatio={2 / 3}
                  sizes="(max-width: 1080px) 400px, 700px"
                  quality={90}
                />
              </a>
            </LocalizedLink>
            <LocalizedLink
              as={child.id}
              page={child.type}
              data={{ id: child.id }}
            >
              <a className={styles.listItemLink} onClick={onLinkClicked}>
                {child.title}
              </a>
            </LocalizedLink>
            <p className={styles.listItemDescription}>{child.preamble}</p>
          </li>
        ))}
      </ul>
    </div>
  );
};

MegaMenuImageList.defaultProps = {
  imageListParentLink: {},
  imageListChildren: [],
};

MegaMenuImageList.propTypes = {
  imageListParentLink: PropTypes.object,
  imageListChildren: PropTypes.array,
  onLinkClicked: PropTypes.func.isRequired,
};
