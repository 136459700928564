import styled, { css } from 'styled-components';
import { color } from '../../styles/variables';
import { fonts } from '../../styles/typography';
import { hover, media, opacityHover } from '../../styles/mixins';

export const CountryLanguageContainer = styled.div`
  margin: 0 auto;
  h2 {
    max-width: 80%;
    ${media.S`
      max-width: 400px;
    `};
  }
  width: 100%;

  @media (min-width: 580px) {
    width: 450px;
  }
`;

export const SelectTitle = styled.label`
  display: inline-block;
  ${fonts.NeoSansRegular};
  font-size: 13px;
  margin-bottom: 10px;

  ${props =>
    props.disabled &&
    css`
      color: ${props.theme.color.disabled};
    `};
`;

export const EqualWidthContainer = styled.div`
  display: flex;
  width: 200px;
  align-items: center;
  justify-content: flex-start;

  ${props =>
    props.right &&
    css`
      justify-content: flex-end;
    `};

  ${props =>
    props.simple &&
    css`
      justify-content: center;
    `};
`;

export const PricingInformationText = styled.p`
  ${fonts.GoudyOldStyleRegular};
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: ${color.superGrey};
  margin: 0 auto;
  max-width: 400px;
`;

export const HoudiniButton = styled.button`
  cursor: pointer;
  &:focus {
    outline: none;
  }
  border: none;
  ${fonts.NeoSansRegular};
  font-size: 14px;
  color: #fff;
  width: 100%;
  text-decoration: none;
  height: 51px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #000;
  margin-top: 10px;
  margin-bottom: 20px;
  transition: opacity 0.1s ease-in-out;
  ${hover`
    opacity: .8;
  `};
`;

export const BackToPage = styled.a`
  position: absolute;
  left: 0;
  display: flex;
  width: auto;
  height: 40px;
  align-items: center;
  ${fonts.NeoSansRegular};
  font-size: 14px;
  text-decoration: none;
  color: ${props => props.theme.color.black};
  cursor: pointer;
  transition: opacity 250ms ease-out;

  > span {
    margin-left: 16px;
  }

  ${hover`
    opacity: .7;
  `};
`;

export const ChangeLanguage = styled.button`
  ${fonts.NeoSansRegular};
  margin: 0 auto;
  display: flex;
  align-items: center;
  font-size: 14px;
  position: relative;
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  &:focus {
    outline: none;
  }
  > p {
    ${fonts.GoudyOldStyleRegular};
    font-size: 14px;
    margin: 0;
    margin-top: 1px;
    opacity: 1;
    transition: opacity 0.1s ease-in-out;
    ${hover`
      opacity: .45;
    `};
  }

  > *:first-child {
    width: auto;
    max-width: 18px;
    height: 13px;
    margin-right: 8px;
    object-fit: contain;
    object-position: center;
  }

  ${media.M`
    margin: 0;
  `}

  ${({ footer }) =>
    footer &&
    css`
      > p {
        font-family: inherit;
      }
    `}
`;

export const ServiceLink = styled.a`
  color: #000;
  text-decoration: none;
  ${fonts.GoudyOldStyleRegular};
  font-size: 14px;
  opacity: 1;
  transition: opacity 0.1s ease-in-out;
  cursor: pointer;

  ${hover`
    opacity: .45;
  `};
`;

export const ServiceButton = styled.button`
  color: #000;
  text-decoration: none;
  ${fonts.GoudyOldStyleRegular};
  font-size: 14px;
  opacity: 1;
  transition: opacity 0.1s ease-in-out;
  cursor: pointer;
  border: none;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;

  ${hover`
    opacity: .45;
  `};
`;

export const ServiceItem = styled.li`
  margin-right: 25px;
  &:last-child {
    margin-right: 0;
  }
`;

export const UspRightList = styled.ul`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  list-style: none;
  padding: 0;
  ${props =>
    props.isMobile &&
    css`
      display: none;
    `};
`;

export const MobileContainer = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const NavBurger = styled.div`
  position: relative;
  height: 1px;
  width: 25px;
  background-color: #000;
  transition: background-color 0.3s ease-in-out;

  &:before,
  &:after {
    content: '';
    position: absolute;
    left: 0;
    height: 1px;
    width: 25px;
    background-color: #000;
    transition: transform 0.3s ease-in-out;
  }
  &:before {
    transform: translateY(7px) rotate(0deg);
  }
  &:after {
    transform: translateY(-7px) rotate(0deg);
  }
`;

export const NavigationButton = styled.button`
  position: absolute;
  left: 0;
  padding: 0;
  background-color: transparent;
  width: 25px;
  height: 15px;
  border: none;
  cursor: pointer;
  &:focus {
    outline: none !important;
  }

  ${props =>
    props.active &&
    css`
      > div {
        background-color: transparent;
        &:before {
          transform: translateY(0px) rotate(45deg);
        }
        &:after {
          transform: translateY(0px) rotate(-45deg);
        }
      }
    `};
`;

export const LogoLink = styled.a``;

export const Overlay = styled.div`
  pointer-events: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  opacity: 0;
  transition: opacity 0.25s ease-out;
  background: rgba(0, 0, 0, 0.3);
  z-index: 101;
  ${props =>
    props.active &&
    css`
      opacity: 1;
    `};

  ${media.IE`
    + * {
      margin-top: 112px !important;
    }
    + section {
      margin-top: 112px !important;
    }
  `};
`;

export const UspText = styled.div`
  ${fonts.GoudyOldStyleItalic};
  font-size: 15px;
  ${props =>
    props.isMobile &&
    css`
      margin: 0 auto;
    `};
  a {
    color: inherit;
  }
`;

export const UspAreaRoot = styled.div`
  position: relative;
  z-index: 102;
  height: 37px;
  background-color: #f4f4ef;
  > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
  }
  ${props =>
    props.isMobile &&
    css`
      display: none;
    `};
  ${media.IE`
    position: fixed;
    width: 100%;
  `};
`;

export const LogoContainer = styled.div`
  width: 104px;
  display: flex;
  > svg {
    width: 100%;
    height: 25px;
    transform-origin: left center;
    transition: transform 0.3s ease-in-out;
  }

  ${media.M`
    width: 130px;
  `};

  ${props =>
    props.belowTop &&
    css`
      > svg {
        transform: scale(0.88);
      }
    `};

  ${props =>
    props.simplified &&
    css`
      > svg {
        transform-origin: center;
      }
    `};

  ${props =>
    props.isMobile &&
    css`
      > svg {
        height: 20px;
      }
    `};
`;

export const CartQuantity = styled.span`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  left: -8px;
  top: -2px;
  z-index: 1;
  border-radius: 50%;
  width: 17px;
  height: 17px;
  background-color: #000;
  color: #fff;
  ${fonts.NeoSansMedium};
  font-size: 8px;
`;

export const HeaderContainer = styled.div`
  // max-height: 75px;
  transition: max-height 0.3s ease-in-out;
  ${props =>
    props.belowTop &&
    css`
      // max-height: 60px;
    `};
  position: sticky;
  top: 0;
  z-index: 200;
  // height: 75px;
  height: auto;
  background-color: #fff;
  border-bottom: 1px solid ${color.borderGrey};
  // display: flex;
  display: block;
  align-items: center;
  > div {
    width: 100%;
    // height: 100%;
  }
  > div > div {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    // height: 100%;
  }

  ${props =>
    props.simplified &&
    css`
      > div > div {
        justify-content: center;
        position: relative;
      }
    `};
  ${media.IE`
    position: fixed;
    top: 37px;
    width: 100%;
  `};
`;

export const SearchButtonContainer = styled.button`
  ${opacityHover};
  cursor: pointer;
  padding: 5px;
  border: none;
  background-color: transparent;
  margin-right: 36px;

  &:focus {
    outline: none;
  }

  ${props =>
    props.isMobile &&
    css`
      margin-right: 18px;
    `};
`;

export const SearchAndCartAreaMobile = styled.div`
  position: absolute;
  right: 0;
  > div {
    /*fadein creates absolute positioned div*/
    position: static;
  }
`;

export const CartLink = styled.button`
  position: relative;
  background-color: transparent;
  border: none;
  opacity: 1;
  cursor: pointer;
  &:focus {
    outline: none;
  }
  margin: 0;
  padding: 5px 0 5px 5px;

  ${props => props.isMobile && css``};
  > svg {
    transition: opacity 0.1s ease-in-out;
    transform: translateZ(0) scale(1, 1) !important;
    backface-visibility: hidden !important;

    ${hover`
      opacity: 0.45;
    `};
  }
  transition: opacity 0.3s ease-in-out;
  ${props =>
    props.navOpen &&
    css`
      opacity: 0;
    `};
`;
