import React from 'react';
import { get, compose, size, getOr, map } from 'lodash/fp';
import PropTypes from 'prop-types';
import Router, { withRouter } from 'next/router';
import { CSSTransition } from 'react-transition-group';

import { connect } from 'store/store';
import {
  addToCartRequest,
  subtractFromCartRequest,
  removeFromCartRequest,
} from 'store/actions/actions';

import { ICONS } from 'constants/icons';
import { REUSE_PRODUCT } from 'constants/cmsTypes';
import { LOADING } from 'constants/requestStatuses';

import { checkoutEvent } from 'helpers/gtm';

import { SubHeading } from 'components/SubHeading/SubHeading';
import { Conditional } from 'components/Conditional/Conditional';
import { String } from 'components/String/String';
import DepictCartRecommendations from 'components/DepictCartRecommendations/DepictCartRecommendations';
import { Icon } from 'components/Icon/Icon';

import { Divider } from 'styles/houdiniProductStyle';
import { color } from 'styles/variables';

import {
  CartEmptyItem,
  CartContainer,
  CartItems,
  GotoCheckoutButton,
  InnerSummaryArea,
  SummaryContainer,
  TotalRow,
  UpperCartContainer,
  UpperScrollArea,
  UpperSummaryList,
  CartProductMessage,
  CloseMessageButton,
  LoadingIndicator,
  LoadingWrapper,
} from './styles';
import { CartItem } from './CartItem';

class CartComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showCartMessage: true,
    };
    this.removeItem = this.removeItem.bind(this);
    this.subtract = this.subtract.bind(this);
    this.add = this.add.bind(this);

    this.cartRef = React.createRef();
  }

  removeItem(orderRow) {
    const { market, type, isAdyen } = this.props;
    const id = get(['orderRowId'], orderRow);
    const updatePaymentProvider = type === 'checkout';

    this.setState({
      [`cartItem-${id}-removed`]: true,
      action: 'remove',
    });

    setTimeout(() => {
      this.props.removeFromCartRequest({
        orderRow,
        currencyCode: this.props.currencyCode,
        market,
        updatePaymentProvider,
        isAdyen,
      });
    }, 500);
  }

  subtract(orderRow) {
    const { market, type, isAdyen } = this.props;
    const updatePaymentProvider = type === 'checkout';

    if (get('quantity', orderRow) > 1) {
      this.props.subtractFromCartRequest({
        orderRow,
        currencyCode: this.props.currencyCode,
        market,
        updatePaymentProvider,
        isAdyen,
      });
    }
  }

  add(id) {
    const { market, type, isAdyen } = this.props;
    const updatePaymentProvider = type === 'checkout';
    this.props.addToCartRequest({
      id,
      cart: this.props.cart,
      currencyCode: this.props.currencyCode,
      market,
      updatePaymentProvider,
      isAdyen,
    });
  }

  goToCheckout() {
    const { cart, currencyCode, market } = this.props;
    // fire GTM-event
    checkoutEvent({ cart, currencyCode, step: 1 });

    Router.push(
      {
        pathname: '/Checkout',
        query: { id: 'checkout', market },
      },
      `/${market}/checkout`
    );
  }

  renderCartItems(orderRows, request) {
    const { isAdyen } = this.props;
    return map((row) => {
      const removed =
        this.state[`cartItem-${get(['orderRowId'], row)}-removed`];

      return (
        <CartItem
          noninteractive={this.props.noninteractive || isAdyen}
          key={get(['orderRowId'], row)}
          orderRow={row}
          orderRowsSize={size(orderRows)}
          add={this.add}
          subtract={this.subtract}
          removeItem={this.removeItem}
          removed={removed}
          reuseProduct={get(['product', 'type'], row) === REUSE_PRODUCT}
          request={request}
        />
      );
    }, orderRows);
  }

  render() {
    const {
      cart,
      noninteractive,
      confirmCart,
      request,
      type,
      sticky,
      disabled,
    } = this.props;
    const actualCart = size(confirmCart) > 0 ? confirmCart : cart;
    const orderRowsSize = size(getOr([], ['orderRows'], actualCart));
    const cartItems = this.renderCartItems(
      getOr([], ['orderRows'], actualCart),
      request
    );
    const showMessage =
      this.state.showCartMessage && !!this.props.cartModifiedMessage;

    return (
      <CartContainer sticky={sticky}>
        <UpperCartContainer noninteractive={noninteractive}>
          <UpperScrollArea>
            <SubHeading mt={0}>
              {noninteractive ? (
                <String id="yourOrderLabel" />
              ) : (
                <String id="yourBagLabel" />
              )}
            </SubHeading>

            <Conditional show={orderRowsSize === 0}>
              <CSSTransition
                classNames="fadein"
                timeout={900}
                nodeRef={this.cartRef}
              >
                <CartEmptyItem>
                  <String id="cartEmptyText" />
                </CartEmptyItem>
              </CSSTransition>
            </Conditional>

            <CartProductMessage in={this.props.cartModifiedMessage ? 1 : 0}>
              {this.props.cartModifiedMessage}
              <CloseMessageButton
                in={showMessage ? 1 : 0}
                onClick={() => this.setState({ showCartMessage: false })}
              >
                <Icon icon={ICONS.CLOSE_SMALL} size={8} color={color.black} />
              </CloseMessageButton>
            </CartProductMessage>

            <CartItems
              disable={
                (request.status && request.status === LOADING) || disabled
              }
            >
              {cartItems}
            </CartItems>

            {((request.status && request.status === LOADING) || disabled) && (
              <LoadingWrapper>
                <LoadingIndicator />
              </LoadingWrapper>
            )}
          </UpperScrollArea>

          <Conditional show={orderRowsSize > 0}>
            <DepictCartRecommendations />
          </Conditional>
        </UpperCartContainer>

        <SummaryContainer
          vanish={orderRowsSize === 0}
          noninteractive={noninteractive}
        >
          <InnerSummaryArea>
            <Conditional show={!!get(['subtotalPrice'], actualCart)}>
              <UpperSummaryList>
                <li>
                  <String id="subTotalLabel" />
                </li>
                <li>{get(['subtotalPrice'], actualCart)}</li>
              </UpperSummaryList>
            </Conditional>

            <Conditional
              show={
                get(['totalDiscount'], actualCart) &&
                get(['discountAmount'], actualCart) > 0
              }
            >
              <UpperSummaryList>
                <li>
                  <String id="savingsLabel" />
                </li>
                <li>{get(['totalDiscount'], actualCart)}</li>
              </UpperSummaryList>
            </Conditional>

            <UpperSummaryList>
              <li>
                <String id="freeShippingLabel" />
              </li>
              <li>{get(['shippingFee'], actualCart)}</li>
            </UpperSummaryList>

            <UpperSummaryList>
              <li>
                <String id="totalVatLabel" />
              </li>
              <li>{get(['totalVat'], actualCart)}</li>
            </UpperSummaryList>

            <Conditional show={get(['giftCard', 'code'], actualCart)}>
              <UpperSummaryList>
                <li>
                  <String id="giftCard" />
                </li>
                <li>-{get(['giftCard', 'amountReserved'], actualCart)}</li>
              </UpperSummaryList>
            </Conditional>

            <Divider color="#8d8d8d" />

            <TotalRow>
              <li>
                <String id="orderTotalLabel" />
              </li>
              <li>{get(['grandTotal'], actualCart)}</li>
            </TotalRow>

            <Conditional show={!noninteractive && type !== 'checkout'}>
              <GotoCheckoutButton onClick={() => this.goToCheckout()}>
                <String id="checkoutButtonLabel" />
              </GotoCheckoutButton>
            </Conditional>
          </InnerSummaryArea>
        </SummaryContainer>
      </CartContainer>
    );
  }
}

CartComponent.defaultProps = {
  cart: {},
  confirmCart: {},
  noninteractive: false,
  sticky: false,
  currencyCode: undefined,
  request: {},
  cartModifiedMessage: '',
  type: '',
  isAdyen: false,
  disabled: false,
};

CartComponent.propTypes = {
  addToCartRequest: PropTypes.func.isRequired,
  subtractFromCartRequest: PropTypes.func.isRequired,
  removeFromCartRequest: PropTypes.func.isRequired,
  cart: PropTypes.object,
  confirmCart: PropTypes.object,
  noninteractive: PropTypes.bool,
  sticky: PropTypes.bool,
  market: PropTypes.string.isRequired,
  currencyCode: PropTypes.string,
  request: PropTypes.object,
  cartModifiedMessage: PropTypes.string,
  type: PropTypes.string,
  isAdyen: PropTypes.bool,
  disabled: PropTypes.bool,
};

export const Cart = compose(withRouter, connect)(
  CartComponent,
  { addToCartRequest, subtractFromCartRequest, removeFromCartRequest },
  (store) => ({
    cart: get(['cart', 'data'], store),
    request: get(['cart', 'request'], store),
    market: get(['viewState', 'market'], store),
    currencyCode: get(['bootstrap', 'data', 'currencyCode'], store),
  })
);
