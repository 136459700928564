/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { LocalizedLink } from '../LocalizedLink/LocalizedLink';
import { EditorialMegaMenu } from './EditorialMegaMenu';
import { MainCategoryMegaMenu } from './MainCategoryMegaMenu';
import styles from './Navigation.module.scss';

export const NavigationItem = (props) => {
  const { navItem } = props;
  const { id, type, title, leftColumn, children } = navItem;
  const linkRef = useRef();

  const displayMainCategoryMegaMenu =
    leftColumn?.length && type === 'MainCategoryPage';
  const displayEditorialListMegaMenu =
    children?.length && type === 'EditorialListPage';
  const displayMegaMenu =
    displayMainCategoryMegaMenu || displayEditorialListMegaMenu;

  const linkClickHandler = () => {
    if (linkRef && linkRef.current) {
      linkRef.current.classList.add(styles.hideMegaMenu);
    }

    setTimeout(() => {
      if (linkRef && linkRef.current) {
        linkRef.current.classList.remove(styles.hideMegaMenu);
      }
    }, 200);
  };

  return (
    <li className={styles.navigationItem}>
      <LocalizedLink as={id} page={type} data={{ id }}>
        <a
          ref={linkRef}
          className={`${styles.mainNavigationLink} ${
            id === 'sale' || id === 'deals' ? styles.red : ''
          }`}
          onClick={linkClickHandler}
        >
          {title}
        </a>
      </LocalizedLink>

      {displayMainCategoryMegaMenu ? (
        <div className={styles.megaMenu}>
          <MainCategoryMegaMenu
            navItem={navItem}
            onLinkClicked={linkClickHandler}
          />
        </div>
      ) : null}

      {displayEditorialListMegaMenu ? (
        <div className={styles.megaMenu}>
          <EditorialMegaMenu data={navItem} onLinkClicked={linkClickHandler} />
        </div>
      ) : null}

      {displayMegaMenu ? <div className={styles.overlay} /> : null}
    </li>
  );
};

NavigationItem.defaultProps = {
  navItem: {},
};

NavigationItem.propTypes = {
  navItem: PropTypes.object,
};
