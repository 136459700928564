import styled from 'styled-components';

import { RocketImage as ImageComponent } from '../RocketImage/RocketImage';
import { dynamicFontSize } from '../../styles/mixins';
import { color } from '../../styles/variables';

export const ProductTitle = styled.a`
  ${dynamicFontSize(14, 16)};
  color: black;
  display: block;
  font-style: normal;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 1.25;
  margin: 16px 10px 12px;
  text-align: center;
  text-decoration: none;
  transition: opacity 0.2s ease-in-out;

  &:hover {
    opacity: 0.5;
  }
`;

export const ProductImage = styled(ImageComponent)``;

export const ProductPrice = styled.p`
  ${dynamicFontSize(11, 13)};
  font-stretch: normal;
  font-style: normal;
  font-weight: 400;
  letter-spacing: normal;
  line-height: normal;
  text-align: center;
  margin-top: auto;

  & > span {
    &:first-of-type {
      text-decoration: line-through;
      opacity: 0.3;
      margin-right: 4px;
    }

    &:last-of-type {
      color: ${color.red};
    }
  }
`;

export const RecommendationsSection = styled.div`
  margin-top: auto;
`;

export const ProductWrapper = styled.div`
  display: flex;
  flex-direction: column;

  &:hover {
    & ${ProductTitle} {
      opacity: 0.5;
    }
  }
`;

export const ProductSectionWrapper = styled.section`
  --gap: 8px;
  --column-template: 1fr 1fr 1fr;

  @media screen and (max-width: 1130px) {
    --column-template: 1fr 1fr;

    & ${ProductWrapper}:nth-of-type(3) {
      display: none;
    }
  }

  display: grid;
  grid-template-columns: var(--column-template);
  gap: var(--gap);
`;
