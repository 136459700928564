import NextImage, { ImageProps as NextImageProps } from 'next/image';
import styled from 'styled-components';

type ObjectFit = {
  $objectFitString?: 'cover' | 'contain';
};

type ImageComponentProps = {
  src: string;
  width?: number;
  height?: number;
  blurString?: string;
  alt?: string;
  container?: boolean;
  containerClassName?: string;
  containerAs?: 'div' | 'span';
  priority?: boolean;
  sizes?: string;
  fill?: boolean;
  onError?: NextImageProps['onError'];
  quality?: number;
};

export type ImageProps = NextImageProps & ObjectFit;

export type ImageWrapperProps = ImageComponentProps & ObjectFit;

const StyledImage = styled(NextImage)<NextImageProps & ObjectFit>`
  ${({ $objectFitString }) =>
    $objectFitString && `object-fit: ${$objectFitString};`}

  @supports (font: -apple-system-body) and (-webkit-appearance: none) {
    &[loading='lazy'] {
      clip-path: 'inset(1px)';
    }
  }
`;

export const ImageComponent: React.FC<ImageProps> = (props) => {
  const { src, alt, ...rest } = props;

  return <StyledImage src={src} alt={alt || ''} {...rest} />;
};

const Image: React.FC<ImageWrapperProps> = (props) => {
  const {
    src,
    width,
    height,
    blurString,
    alt,
    $objectFitString = 'cover',
    priority,
    sizes,
    fill,
    onError,
    quality,
  } = props;
  if (!src) {
    return null;
  }

  const propsToPass = {
    src: src || '',
    alt: alt || '',
    width: !fill ? width : undefined,
    height: !fill ? height : undefined,
    fill,
    $objectFitString,
    priority,
    placeholder: blurString ? 'blur' : 'empty',
    blurDataURL: blurString,
    quality: quality || 90, // Next.js default
    sizes,
    onError,
    // ? Uncomment this to disable inbuilt Next.js image optimization
    loader: ({ src: srcToLoad, width: widthToLoad, quality }) => {
      const url = new URL(srcToLoad);

      if (url.pathname.includes('data:image')) {
        return srcToLoad;
      }

      const params = url.searchParams;
      params.set('width', widthToLoad.toString());
      params.set('quality', `${quality || 90}`);
      if (!(width && height)) {
        if (params.has('height')) params.delete('height');
      }

      return url.href;
    },
  } satisfies ImageProps;

  return <ImageComponent {...propsToPass} />;
};

export default Image;
