import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { ICONS } from '../../constants/icons';
import { fonts } from '../../styles/typography';
import { media } from '../../styles/mixins';
import { Icon } from '../Icon/Icon';

export const CheckboxHOC = ({ id, name, required, checked, children, onChange }) => (
  <Label htmlFor={id}>
    <HiddenCheckbox onChange={onChange} name={name} id={id} required={required} />
    <CheckboxContainer>{checked ? <Icon icon={ICONS.CLOSE} color="#979797" size={18} /> : ''}</CheckboxContainer>
    {children}
  </Label>
);

const HiddenCheckbox = styled.input.attrs({
  type: 'checkbox'
})`
  position: absolute;
  left: -10000px;
`;

const CheckboxContainer = styled.div`
  margin-right: 12px;
  border: 1px solid ${props => props.theme.color.borderGrey};
  min-width: 22px;
  width: 22px;
  height: 22px;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;

  ${media.S`
    margin-top: 2px;
    padding: 1px;
    min-width: 16px;
    width: 16px;
    height: 16px;
  `};
`;

const Label = styled.label`
  display: flex;
  align-items: flex-start;
  flex: 1;
  margin: 0;
  position: relative;
  cursor: pointer;
  ${fonts.GoudyOldStyleRegular};
  font-size: 14px;
  line-height: 1.43;
  color: ${props => props.theme.color.black};

  a {
    color: inherit;
    text-decoration: underline;
    transition: opacity 250ms ease-out;

    &:hover {
      opacity: 0.6;
    }
  }
`;

CheckboxHOC.defaultProps = {
  children: null,
  checked: false,
  required: false,
  id: null
};

CheckboxHOC.propTypes = {
  children: PropTypes.node,
  checked: PropTypes.bool,
  required: PropTypes.bool,
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};
