import styled from 'styled-components';
import { fonts } from '../../styles/typography';
import { color } from '../../styles/variables';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;
  max-width: 450px;
  margin: 0 auto;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: stretch;
  width: 100%;
`;

export const Title = styled.h2`
  ${fonts.GoudyOldStyleRegular};
  position: relative;
  font-size: 32px;
  margin-top: 0;
  margin-bottom: 30px;
  padding-bottom: 20px;
  font-weight: 400;

  &:before {
    content: '';
    position: absolute;
    width: 120px;
    height: 1px;
    background: #000000;
    left: 50%;
    bottom: 0;
    transform: translateX(-60px);
  }
`;

export const Benefit = styled.p`
  font-family: 'goudy-old-style', Georgia, serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  padding: 0;
  margin: 4px 0;

  &:first-of-type {
    margin-top: -16px;
  }

  &:last-of-type {
    margin-bottom: 24px;
  }
`;

export const SendButton = styled.button.attrs({
  type: 'submit'
})`
  ${fonts.NeoSansRegular};
  width: calc(100% - 20px);
  min-height: 50px;
  font-size: 14px;
  text-align: center;
  border: none;
  outline: none;
  border-radius: 0;
  box-shadow: none;
  color: ${props => props.theme.color.white};
  background: ${props => props.theme.color.black};
  cursor: pointer;
  transition: opacity 0.25s ease-out;

  &:disabled {
    opacity: 0.6;
    cursor: default;
  }
`;

export const Action = styled.p`
  font-size: 14px;
  margin-top: 30px;

  & + & {
    margin-top: 0;
  }
`;

export const ActionButton = styled.button`
  display: inline-block;
  border: none;
  margin: 0;
  padding: 0;
  text-decoration: underline;
  background: transparent;
  cursor: pointer;
  text-align: center;
  transition: background 250ms ease-in-out, transform 150ms ease;
  -webkit-appearance: none;
  -moz-appearance: none;
`;

export const NoticeHeading = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 30px;

  p {
    ${fonts.GoudyOldStyleRegular};
    font-size: 22px;
    line-height: 28px;
    margin: 0 0 0 10px;
  }
`;

export const Text = styled.p`
  ${fonts.GoudyOldStyleRegular};
  font-size: 16px;
  line-height: 24px;
  margin: 0;
`;

export const InfoText = styled.p`
  ${fonts.GoudyOldStyleRegular};
  font-size: 16px;
  line-height: 24px;
  padding: 30px;
  background-color: ${color.primary};
  margin: 0;
`;

export const ErrorText = styled.p`
  ${fonts.GoudyOldStyleRegular};
  width: calc(100% - 20px);
  text-align: center;
  font-size: 16px;
  line-height: 24px;
  padding: 12px 18px;
  color: ${color.white};
  background-color: ${color.red};
`;
