import styled, { css } from 'styled-components';
import { media } from '../../styles/mixins';
import { TextInput } from '../CampaignCodeInput/CampaignCodeInput';
import { fonts } from '../../styles/typography';
import { color } from '../../styles/variables';

export const FormRow = styled.div`
  display: inline-block;
  width: 100%;
  padding: 0 10px;
  position: relative;
  margin-bottom: 24px;

  &[name='zip'] {
    width: 35%;
  }

  &[name='city'] {
    width: 65%;
  }

  ${media.XL`
    width: ${(props) => props.width * 100}%;
    
    &[name='zip'] {
      width: 140px;
    }
  
    &[name='city'] {
      width: calc(100% - 140px);
    }
  `};
`;

export const GenericInput = styled(TextInput)`
  display: block;
  width: 100%;
  margin: 0;

  &[type='tel'] {
    padding-left: 95px;
  }

  &:disabled {
    color: ${(props) => props.theme.color.disabled};
  }

  ${({ error }) =>
    error &&
    css`
      border-color: ${color.red};
    `}
`;

export const ValidationError = styled.p`
  ${fonts.NeoSansRegular};
  font-size: 13px;
  color: #5f2d3a;
  margin: 5px 0 0;
  height: auto;
`;

export const FormLabel = styled.label`
  ${fonts.NeoSansRegular};
  font-size: 13px;
  line-height: normal;
  letter-spacing: normal;
  color: ${(props) => props.theme.color.black};
  position: absolute;
  top: -8px;
  background: white;
  left: 16px;
  padding: 0 5px;
  z-index: 1;

  &:disabled {
    color: ${(props) => props.theme.color.disabled};
  }
`;

export const Arrow = styled.div`
  position: absolute;
  top: 18px;
  right: 30px;
  transform: rotate(180deg);
  pointer-events: none;
`;

export const SelectFormRow = styled(FormRow)`
  position: relative;

  select {
    ${fonts.NeoSansLight};
    display: block;
    background-color: #fff;
    border: 1px solid ${color.borderGrey};
    background-image: none;
    appearance: none;
    outline: none;
    padding: 0 15px;
    height: 50px;
    cursor: pointer;
    width: 100%;
    border-radius: 0;
    font-size: 16px;

    ::-ms-expand {
      display: none;
    }

    &:disabled {
      color: ${(props) => props.theme.color.disabled};
      cursor: default;
    }
  }

  ${({ error }) =>
    error &&
    css`
      select {
        border-color: ${color.red};
      }
    `}
`;
