import React, { Fragment } from 'react';
import { get, getOr, map, reduce, compose, size, uniq } from 'lodash/fp';
import Head from 'next/head';
import PropTypes from 'prop-types';
import { withCookies } from 'react-cookie';
import { withRouter } from 'next/router';
import { ThemeProvider } from 'styled-components';
import Script from 'next/script';
import { base } from '../../styles/theme';
import { GlobalStyle } from '../../styles/global';
import { Header } from '../Header/Header';
import { Footer } from '../Footer/Footer';
import { MainContent } from './styles';
import { Modal } from '../Modal/Modal';
import { Cart } from '../Cart/Cart';
import {
  CART,
  MARKET_SELECT,
  NEWSLETTER,
  LOGIN,
  CREATE_ACCOUNT,
  RESET_PASSWORD,
  FORGOT_PASSWORD,
  LOGIN_PWD_RESET,
} from '../../constants/modals';
import { removeViewstate, setViewstate } from '../../store/actions/actions';
import { connect } from '../../store/store';
import { NEXT_PUBLIC_SITE_BASE_URL } from '../../constants/site';
import * as GTM from '../../helpers/gtm';
import { createHrefLangs } from '../../helpers/createHrefLangs';
import { MarketSelect } from '../MarketSelect/MarketSelect';
import { withUserData } from '../../hoc/withUserData';

import Login from '../MyPages/Login/Login';
import CreateAccount from '../MyPages/CreateAccount/CreateAccount';
import ResetPassword from '../MyPages/ResetPassword/ResetPassword';
import ForgotPassword from '../MyPages/ForgotPassword/ForgotPassword';

export class LayoutComponent extends React.Component {
  componentDidMount() {
    if (this.props.myPagesEnabled)
      this.props.userHook.checkUser(this.props.market);

    if (get(['meta', 'seoTitle'], this.props)) {
      // send pageView event to Google Tag Manager when a page mounts
      GTM.pageViewEvent();
    }
  }

  componentDidUpdate(prevProps) {
    const { routerHistory, newsletter, cookies } = this.props;
    const {
      smartNewsletterWidgetNumberOfViews,
      smartNewsletterWidgetNumberofVisits,
    } = newsletter;
    const { NEWSLETTER_SHOWED, NUMBER_OF_VISITS } = getOr(
      {},
      'cookies',
      cookies
    );

    // is the newsletter active, and the user has not seen it yet?
    const newsletterActive =
      get('smartNewsletterWidgetEnabled', newsletter) && !NEWSLETTER_SHOWED;

    // has the user visited more routes then specified in backend?
    const newsletterBasedOnViews =
      smartNewsletterWidgetNumberOfViews &&
      size(uniq(routerHistory)) === smartNewsletterWidgetNumberOfViews &&
      size(uniq(get('routerHistory', prevProps))) ===
        smartNewsletterWidgetNumberOfViews - 1;

    // has the user visited the site more times then specified in backend?
    const newsletterBasedOnVisits =
      smartNewsletterWidgetNumberofVisits &&
      parseInt(NUMBER_OF_VISITS, 10) === smartNewsletterWidgetNumberofVisits;

    if (
      newsletterActive &&
      (newsletterBasedOnViews || newsletterBasedOnVisits)
    ) {
      GTM.newsletterAutoOpenEvent();
      this.props.setViewstate('activeModal', NEWSLETTER);
    }

    if (
      get(['meta', 'seoTitle'], this.props) &&
      get(['meta', 'seoTitle'], prevProps) !==
        get(['meta', 'seoTitle'], this.props)
    ) {
      // send pageView event to Google Tag Manager when a page get's new props with a new seoTitle
      GTM.pageViewEvent();
    }
  }

  closeAllModals() {
    this.props.removeViewstate('activeModal');
  }

  render() {
    const {
      meta,
      url,
      markets,
      externalScripts,
      market,
      activeModal,
      facebookImage,
    } = this.props;

    const pageUrl =
      typeof url === 'string' ? `${url !== '' ? '/' : ''}${url}` : undefined;

    const flatListOfMarketsAsObjects = reduce(
      (memory, marketItem) => {
        return [...getOr([], 'languages', marketItem), ...memory];
      },
      [],
      markets
    );

    const flatListOfMarkets = map(
      (marketItem) => get('id', marketItem),
      flatListOfMarketsAsObjects
    );
    const hrefLangs = createHrefLangs(flatListOfMarkets);

    return (
      <ThemeProvider theme={base}>
        <>
          <GlobalStyle
            fontFamily="neo-sans"
            fontWeight="400"
            fontStyle="normal"
          />
          <Script
            id="Cookiebot"
            src="https://consent.cookiebot.com/uc.js"
            data-cbid={process.env.NEXT_PUBLIC_COOKIEBOT_TOKEN}
            data-blockingmode="auto"
            type="text/javascript"
          />

          {externalScripts.map((script) => (
            <Script key={script} type="text/javascript" src={script} />
          ))}

          <Head>
            <title>{getOr('', 'seoTitle', meta) || ''}</title>
            <meta property="og:title" content={get('seoTitle', meta)} />
            <meta
              name="description"
              property="og:description"
              content={get('seoDescription', meta)}
            />
            <meta property="og:locale" content={market} />

            {typeof get('image', meta) === 'string' ? (
              <meta property="og:image" content={get('image', meta)} />
            ) : typeof get('facebookImage', meta) === 'string' ? (
              <meta property="og:image" content={get('facebookImage', meta)} />
            ) : facebookImage ? (
              <meta property="og:image" content={`${facebookImage}`} />
            ) : (
              <meta
                property="og:image"
                content={`${NEXT_PUBLIC_SITE_BASE_URL}/images/houdini_og-image_fallback_2.jpg`}
              />
            )}

            {typeof pageUrl === 'string' && (
              <meta
                property="og:url"
                content={`${NEXT_PUBLIC_SITE_BASE_URL}/${market}${pageUrl}`}
              />
            )}

            {process.env.NEXT_PUBLIC_INDEXABLE === 'true' ? (
              <meta
                name="robots"
                content={`${
                  get('indexThePage', meta)
                    ? 'INDEX, FOLLOW'
                    : 'NOINDEX, NOFOLLOW'
                }`}
              />
            ) : (
              <meta name="robots" content="NOINDEX, NOFOLLOW" />
            )}

            {process.env.NEXT_PUBLIC_GOOGLE_SITE_VERIFICATION && (
              <meta
                name="google-site-verification"
                content={process.env.NEXT_PUBLIC_GOOGLE_SITE_VERIFICATION}
              />
            )}

            <meta
              name="p:domain_verify"
              content={process.env.NEXT_PUBLIC_PINTEREST_VERIFY_CODE}
            />

            {typeof pageUrl === 'string' && (
              <>
                <link
                  rel="canonical"
                  href={`${NEXT_PUBLIC_SITE_BASE_URL}/${market}${pageUrl}`}
                />

                {hrefLangs?.map(({ locale, market: marketItem }) => (
                  <link
                    rel="alternate"
                    href={`${NEXT_PUBLIC_SITE_BASE_URL}/${marketItem}${pageUrl}`}
                    hrefLang={locale}
                    key={`alternate-link_${locale}`}
                  />
                ))}
                <link
                  rel="alternate"
                  href={`${NEXT_PUBLIC_SITE_BASE_URL}/en-eu${pageUrl}`}
                  hrefLang="x-default"
                />
              </>
            )}
          </Head>

          <MainContent>
            <Header simplified={this.props.simpleHeader} />
            {this.props.children}
          </MainContent>

          <Footer />

          <Modal
            onClose={() => this.closeAllModals()}
            in={activeModal === CART}
            slideIn
            noSidePadding
            cart
          >
            <Cart />
          </Modal>

          <Modal
            onClose={() => this.closeAllModals()}
            in={activeModal === MARKET_SELECT}
            width="90vw"
            maxWidth="970px"
          >
            <MarketSelect />
          </Modal>

          <Modal
            onClose={() => this.closeAllModals()}
            in={activeModal === LOGIN || activeModal === LOGIN_PWD_RESET}
            width="90vw"
            maxWidth="740px"
          >
            <Login />
          </Modal>

          <Modal
            onClose={() => this.closeAllModals()}
            in={activeModal === RESET_PASSWORD}
            width="90vw"
            maxWidth="740px"
          >
            <ResetPassword />
          </Modal>

          <Modal
            onClose={() => this.closeAllModals()}
            in={activeModal === FORGOT_PASSWORD}
            width="90vw"
            maxWidth="740px"
          >
            <ForgotPassword />
          </Modal>

          <Modal
            onClose={() => this.closeAllModals()}
            in={activeModal === CREATE_ACCOUNT}
            width="90vw"
            maxWidth="740px"
          >
            <CreateAccount />
          </Modal>
        </>
      </ThemeProvider>
    );
  }
}

LayoutComponent.defaultProps = {
  simpleHeader: false,
  meta: {},
  url: undefined,
  markets: [],
  routerHistory: [],
  newsletter: undefined,
  externalScripts: [],
  market: null,
  activeModal: null,
  facebookImage: null,
  userHook: {},
  myPagesEnabled: false,
};

LayoutComponent.propTypes = {
  removeViewstate: PropTypes.func.isRequired,
  setViewstate: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  markets: PropTypes.array,
  routerHistory: PropTypes.arrayOf(PropTypes.string),
  newsletter: PropTypes.object,
  url: PropTypes.string,
  simpleHeader: PropTypes.bool,
  meta: PropTypes.object,
  externalScripts: PropTypes.array,
  market: PropTypes.string,
  activeModal: PropTypes.string,
  cookies: PropTypes.object.isRequired,
  facebookImage: PropTypes.string,
  userHook: PropTypes.object,
  myPagesEnabled: PropTypes.bool,
};

export const Layout = compose(
  withUserData,
  withRouter,
  withCookies,
  connect
)(LayoutComponent, { removeViewstate, setViewstate }, (store) => ({
  facebookImage: get(['bootstrap', 'data', 'facebookImage'], store),
  market: get('viewState.market', store),
  activeModal: get(['viewState', 'activeModal'], store),
  markets: get(['bootstrap', 'data', 'markets'], store),
  routerHistory: get(['viewState', 'routerHistory'], store),
  newsletter: get(['bootstrap', 'data', 'newsletter'], store),
  myPagesEnabled: getOr(false, ['bootstrap', 'data', 'myPagesEnabled'], store),
}));
