import transition, { css } from '@geraldcloudnine/styled-transition-group';

export const FadeIn = transition.div.attrs({
  mountOnEnter: true,
  unmountOnExit: true,
})`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  
  &:enter {
    opacity: 0.01;
  }
  &:enter-active {
    transition: opacity ${(props) => props.timeout}ms ease-out;
    opacity: 1;
  }
  &:exit {
    opacity: 1;
  }
  &:exit-active {
    transition: opacity ${(props) => props.timeout}ms ease-out;
    opacity: 0.01;
  }
`;

export const FilterTagsHeight = transition.div.attrs({
  mountOnEnter: true,
  unmountOnExit: true,
})`  
  height:34px;
  overflow: hidden;

  &:enter {
    max-height: 0;
  }
  &:enter-active {
    transition: max-height ${(props) => props.timeout}ms ease-out;
    max-height: 34px;
  }
  &:exit {
    max-height: 34px;
  }
  &:exit-active {
    transition: max-height ${(props) => props.timeout}ms ease-out;
    max-height: 0;
  }
`;

export const FadeInScale = transition.div.attrs({
  mountOnEnter: true,
  unmountOnExit: true,
})`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  
  &:enter {
    opacity: 0.01;
    transform: scale(1.025);
  }
  &:enter-active {
    transition: all ${(props) => props.timeout}ms ease-out;
    opacity: 1;
    transform: scale(1);
  }
  &:exit {
    opacity: 1;
    transform: scale(1);
  }
  &:exit-active {
    transition: all ${(props) => props.timeout}ms ease-out;
    opacity: 0.01;
   
    transform: scale(1.025);
  }
`;

export const SlideIn = transition.div.attrs({
  mountOnEnter: true,
  unmountOnExit: true,
})`
  position: absolute;
  top: 0;
  bottom: 0;
  
  ${(props) =>
    props.fromLeft &&
    css`
      left: 0;
    `}
  
  ${(props) =>
    !props.fromLeft &&
    css`
      right: 0;
    `}

  &:enter {
    transform: translateX(${(props) => (props.fromLeft ? '-100%' : '100%')});
  }
  &:enter-active {
    transform: translateX(0);
    transition: transform ${(props) => props.timeout}ms ease-in-out;
  }
  &:exit {
    transform: translateX(0);
  }
  &:exit-active {
    transform: translateX(${(props) => (props.fromLeft ? '-100%' : '100%')});
    transition: transform ${(props) => props.timeout}ms ease-in-out;
  }
`;
