import React from 'react';
import { func, string } from 'prop-types';
import { useSelector } from 'react-redux';

import { Wrapper, Title, Action, ActionButton, Text } from './styles';

const ErrorMessage = ({ buttonLabel, buttonPreamble, buttonOnClick, errorMessage }) => {
  const websiteStrings = useSelector(({ bootstrap }) => bootstrap?.data?.websiteStrings);

  return (
    <Wrapper>
      <Title>{websiteStrings?.myPagesErrorMessageTitle || 'Something went wrong'}</Title>

      <Text>{errorMessage || "Sorry, we're having some technical difficulties. Please try again later."}</Text>

      {buttonLabel && (
        <Action>
          {buttonPreamble ? `${buttonPreamble} ` : ''}
          <ActionButton type="button" onClick={buttonOnClick}>
            {buttonLabel}
          </ActionButton>
        </Action>
      )}
    </Wrapper>
  );
};

ErrorMessage.propTypes = {
  buttonLabel: string,
  buttonPreamble: string,
  buttonOnClick: func,
  errorMessage: string
};

ErrorMessage.defaultProps = {
  buttonLabel: '',
  buttonPreamble: '',
  buttonOnClick: () => {},
  errorMessage: ''
};

export default ErrorMessage;
