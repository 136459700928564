import { useMemo } from 'react';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';

import { connect } from 'store/store';

import { useCartRecommendations } from 'api/depict';

import { Conditional } from 'components/Conditional/Conditional';
import { SubHeading } from 'components/SubHeading/SubHeading';
import { String } from 'components/String/String';
import {
  LocalizedLink,
  LocalizedLinkPropType,
} from 'components/LocalizedLink/LocalizedLink';

import {
  ProductImage,
  ProductPrice,
  ProductSectionWrapper,
  ProductTitle,
  ProductWrapper,
  RecommendationsSection,
} from './styles';

type Props = {
  market: string;
  currencyCode: string;
  locale: string;
  pathHistory: string[];
  routerHistory: string[];
  orderRows: any[];
};

type LocalizedLinkProps = PropTypes.InferProps<typeof LocalizedLinkPropType>;

const LinkComponent = LocalizedLink as React.FC<LocalizedLinkProps>;

const DepictCartRecommendations: React.FC<Props> = (props) => {
  const { pathHistory, orderRows = [], locale, currencyCode } = props;

  const router = useRouter();

  const isHoudiniProductPage =
    pathHistory && pathHistory[pathHistory?.length - 1] === '/HoudiniProduct';

  const productIds = useMemo(() => {
    const parsedOrderRows = orderRows
      .map(
        (row) =>
          `${row?.product?.id.split('/').pop()}?color=${
            row?.product?.modelColorId
          }`
      )
      .filter(Boolean);

    if (isHoudiniProductPage) {
      const productPageId = router.asPath.split('/').pop();
      return [productPageId];
    }

    return Array.from(new Set(parsedOrderRows));
  }, [isHoudiniProductPage, orderRows, router.asPath]);

  const { products, error } = useCartRecommendations({
    productIds,
    locale,
    currency: currencyCode,
  });

  if (error) return null;

  return (
    <RecommendationsSection>
      <Conditional show={!!products?.length}>
        <SubHeading centerMobile>
          <String id="linkedProductsLabel" />
        </SubHeading>

        <ProductSectionWrapper>
          {products
            ? products.slice(0, 3).map((product) => {
                const variant = product.variants[product.selectedVariantIndex];
                const url = `${product.id}?color=${variant.id}`;
                return (
                  <ProductWrapper
                    key={product.id}
                    data-recommendation-id={
                      product.depictConfig.recommendationId
                    }
                  >
                    <LocalizedLink
                      as={`${url}`}
                      page="HoudiniProduct"
                      data={{
                        id: product.id,
                        color: variant.id,
                      }}
                    >
                      <ProductImage
                        src={variant.images[0].urlPath}
                        alt={product.title}
                        heightRatio={1.4}
                        quality={50}
                        sizes="100px"
                      />
                    </LocalizedLink>
                    <LinkComponent
                      as={`${url}`}
                      page="HoudiniProduct"
                      data={{
                        id: product.id,
                        color: variant.id,
                      }}
                    >
                      <ProductTitle>{product.title}</ProductTitle>
                    </LinkComponent>
                    <ProductPrice>
                      {variant.sizes[0].price.list <
                      variant.sizes[0].price.reduced ? (
                        <>
                          <span>{variant.sizes[0].price.list}</span>
                          <span>{variant.sizes[0].price.reduced}</span>
                        </>
                      ) : (
                        variant.sizes[0].price.list
                      )}
                    </ProductPrice>
                  </ProductWrapper>
                );
              })
            : null}
        </ProductSectionWrapper>
      </Conditional>
    </RecommendationsSection>
  );
};

export default connect(DepictCartRecommendations, {}, (store: any) => ({
  market: store.viewState.market,
  currencyCode: store.bootstrap.data.currencyCode,
  locale: store.viewState.language,
  pathHistory: store.viewState.pathHistory,
  routerHistory: store.viewState.routerHistory,
  orderRows: store.cart.data.orderRows,
}));
