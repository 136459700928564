import React from 'react';
import { useCookies } from 'react-cookie';
import PropTypes from 'prop-types';

import { BannerMessage } from './BannerMessage';

export const BannerMessageList = ({ messages, isMobile }) => {
  // Use cookies to store if a user already has seen and closed a message.
  const [cookies] = useCookies();

  // Map over messsages, but  filter old ones.
  return (
    <>
      {messages.map(({ id, html, htmlMobile, type }) => {
        if (cookies[id]) return false;

        const htmlToRender = isMobile && (htmlMobile && htmlMobile.length > 0) ? htmlMobile : html;

        return <BannerMessage key={id} type={type} id={id} html={htmlToRender} />;
      })}
    </>
  );
};

BannerMessageList.defaultProps = {
  messages: [],
  isMobile: false
};

BannerMessageList.propTypes = {
  messages: PropTypes.arrayOf(PropTypes.object),
  isMobile: PropTypes.bool
};
