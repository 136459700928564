import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import { setViewstate } from '../../../store/actions/actions';

import { forgotPassword } from '../../../api/myPages';

import { LOGIN } from '../../../constants/modals';

import { Input } from '../../FormElements/Input';
import ErrorMessage from '../ErrorMessage';

import { Wrapper, Title, Form, SendButton, Action, ActionButton, InfoText } from '../styles';

const ForgotPassword = () => {
  const dispatch = useDispatch();
  const market = useSelector(state => state?.viewState?.market);
  const websiteStrings = useSelector(state => state?.bootstrap?.data?.websiteStrings);

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm();

  const [showNotice, setShowNotice] = useState(false);
  const [showError, setShowError] = useState(false);

  const onSubmit = formData => {
    setShowError(false);

    forgotPassword(formData, { market }).then(({ status }) => {
      if (status === 200) setShowNotice(true);
      else setShowError(true);
    });
  };

  const openLoginModal = () => dispatch(setViewstate('activeModal', LOGIN));

  if (showError) {
    return <ErrorMessage buttonLabel="Back to log in" buttonOnClick={openLoginModal} />;
  }

  return (
    <Wrapper>
      <Title>{websiteStrings?.myPagesForgotPassword || 'Forgot password'}</Title>

      {showNotice && (
        <InfoText>
          {websiteStrings?.myPagesForgotPasswordNotice ||
            'We have sent you an email with further instructions. Follow them to complete resetting your password.'}
        </InfoText>
      )}

      {!showNotice && (
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Input
            name="email"
            label="Email"
            type="email"
            placeholder="example@email.com"
            error={errors?.email}
            register={register('email', {
              required: 'Required',
              pattern: {
                value: /^([a-zA-Z0-9_\-+.]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,5})$/,
                message: websiteStrings?.myPagesForgotPasswordEmailValidation || 'Not an email'
              }
            })}
          />
          <SendButton>{websiteStrings?.myPagesForgotPasswordResetPassword || 'Reset password'}</SendButton>
          <Action>
            <ActionButton type="button" onClick={openLoginModal}>
              {websiteStrings?.myPagesForgotPasswordBackToLogin || 'Back to Log in'}
            </ActionButton>
          </Action>
        </Form>
      )}
    </Wrapper>
  );
};

export default ForgotPassword;
