import styled, { css } from 'styled-components';
import { fonts } from '../../styles/typography';
import { color } from '../../styles/variables';

export const ProductCardPrice = styled.p`
  ${fonts.NeoSansRegular};
  display: inline-block;
  margin: 3px 0;
  font-size: 13px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${color.black} !important;
  + p {
    margin-left: 10px;
  }

  ${props =>
    props.oldPrice &&
    css`
      text-decoration: line-through;
      color: #8d8d8d !important;
    `};
`;
