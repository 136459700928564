import styled, { css } from 'styled-components';
import { fonts } from './typography';
import { color, thresholds } from './variables';
import { media, dynamicFontSize } from './mixins';

export const NothingInCart = styled.h1`
  ${fonts.NeoSansLight};
  ${dynamicFontSize(32, 45)};
  margin: 34px 0 45px;
  text-align: center;

  &:after {
    content: '';
    display: block;
    position: relative;
    top: 20px;
    margin: 12px auto 10px;
    height: 1px;
    width: 120px;
    background-color: ${color.black};
  }
`;

export const NothingInCartMessage = styled.p`
  ${fonts.GoudyOldStyleRegular};
  text-align: center;
  max-width: 650px;
  margin: 10px auto;
  padding: 0 20px;
  line-height: 1.5;

  ${media.M`
    padding: 0;
  `};
`;

export const CheckoutMainHeading = styled.h1`
  ${fonts.NeoSansLight};
  ${dynamicFontSize(32, 45)};
  margin: 34px 0;
  font-stretch: normal;
  text-align: center;
  line-height: 1.14;
  letter-spacing: -0.2px;
  color: ${color.black};

  &:after {
    content: '';
    display: block;
    margin: 12px auto 10px;
    height: 1px;
    width: 120px;
    background-color: ${color.black};
  }

  ${media.M`
    text-align: left;

    &:after {
      margin-top: 12px;
      margin-left: 0;
    }
  `};
`;
export const ContentRoot = styled.div`
  width: 100%;

  ${media.M`
    display: flex;
    justify-content: space-between;
    align-items: stretch;
  `};
`;
export const ContentArea = styled.div`
  flex: 1;
  min-height: 800px;
  padding: 0 20px;
`;

export const FakeCart = styled.div`
  position: relative;
  background-color: white;
  min-width: 400px;
  width: 40vw;
  max-width: 550px;
  margin-left: 20px;
  flex: 1;

  ${media.L`
    margin-left: 80px;
  `};
`;

export const BackToPage = styled.a`
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  ${fonts.NeoSansRegular};
  font-size: 14px;
  text-decoration: none;
  color: ${props => props.theme.color.black};
  background-color: ${props => props.theme.color.primary};

  span {
    margin-left: 16px;
  }
`;

export const SubTitle = styled.h2`
  ${fonts.NeoSansRegular};
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
  margin: 50px 0 20px;
  ${props =>
    props.disabled &&
    css`
      color: ${props.theme.color.disabled};
    `};
`;

export const CartArea = styled.div`
  background-color: white;
  width: 100%;
  border: solid 1px ${color.grey};
  padding: 30px 20px 10px;

  ${props =>
    props.noTopBorder &&
    css`
      border-top-width: 0;
    `};

  ${props =>
    props.evenPadding &&
    media.L`
     padding: 30px 20px 30px;
  `};

  margin-bottom: 20px;

  ${props =>
    props.paymentWrapper &&
    css`
      margin-bottom: 0;
      ${media.M`
      margin-bottom: 100px;
    `};
    `};

  ${props =>
    props.noPadding &&
    css`
      padding: 0;
    `};
`;

export const CartAreaSummary = styled(CartArea)`
  position: sticky;
  top: -58px;
  z-index: 10;

  ${props =>
    props.sticky &&
    css`
      margin-left: -20px;
      width: calc(100% + 40px);
    `};

  ${props =>
    props.sticky &&
    props.active &&
    css`
      top: 60px;
      transition: top 200ms ease-out;
    `};

  ${props =>
    props.sticky &&
    !props.active &&
    css`
      top: -58px;
      transition: top 200ms ease-out;
    `};
`;

export const SummaryButton = styled.a`
  cursor: pointer;
`;

export const CartItemsContainer = styled.ul`
  list-style: none;
  padding: 0 20px;

  ${({ disable }) =>
    disable &&
    css`
      opacity: 0.5;
      pointer-events: none;
    `}
`;

export const AccordionContentContainer = styled.div`
  padding: 0 20px;
  ${props =>
    props.disabled &&
    css`
      opacity: ${thresholds.disabledOpacity};
    `};
`;

export const TotalRow = styled.ul`
  padding-left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  list-style: none;
  margin: 20px 0 16px;

  li {
    ${fonts.NeoSansRegular};
    font-size: 14px;
  }
`;

export const TotalRowSticky = styled(TotalRow)`
  margin: 10px 0 0 0;
  padding: 0;
  height: 36px;

  li {
    ${fonts.NeoSansRegular};
    font-size: 14px;

    &.stickyGrandTotal {
      flex-grow: 1;
      text-align: right;
      padding-right: 14px;
    }
  }
`;

export const UpperSummaryList = styled.ul`
  display: flex;
  align-items: center;
  justify-content: space-between;
  list-style: none;
  padding: 0;
  margin: 0 0 10px 0;
  li {
    ${fonts.NeoSansLight};
    font-size: 14px;
  }
  + hr {
    margin-top: 20px;
  }
`;

export const CampaignCodeInfo = styled.p`
  ${fonts.GoudyOldStyleItalic};
  font-size: 16px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #000000;
  margin-bottom: 0;
  max-width: 340px;
`;

export const CampaignCodeFeedbackText = styled.p`
  ${fonts.NeoSansRegular};
  font-size: 13px;
  font-weight: 400;
  color: ${props => props.theme.color.black};
`;

export const SummaryTotalContainer = styled.div`
  background-color: #f9f9f9;
  height: 53px;
`;

export const SummaryTotal = styled(UpperSummaryList)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 7px;
  padding: 0 18px;
`;
