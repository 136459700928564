import React, { Component, Fragment } from 'react';
import { get, getOr, isEmpty } from 'lodash/fp';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from '../../store/store';
import { setViewstate, removeViewstate, cartRequest } from '../../store/actions/actions';
import { Icon } from '../Icon/Icon';
import { ICONS } from '../../constants/icons';
import { hover } from '../../styles/mixins';
import { fonts } from '../../styles/typography';
import { axiosSingleton } from '../../api/houdini';
import { ingridSuspend, ingridResume } from '../../helpers/ingrid';
import { klarnaSuspend, klarnaResume } from '../../helpers/klarna';
import { Conditional } from '../Conditional/Conditional';
import { CampaignCodeFeedbackText } from '../../styles/checkoutStyle';
import { color } from '../../styles/variables';

export class CampaignCodeInputComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inputValue: null,
      inputError: false
    };
  }

  onChangeHandler(event) {
    this.setState({ inputValue: event.target.value });
    this.removeErrorMessage();
  }

  onSubmitHandler(event) {
    event.preventDefault();
    const { market, isAdyen } = this.props;

    this.suspendCheckout(isAdyen);

    axiosSingleton
      .post(`/checkout/setCampaignCode`, { campaignCode: this.state.inputValue }, { params: { market } })
      .then(response => {
        if (response.status === 200) {
          if (get(['data', 'changeOccured'], response) === true) {
            this.props.setViewstate('campaignCode', this.state.inputValue);
            this.removeErrorMessage();
            this.props.cartRequest({ market });
          } else {
            this.setState({ inputError: true });
          }
        } else {
          this.setState({ inputError: true });
        }
        this.resumeCheckout(isAdyen);
      })
      .catch(() => {
        this.setState({ inputError: true, inputValue: null });
      });
  }

  removeActiveCode(codeToRemove) {
    const { market, isAdyen } = this.props;

    this.suspendCheckout(isAdyen);

    axiosSingleton
      .post(`/checkout/removeCampaignCode`, { campaignCode: codeToRemove }, { params: { market } })
      .then(response => {
        if (response.status === 200) {
          if (get(['data', 'changeOccured'], response) === true) {
            this.props.removeViewstate('campaignCode');
            this.props.cartRequest({ market });
          }
        } else {
          this.setState({ inputError: true });
        }
        this.resumeCheckout(isAdyen);
      })
      .catch(() => {
        this.setState({ inputError: true, inputValue: null });
      });
  }

  removeErrorMessage() {
    this.setState({ inputError: false });
  }

  suspendCheckout(isAdyen) {
    ingridSuspend();
    if (!isAdyen) {
      klarnaSuspend();
    }
  }

  resumeCheckout(isAdyen) {
    ingridResume();
    if (!isAdyen) {
      klarnaResume();
    }
  }

  render() {
    const { campaignCode, websiteStrings, disabled, initialValue } = this.props;
    const { inputValue } = this.state;

    if (campaignCode || initialValue) {
      return (
        <Fragment>
          <ActiveCode>
            <ActiveCodeText>{campaignCode || initialValue}</ActiveCodeText>
            <RemoveCode onClick={() => this.removeActiveCode(inputValue || initialValue)} disabled={disabled}>
              <Icon icon={ICONS.CLOSE_SMALL} size={8} color={color.white} />
            </RemoveCode>
          </ActiveCode>
          <CampaignCodeFeedbackText>
            {getOr('{{campaignCodeInfoActive}}', 'campaignCodeInfoActive', websiteStrings)}
          </CampaignCodeFeedbackText>
        </Fragment>
      );
    }

    return (
      <Fragment>
        <Root as="form" onSubmit={e => this.onSubmitHandler(e)}>
          <TextInput
            onChange={e => this.onChangeHandler(e)}
            placeholder={getOr('{{campaignCodeInputPlaceholder}}', 'campaignCodeInputPlaceholder', websiteStrings)}
            disabled={disabled}
          />
          <SendButton disabled={isEmpty(this.state.inputValue) || disabled}>
            {getOr('{{campaignCodeButtonLabel}}', 'campaignCodeButtonLabel', websiteStrings)}
          </SendButton>
        </Root>

        <Conditional show={this.state.inputError}>
          <ErrorMessage>
            <ErrorText>
              {getOr('{{campaignCodeValidationError}}', 'campaignCodeValidationError', websiteStrings)}
            </ErrorText>
            <RemoveCode onClick={() => this.removeErrorMessage()} disabled={disabled}>
              <Icon icon={ICONS.CLOSE_SMALL} size={8} color={color.white} />
            </RemoveCode>
          </ErrorMessage>
        </Conditional>
      </Fragment>
    );
  }
}

const ActiveCode = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  max-width: 340px;
  background-color: ${props => props.theme.color.activeGreen};

  p {
    color: ${props => props.theme.color.white};
  }
`;

const ActiveCodeText = styled.p`
  ${fonts.NeoSansRegular};
  padding: 0 15px;
  margin: 0;
  font-size: 13px;
  color: #000000;
`;

const RemoveCode = styled.button`
  width: 38px;
  height: 38px;
  margin: 0;
  padding: 0;
  border: none;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: opacity 250ms ease-out;

  ${hover`
    opacity: .6;
  `};
`;

const ErrorMessage = styled(ActiveCode)`
  margin-top: 20px;
  background-color: #b297a5;
`;

const ErrorText = styled(ActiveCodeText)`
  color: ${props => props.theme.color.white};
`;

const Root = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: center;
  max-width: 340px;
`;

export const TextInput = styled.input`
  flex: 1;
  ${fonts.NeoSansLight};
  border: 1px solid ${props => props.theme.color.grey};
  padding-left: 15px;
  min-height: 50px;
  font-size: 16px;
  color: ${props => props.theme.color.black};
  -webkit-appearance: none;
  border-radius: 0;

  &::placeholder {
    color: ${props => props.theme.color.superGrey};
    font-style: italic;
  }

  &:focus {
    outline: none;
    border-bottom-color: ${props => props.theme.color.superGrey};
  }

  @keyframes onAutoFillStart {
    from {
      /**/
    }
    to {
      /**/
    }
  }

  @keyframes onAutoFillCancel {
    from {
      /**/
    }
    to {
      /**/
    }
  }

  &:-webkit-autofill {
    // Expose a hook for JavaScript when auto fill is shown.
    // JavaScript can capture 'animationstart' events
    animation-name: onAutoFillStart;
    animation-duration: 0.001s;

    // Make the backgound color become yellow _really slowly_
    transition: background-color 50000s ease-in-out 0s;
  }

  &:not(:-webkit-autofill) {
    // Expose a hook for JS onAutoFillCancel
    // JavaScript can capture 'animationstart' events
    animation-name: onAutoFillCancel;
  }
`;

export const SendButton = styled.button.attrs({
  type: 'submit'
})`
  ${fonts.NeoSansRegular};
  min-width: 95px;
  min-height: 50px;
  font-size: 14px;
  text-align: center;
  border: none;
  outline: none;
  border-radius: 0;
  box-shadow: none;
  color: ${props => props.theme.color.white};
  background: ${props => props.theme.color.black};
  cursor: pointer;
  transition: opacity 0.25s ease-out;

  &:disabled {
    opacity: 0.6;
    cursor: default;
  }
`;

CampaignCodeInputComponent.defaultProps = {
  disabled: false,
  websiteStrings: {},
  isAdyen: false,
  campaignCode: null,
  initialValue: null
};

CampaignCodeInputComponent.propTypes = {
  cartRequest: PropTypes.func.isRequired,
  setViewstate: PropTypes.func.isRequired,
  removeViewstate: PropTypes.func.isRequired,
  websiteStrings: PropTypes.object,
  disabled: PropTypes.bool,
  isAdyen: PropTypes.bool,
  campaignCode: PropTypes.string,
  initialValue: PropTypes.string,
  market: PropTypes.string.isRequired
};

export const CampaignCodeInput = connect(
  CampaignCodeInputComponent,
  { setViewstate, removeViewstate, cartRequest },
  store => ({
    campaignCode: get('viewState.campaignCode', store),
    websiteStrings: get(['bootstrap', 'data', 'websiteStrings'], store),
    market: get(['viewState', 'market'], store)
  })
);
