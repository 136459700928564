/* eslint-disable @typescript-eslint/no-floating-promises */
import { useEffect, useState, useCallback } from 'react';

type VideoType = HTMLVideoElement;

const isVideoPlaying = (video: VideoType) =>
  !!(video.currentTime > 0 && !video.paused && !video.ended);

const isIOS = () =>
  typeof window !== 'undefined' &&
  /iPad|iPhone|iPod/.test(window.navigator.userAgent);

const playVideo = async (video: VideoType) => {
  await video.play().catch((e) => {
    throw new Error(e);
  });
};

type Props = {
  dependencies?: unknown[];
  autoplay?: boolean;
};

type Return = {
  setVideoRef: (node?: Element) => void;
};

/**
 * Fix attempt for iOS video autoplay in battery saving mode.
 * @param options - Options object
 * @param options.dependencies - Array of dependencies to watch for changes
 */
const useIOSVideoFix = (options: Props): Return => {
  const { dependencies = [], autoplay = true } = options;
  const [videoRef, setRef] = useState<VideoType>(null);

  const isIPhone = isIOS();

  const setVideoRef = useCallback((node?: Element) => {
    if (node) {
      setRef(node as VideoType);
    }
    return node;
  }, []);

  useEffect(() => {
    if (videoRef && isIPhone) {
      if (!isVideoPlaying(videoRef) && (autoplay || videoRef.autoplay)) {
        if (
          typeof window !== 'undefined' &&
          process.env.NEXT_PUBLIC_ENVIRONMENT !== 'PRODUCTION'
        ) {
          console.info('DEBUG: iOS video fix applied.', {
            ref: videoRef,
          });
        }
        document.addEventListener(
          'touchstart',
          playVideo.bind(null, videoRef),
          { passive: true }
        );
        document.addEventListener('click', playVideo.bind(null, videoRef), {
          passive: true,
        });
      }
    }

    return () => {
      if (videoRef) {
        document.removeEventListener(
          'touchstart',
          playVideo.bind(null, videoRef)
        );
        document.removeEventListener('click', playVideo.bind(null, videoRef));
      }
    };
  }, [videoRef, isIPhone, autoplay, ...dependencies]);

  return { setVideoRef };
};

export default useIOSVideoFix;
