import React, { useRef, useState, useEffect } from 'react';
import { useRouter } from 'next/router';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

import { media } from '../../styles/mixins';
import { Icon } from '../Icon/Icon';
import { ICONS } from '../../constants/icons';
import useOnScreen from '../../hooks/useOnScreen';
import { productVideoPlay } from '../../helpers/gtm';
import useIOSVideoFix from '../../hooks/useIOSVideoFix';

const VIDEO_ACTIONS = {
  PLAY: 'play',
  PAUSE: 'pause',
};

const Video = ({
  src,
  cover,
  activeSlide,
  activeModal,
  relative,
  m,
  inheritHeight,
  playOnViewport,
  hideButton,
  muted,
  loop,
  takeOverContainer,
  disableControls,
  playsInline,
  link,
  market,
  absolute,
  autoplay,
  videoDescription,
  ariaID,
}) => {
  const [playing, setPlaying] = useState(false);
  const videoRef = useRef();
  const isVisible = useOnScreen(videoRef);
  const router = useRouter();

  const { setVideoRef } = useIOSVideoFix({
    dependencies: [src],
    autoplay: autoplay && playOnViewport,
  });

  const play = (ref) => {
    if (src && ref.current) {
      productVideoPlay(src);
      ref.current.play().catch((e) => console.warn(e));
    }
  };

  const pause = (ref) => {
    if (src && ref.current) ref.current.pause();
  };

  useEffect(() => {
    if (!playOnViewport) pause(videoRef);
  }, [activeSlide, activeModal]);

  useEffect(() => {
    if (playOnViewport && autoplay) {
      if (isVisible) {
        if (!playing) {
          play(videoRef);
        }
      } else if (playing) {
        pause(videoRef);
      }
    }
  }, [isVisible]);

  const handleClick = () => {
    if (link?.link?.id) router.push(`${market}/${link.link.id}`);
  };

  const handleVideoAction = (action) => {
    if (action === VIDEO_ACTIONS.PLAY && videoRef.current.paused) {
      play(videoRef);
    }
    if (action === VIDEO_ACTIONS.PAUSE && !videoRef.current.paused) {
      pause(videoRef);
    }
  };

  const handleEnded = () => setPlaying(false);
  const handlePause = () => setPlaying(false);
  const handlePlay = () => setPlaying(true);
  const handleCanPlay = () => {
    if (playOnViewport && isVisible && !playing && autoplay) {
      play(videoRef);
    }
  };
  const shouldHideControls = disableControls || src === '';

  return (
    <StyledVideoWrapper
      disableControls={disableControls}
      takeOverContainer={takeOverContainer}
      playing={playing}
      onClick={() => !disableControls && handleClick()}
      m={m}
      inheritHeight={inheritHeight}
      relative={relative}
      absolute={absolute}
    >
      {!hideButton &&
        (playing ? (
          <Icon
            color="white"
            icon={ICONS.VIDEO_PAUSE}
            size={64}
            onClick={() => handleVideoAction(VIDEO_ACTIONS.PAUSE)}
          />
        ) : (
          <Icon
            color="white"
            icon={ICONS.VIDEO_PLAY}
            size={64}
            onClick={() => handleVideoAction(VIDEO_ACTIONS.PLAY)}
          />
        ))}

      <StyledVideo
        controls={!shouldHideControls}
        relative={relative}
        ref={(node) => {
          videoRef.current = node;
          setVideoRef(node);
        }}
        poster={cover}
        onClick={(e) => e.stopPropagation()}
        onEnded={() => handleEnded()}
        onPause={() => handlePause()}
        onPlay={() => handlePlay()}
        onCanPlay={() => handleCanPlay()}
        muted={muted}
        loop={loop}
        playsInline={playsInline}
        aria-describedby={ariaID}
      >
        <StyledSource type="video/mp4" src={src ? `${src}#t=0.1` : ''} />
        {videoDescription && (
          <StyledDescription id={ariaID}>{videoDescription}</StyledDescription>
        )}
      </StyledVideo>
    </StyledVideoWrapper>
  );
};

export default Video;

export const StyledVideoWrapper = styled.div`
  position: relative;
  cursor: ${({ disableControls }) => (disableControls ? 'default' : 'pointer')};
  margin: ${({ m }) => m};
  padding-bottom: ${({ pb }) => pb};
  width: 100%;
  height: 100%;
  display: flex;

  ${({ inheritHeight }) =>
    inheritHeight &&
    css`
      max-height: 300px;

      ${media.M`
        max-height: 450px;
      `}

      ${media.L`
        max-height: 670px;
      `}
    `};

  &:hover {
    svg {
      opacity: 0.5;
    }
  }

  svg {
    transition: 0.2s ease-out;
    fill: white;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
    opacity: 0.5;
  }

  ${({ playing }) =>
    playing &&
    css`
      svg {
        opacity: 0;
      }
    `}

  ${({ takeOverContainer }) =>
    takeOverContainer &&
    css`
      position: absolute;
      z-index: -1;
    `};

  ${({ absolute }) =>
    absolute &&
    css`
      position: absolute;
    `};

  ${({ relative }) =>
    relative &&
    css`
      position: relative;
    `};
`;

export const StyledVideo = styled.video`
  width: 100%;
  object-fit: cover;
  object-position: center;
  left: 0;
  top: 0;
  position: ${({ relative }) => (relative ? 'relative' : 'absolute')};
`;

export const StyledSource = styled.source``;

export const StyledDescription = styled.p`
  position: absolute;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px 1px 1px 1px); /* IE 7+ only support clip without commas */
  clip: rect(1px, 1px, 1px, 1px);
`;

Video.defaultProps = {
  src: '',
  cover: '',
  activeSlide: 0,
  activeModal: '',
  relative: false,
  m: '',
  inheritHeight: false,
  playOnViewport: false,
  hideButton: false,
  muted: true,
  loop: false,
  takeOverContainer: false,
  disableControls: false,
  playsInline: false,
  link: {},
  market: '',
  absolute: false,
  autoplay: false,
  videoDescription: '',
  ariaID: '',
};

Video.propTypes = {
  src: PropTypes.string,
  cover: PropTypes.string,
  activeSlide: PropTypes.number,
  activeModal: PropTypes.string,
  relative: PropTypes.bool,
  m: PropTypes.string,
  inheritHeight: PropTypes.bool,
  playOnViewport: PropTypes.bool,
  hideButton: PropTypes.bool,
  muted: PropTypes.bool,
  loop: PropTypes.bool,
  takeOverContainer: PropTypes.bool,
  disableControls: PropTypes.bool,
  playsInline: PropTypes.bool,
  absolute: PropTypes.bool,
  link: PropTypes.object,
  market: PropTypes.string,
  autoplay: PropTypes.bool,
  videoDescription: PropTypes.string,
  ariaID: PropTypes.string,
};
