import styled, { css } from 'styled-components';
import { media, dynamicFontSize, hover } from './mixins';
import { fonts } from './typography';
import { color, margins } from './variables';

export const VariantSelectContainer = styled.div`
  position: relative;
  // height: 60px;
  margin: 35px 0 15px;
  ${media.SBP`
    margin: 30px 0 13px;
    
				${({ m }) =>
          m &&
          css`
            margin: ${m};
          `}
  `};
`;

export const ChosenColor = styled.div`
  min-height: 25px;
  ${fonts.NeoSansLight};
  font-size: 13px;
  color: #000000;
  text-align: center;
  ${media.SBP`
    text-align: left;
  `};

  ${({ left }) =>
    left &&
    css`
      text-align: left;
    `};
`;

export const RadioButtonsContainer = styled.div`
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;

  ${media.SBP`
    text-align: left;
    margin-left: -6px;
    justify-content: flex-start;
  `};

  ${({ left }) =>
    left &&
    css`
      justify-content: flex-start;
      margin-left: -6px;
      text-align: left;
    `};
`;

export const RadioButtonSection = styled.div`
  margin-bottom: 6px;
  min-height: 32px;
  display: flex;
  flex-wrap: wrap;
`;

export const AccParagraph = styled.p`
  ${fonts.GoudyOldStyleRegular};
  font-size: 14px;
  line-height: 1.5;
  ${(props) =>
    props.heading &&
    css`
      font-size: 14px;
      line-height: 24px;
      margin-bottom: 0;
    `};
    ${(props) => props.heading && fonts.NeoSansLight}

  }
`;

export const CartButton = styled.button`
  position: relative;
  background-color: #000;
  color: #fff;
  border: none;
  width: 100%;
  height: 51px;
  cursor: pointer;
  overflow: hidden;
  margin: 40px 0 20px;
  transition: opacity 250ms ease-out;

  &:focus {
    outline: 0;
  }

  ${(props) =>
    props.isDisabled &&
    css`
      color: #000;
      cursor: auto;
      background-color: #f4f4ef;

      &:hover {
        > span {
          top: 0;
          &:last-child {
            top: 100%;
          }
        }
      }

      ${props.isMobile &&
      props.showSelectSizeText &&
      css`
        > span {
          top: 0 !important;
          &:last-child {
            top: 100% !important;
          }
        }
      `};

      ${props.showOutOfStockMessage &&
      css`
        color: #a8001b;
        cursor: auto;
        background-color: #f4f4ef;
        transition: color 250ms ease-out;

        &:hover {
          > span {
            top: -100% !important;
            &:last-child {
              top: 0 !important;
            }
          }
        }
      `};
    `};

  ${(props) =>
    !props.isDisabled &&
    css`
      ${hover`
        opacity: .8;
      `};
    `};

  > span {
    display: flex;
    align-items: center;
    justify-content: center;
    left: 0;
    top: -100%;
    position: absolute;
    height: 100%;
    width: 100%;
    font-size: 14px;
    transition: all 0.25s ease-in-out;
    &:last-child {
      top: 0;
    }
  }

  span {
    pointer-events: none;
  }
`;

export const FindStoreNearYouButton = styled.button`
  position: relative;
  background-color: #000;
  color: #fff;
  border: none;
  width: 100%;
  height: 51px;
  cursor: pointer;
  overflow: hidden;
  margin: 40px 0 20px;
  padding: 0;
  transition: opacity 250ms ease-out;

  &:focus {
    outline: 0;
  }

  a {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    ${fonts.NeoSansRegular};
    line-height: 2.5;
    font-size: 14px;
    color: #fff;
    text-decoration: none;
  }

  ${hover`
    opacity: .8;
  `};
`;

export const FindStoreLink = styled.a``;

export const PriceHolder = styled.div`
  text-align: center;
`;

export const TopContainer = styled.div`
  display: flex;
  width: auto;
  align-items: center;
  flex-direction: column;
  ${media.SBP`
  flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: baseline;
  `};
`;

export const ProductName = styled.h1`
  ${fonts.NeoSansRegular};
  margin: 0;
  margin-bottom: 15px;
  line-height: 35px;
  ${media.M`
    line-height: 42px;
  `};
  ${dynamicFontSize(25, 32)};
`;

export const TestfreaksBadge = styled.div`
  display: none;
  margin: 15px 0 0;
  ${media.SBP`
    margin: 0;
  `};
  &.testfreaks-loaded {
    display: block;
  }
`;

export const ProductText = styled.p`
  ${fonts.GoudyOldStyleRegular};
  text-align: center;
  line-height: 24px;
  width: 100%;
  max-width: 570px;
  ${media.SBP`
    max-width: none;
    text-align: left;
  `};
`;

export const InnerContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  flex-direction: column;
  ${media.SBP`
      flex-direction: row;
    `};
`;

export const ProductInfoRightContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${media.SBP`
    width: calc(45.5% + 80px);
    align-items: baseline;
    padding: 0 0 0 80px;
  `};

  ${media.XXL`
    width: calc(40.5% + 80px);
  `};

  ${(props) =>
    props.wide &&
    css`
      padding: 0 ${margins.xSmall}px;
      ${media.S`
        padding: 0 ${margins.small}px;
      `};
      ${media.PM`
        padding: 0 auto;
      `};
    `};
`;

export const Divider = styled.hr`
  border: 0;
  height: 1px;
  width: 100%;
  margin: 0;
  background-color: ${color.borderGrey};

  ${(props) =>
    props.color &&
    css`
      background-color: ${props.color};
    `};

  ${(props) =>
    props.thinEdges &&
    css`
      background-color: transparent;
      background-image: linear-gradient(
        to right,
        rgba(0, 0, 0, 0),
        rgba(0, 0, 0, 0.75),
        rgba(0, 0, 0, 0)
      );
    `};
`;

export const NearlyOutOfStock = styled.div`
  display: flex;
  align-items: center;
  margin: 25px 0 -25px;
`;

export const NearlyOutOfStockIndicator = styled.div`
  display: inline-block;
  width: 13px;
  height: 13px;
  margin-right: 8px;
  background: rgb(255, 172, 27);
  border-radius: 50%;
`;

export const NearlyOutOfStockText = styled.p`
  ${fonts.NeoSansLight};
  font-size: 13px;
  line-height: 1;
  color: #000000;
  margin: 0;
`;

export const ModelCarryText = styled.p`
  ${fonts.NeoSansLight};
  font-size: 13px;
  line-height: 1.54;
  color: #000000;
  margin: 0;
`;

export const ModelCarryContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (min-width: 480px) {
    flex-direction: row;
  }
`;

export const SizeGuideLink = styled.button`
  ${fonts.NeoSansRegular};
  font-size: 13px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  border-bottom: 1px solid #000;
  color: ${color.black};
  &:focus {
    outline: none;
  }
  margin: 0;
  margin-bottom: 10px;
  @media (min-width: 480px) {
    margin-bottom: 0;
    margin: 0 15px 0 0;
  }
  padding: 0;

  span {
    display: block;
    position: relative;
    top: 1px;
  }
  opacity: 1;
  transition: all 0.1s ease-in-out;
  ${hover`
    opacity: 0.45;
    `};
`;

export const OutOfStockNoticeLink = styled(SizeGuideLink)`
  margin-top: 10px !important;
`;

export const ProductMessage = styled.div`
  ${fonts.GoudyOldStyleRegular};
  font-size: 16px;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #000000;
  background-color: ${(props) => props.theme.color.lightGrey};
  padding: 0;
  transition: margin-bottom 250ms ease-in-out, max-height 250ms ease-in-out,
    opacity 250ms ease-in-out, padding 250ms ease-in-out;
  max-height: 0;
  opacity: 0;
  padding: 0 20px;
  max-width: 300px;
  margin: 0 auto;

  ${(props) =>
    props.in &&
    css`
      margin-bottom: 30px;
      max-height: 200px;
      opacity: 1;
      padding: 15px 20px;
    `};
`;
