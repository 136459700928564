import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled, { css, keyframes } from 'styled-components';
import { useSelector } from 'react-redux';
import get from 'lodash/fp/get';
import { NEWSLETTER } from '../../constants/modals';

// duration of animation in milliseconds
const animationDuration = 820;

export const WiggleAnimation = ({ children, modalTarget }) => {
  const [lastActiveModal, setLastActiveModal] = useState();
  const [animationActive, setAnimationActive] = useState(false);
  const activeModal = useSelector((state) =>
    get(['viewState', 'activeModal'], state)
  );

  useEffect(() => {
    if (lastActiveModal === modalTarget && !activeModal) {
      setAnimationActive(true);
      setTimeout(() => {
        setAnimationActive(false);
      }, animationDuration);
    }

    setLastActiveModal(activeModal);
  }, [activeModal]);

  return <Root animationActive={animationActive}>{children}</Root>;
};

WiggleAnimation.defaultProps = {
  modalTarget: NEWSLETTER,
};

WiggleAnimation.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  modalTarget: PropTypes.string,
};

// ANIMATION
const Wiggle = keyframes`
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }
  
  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
`;

// STYLES
const Root = styled.div`
  ${(props) =>
    props.animationActive &&
    css`
      animation: ${Wiggle} ${animationDuration}ms
        cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
    `};
`;
