import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useRouter } from 'next/router';

import useUser from '../../../hooks/useUser';

import { loginRequest, setViewstate } from '../../../store/actions/actions';
import { verifyEmail, getUser } from '../../../api/myPages';

import { VERIFY_EMAIL_PARAM } from '../../../constants/queryStrings';
import { FAILURE, LOADING } from '../../../constants/requestStatuses';
import {
  CREATE_ACCOUNT,
  FORGOT_PASSWORD,
  LOGIN,
  LOGIN_PWD_RESET,
} from '../../../constants/modals';

import { Input } from '../../FormElements/Input';
import { Icon } from '../../Icon/Icon';
import { ICONS } from '../../../constants/icons';
import ErrorMessage from '../ErrorMessage';

import { color } from '../../../styles/variables';
import {
  Wrapper,
  Form,
  Title,
  SendButton,
  Action,
  ActionButton,
  NoticeHeading,
  ErrorText,
} from '../styles';
import { depictSetUserId } from '../../../helpers/depict';

const Login = () => {
  const { setUser } = useUser();
  const router = useRouter();
  const dispatch = useDispatch();
  const { market, activeModal } = useSelector((state) => state?.viewState);
  const myPages = useSelector((state) => state.myPages);
  const websiteStrings = useSelector(
    (state) => state?.bootstrap?.data?.websiteStrings
  );

  const [showEmailConfirmed, setShowEmailConfirmed] = useState(false);
  const [showPasswordUpdated, setShowPasswordUpdated] = useState(false);
  const [showError, setShowError] = useState(false);

  const {
    register,
    handleSubmit,
    clearErrors,
    formState: { errors },
  } = useForm();

  const onSubmit = (formData) => {
    dispatch(loginRequest({ formData, market }));
  };

  const openActionLink = () =>
    dispatch(setViewstate('activeModal', CREATE_ACCOUNT));
  const openResetPasswordModal = () =>
    dispatch(setViewstate('activeModal', FORGOT_PASSWORD));

  const resetUrl = () =>
    window.history.replaceState(null, null, window.location.pathname);

  const resetLoginForm = () => {
    setShowEmailConfirmed(false);
    setShowError(false);
    resetUrl();
    dispatch(setViewstate('activeModal', LOGIN));
  };

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    if (params.has(VERIFY_EMAIL_PARAM)) {
      verifyEmail({ token: params.get(VERIFY_EMAIL_PARAM) }, { market })
        .then(({ status }) => {
          if (status === 200) {
            resetUrl();
            setShowEmailConfirmed(true);
          } else setShowError(true);
        })
        .catch(() => {
          setShowError(true);
        });
    }

    if (activeModal === LOGIN_PWD_RESET) setShowPasswordUpdated(true);
  }, []);

  useEffect(() => {
    if (myPages?.data?.redirectUrl) {
      getUser(market).then(({ data }) => {
        setUser(data);
        router.push(
          `/${market}/${myPages?.data?.redirectUrl || 'my-pages'}`,
          null,
          { shallow: true }
        );
        depictSetUserId(data?.customerId);
      });
    }
  }, [myPages?.data]);

  if (showError) {
    return (
      <ErrorMessage
        buttonLabel="Go back to log in"
        buttonOnClick={resetLoginForm}
      />
    );
  }

  return (
    <Wrapper>
      <Title>{websiteStrings?.myPagesLogin || 'Login'}</Title>

      {showPasswordUpdated && (
        <NoticeHeading>
          <Icon icon={ICONS.INFO} size={36} color={color.darkSepia} />
          <div>
            <p>
              {websiteStrings?.myPagesPasswordChanged ||
                'Your password has been changed.'}
            </p>
            <p>
              {websiteStrings?.myPagesPasswordChangedLogin ||
                'Log in to your account using new password.'}
            </p>
          </div>
        </NoticeHeading>
      )}

      {showEmailConfirmed && (
        <NoticeHeading>
          <Icon icon={ICONS.INFO} size={36} color={color.darkSepia} />
          <div>
            <p>
              {websiteStrings?.myPagesEmailConfirmed ||
                'Your email has been confirmed.'}
            </p>
            <p>
              {websiteStrings?.myPagesEmailConfirmedLogin ||
                'You can now log in to your account.'}
            </p>
          </div>
        </NoticeHeading>
      )}

      <Form onSubmit={handleSubmit(onSubmit)}>
        <Input
          name="email"
          type="email"
          label="Email"
          placeholder="example@email.com"
          register={register('email', {
            required: 'Required',
            pattern: {
              value:
                /^([a-zA-Z0-9_\-+.]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,5})$/,
              message:
                websiteStrings?.myPagesLoginValidationNotAnEmail ||
                'Not an email',
            },
          })}
          error={errors?.email}
        />
        <Input
          name="password"
          type="password"
          label="Password"
          placeholder=""
          register={register('password', {
            required:
              websiteStrings?.myPagesLoginValidationPasswordRequired ||
              'Required',
          })}
          error={errors?.password}
        />
        <SendButton
          onClick={() => clearErrors('post')}
          disabled={myPages?.request?.status === LOADING}
        >
          {websiteStrings?.myPagesLogin || 'Login'}
        </SendButton>
        {myPages?.request?.status === FAILURE && (
          <ErrorText>
            {websiteStrings?.myPagesLoginError || 'Something went wrong'}
          </ErrorText>
        )}
        <Action>
          {websiteStrings?.myPagesNoAccount || 'Don’t have an account yet?'}{' '}
          &nbsp;
          <ActionButton type="button" onClick={openActionLink}>
            {websiteStrings?.myPagesCreateAccount || 'Create an account'}
          </ActionButton>
        </Action>
        <Action>
          {websiteStrings?.myPagesForgotPassword || 'Forgot your password?'}{' '}
          &nbsp;
          <ActionButton type="button" onClick={openResetPasswordModal}>
            {websiteStrings?.myPagesClickHere || 'Click here'}
          </ActionButton>
        </Action>
      </Form>
    </Wrapper>
  );
};

export default Login;
