import { useState } from 'react';
import { useRouter } from 'next/router';
import { getUser, logout } from '../api/myPages';
import { depictSetUserId } from '../helpers/depict';

export default function useUser() {
  const router = useRouter();

  const getUserData = () => {
    if (typeof window !== 'undefined') {
      const userString = window.localStorage.getItem('user');
      return JSON.parse(userString);
    }

    return {};
  };

  const [user, setUser] = useState(getUserData());

  const saveUser = (userData) => {
    if (typeof window !== 'undefined') {
      window.localStorage.setItem('user', JSON.stringify(userData));
      setUser(userData);
      depictSetUserId(userData?.customerId);
    }
  };

  const clearUserData = (market, redirect = false) => {
    if (typeof window !== 'undefined') {
      logout(market)
        .then(() => {
          window.localStorage.removeItem('user');
          if (redirect) router.push(`/${market}`);
        })
        .catch(() => {
          window.localStorage.removeItem('user');
          if (redirect) router.push(`/${market}`);
        });
    }
  };

  const checkUserData = (market) => {
    if (typeof window !== 'undefined') {
      getUser(market)
        .then(({ data }) => {
          if (data && data?.customerId !== '_everyone') saveUser(data);
          else window.localStorage.removeItem('user');
        })
        .catch(() => {
          window.localStorage.removeItem('user');
        });
    }
  };

  return {
    user,
    setUser: saveUser,
    logoutUser: clearUserData,
    checkUser: checkUserData,
  };
}
