import React, { useEffect, useState, useRef } from 'react';
import styled, { css } from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';

import { Container } from 'components/Container/Container';
import { Icon } from 'components/Icon/Icon';
import { FlagComponent } from 'components/FlagComponent/FlagComponent';

import { COUNTRY_CODE_TO_MARKET } from 'constants/serverConstants';
import { ICONS } from 'constants/icons';

import * as GTM from 'helpers/gtm';

import { media } from 'styles/mixins';
import { fonts } from 'styles/typography';

import { setCartCountry } from 'api/cart';

import { setViewstate } from 'store/actions/actions';

const GeoLocationArea = () => {
  const URL = 'https://www.cloudflare.com/cdn-cgi/trace';

  const dispatch = useDispatch();

  const countrySelectRef = useRef();
  const languageSelectRef = useRef();

  const [userCountry, setUserCountry] = useState('');
  const [show, setShow] = useState(false);
  const [open, setOpen] = useState('');
  const [selectedCountry, setSelectedCountry] = useState('');
  const [selectedLanguage, setSelectedLanguage] = useState('');
  const [selectedMarket, setSelectedMarket] = useState({});

  const currentMarket = useSelector((state) => state?.viewState.market);
  const markets = useSelector((state) => state?.markets);
  const websiteStrings = useSelector(
    (state) => state?.bootstrap?.data?.websiteStrings
  );

  useEffect(() => {
    const fetchData = async () => {
      const data = await fetch(URL, { method: 'GET' });
      const text = await data.text();
      // eslint-disable-next-line no-useless-escape
      const parsedText = text.replace(/[\r\n]+/g, '","').replace(/\=+/g, '":"');
      const json = JSON.parse(
        `{"${parsedText.slice(0, parsedText.lastIndexOf('","'))}"}`
      );
      const countryCode = json.loc;
      setUserCountry(countryCode);
    };

    fetchData().catch(console.error);
  }, []);

  useEffect(() => {
    if (userCountry) {
      if (
        currentMarket !== COUNTRY_CODE_TO_MARKET[userCountry] &&
        !window.sessionStorage.getItem('marketSelection')
      ) {
        setShow(true);
        setSelectedCountry(userCountry.toLowerCase());
      } else {
        setShow(false);
      }
    }
  }, [userCountry]);

  useEffect(() => {
    dispatch(setViewstate('geolocationSelector', show));
  }, [show]);

  useEffect(() => {
    setSelectedLanguage('');
    if (markets.request.statusCode === 200)
      setSelectedMarket(
        markets?.data?.find((market) => market.countryCode === selectedCountry)
      );
  }, [selectedCountry, markets?.data]);

  useEffect(() => {
    if (selectedMarket?.languages && selectedMarket?.languages?.length === 1)
      setSelectedLanguage(selectedMarket?.languages[0].id);
  }, [selectedMarket]);

  const handleSubmit = (e) => {
    e.preventDefault();

    const pathName = window.location.pathname.split('/');
    pathName[1] = selectedLanguage;
    const newPathName = pathName.join('/');

    setCartCountry({
      market: selectedLanguage,
      countryCode: selectedCountry,
    }).then(() => {
      window.sessionStorage.setItem(
        'countryNativeName',
        markets?.data?.find((market) => market.countryCode === selectedCountry)
          .nativeName
      );
      window.sessionStorage.setItem('countryCode', selectedCountry);
      window.sessionStorage.setItem('marketSelection', selectedLanguage);

      GTM.changeMarketEvent({
        from: currentMarket,
        to: selectedLanguage,
        eventCallback() {
          if (
            window.location.href === `${window.location.origin}${newPathName}`
          )
            window.location.reload();
          else window.location.href = `${window.location.origin}${newPathName}`;
        },
      });
    });
  };

  const handleOptionClick = (code, target) => {
    if (!open) {
      setOpen(target);
    } else {
      setOpen('');

      if (target === 'country') {
        setSelectedCountry(code);
        countrySelectRef.current.scrollTop = 0;
      } else {
        setSelectedLanguage(code);
        languageSelectRef.current.scrollTop = 0;
      }
    }
  };

  return (
    show && (
      <GeoLocationWrapper show={show}>
        <GeoLocationTitleWrapper>
          <GeoLocationTitle>
            {websiteStrings?.geoLocationTitle || '{{geoLocationTitle}}'}
          </GeoLocationTitle>
          <GeoLocationDescription>
            {websiteStrings?.geoLocationDescription ||
              '{{geoLocationDescription}}'}
          </GeoLocationDescription>
        </GeoLocationTitleWrapper>
        <>
          <GeoLocationFormWrapper>
            {selectedCountry && (
              <GeoLocationForm onSubmit={handleSubmit}>
                <GeoLocationSelectWrapper>
                  <GeoLocationSelectIcon
                    icon={ICONS.ARROW}
                    size={14}
                    color="#000000"
                    open={open === 'country'}
                  />
                  <GeoLocationLabel>
                    {websiteStrings?.geoLocationCountryLabel ||
                      '{{geoLocationCountryLabel}}'}
                  </GeoLocationLabel>
                  <GeoLocationSelect
                    name="country"
                    open={open === 'country'}
                    ref={countrySelectRef}
                  >
                    <GeoLocationOptions>
                      {!!markets?.data &&
                        Array.isArray(markets.data) &&
                        markets?.data?.map(({ countryCode, countryName }) => (
                          <GeoLocationOption
                            key={countryCode}
                            value={countryCode}
                            active={countryCode === selectedCountry}
                            onClick={() =>
                              handleOptionClick(countryCode, 'country')
                            }
                          >
                            <GeoLocationFlag
                              countryCode={countryCode.toUpperCase()}
                            />
                            {countryName}
                          </GeoLocationOption>
                        ))}
                    </GeoLocationOptions>
                  </GeoLocationSelect>
                </GeoLocationSelectWrapper>
                {!!selectedMarket?.languages?.length &&
                  selectedMarket?.languages?.length > 1 && (
                    <GeoLocationSelectWrapper>
                      <GeoLocationSelectIcon
                        icon={ICONS.ARROW}
                        size={14}
                        color="#000000"
                        open={open === 'language'}
                      />
                      <GeoLocationLabel>
                        {websiteStrings?.geoLocationLanguageLabel ||
                          '{{geoLocationLanguageLabel}}'}
                      </GeoLocationLabel>
                      <GeoLocationSelect
                        name="language"
                        open={open === 'language'}
                        ref={languageSelectRef}
                      >
                        <GeoLocationOptions>
                          <GeoLocationOption
                            value=""
                            active={selectedLanguage === ''}
                            onClick={() => handleOptionClick('', 'language')}
                          >
                            {websiteStrings?.geoLocationLanguagePlaceholder ||
                              '{{geoLocationLanguagePlaceholder}}'}
                          </GeoLocationOption>
                          {selectedMarket.languages.map(({ title, id }) => (
                            <GeoLocationOption
                              key={id}
                              value={id}
                              active={id === selectedLanguage}
                              onClick={() => handleOptionClick(id, 'language')}
                            >
                              {title}
                            </GeoLocationOption>
                          ))}
                        </GeoLocationOptions>
                      </GeoLocationSelect>
                    </GeoLocationSelectWrapper>
                  )}
                <GeoLocationSubmitButton
                  disabled={!selectedLanguage || !selectedCountry}
                >
                  {websiteStrings?.geoLocationContinue ||
                    '{{geoLocationContinue}}'}
                </GeoLocationSubmitButton>
                <GeoLocationDesktopClose onClick={() => setShow(false)}>
                  <Icon icon={ICONS.CLOSE} size={14} color="#000000" />
                </GeoLocationDesktopClose>
              </GeoLocationForm>
            )}
          </GeoLocationFormWrapper>
          <GeoLocationClose onClick={() => setShow(false)}>
            <Icon icon={ICONS.CLOSE} size={14} color="#000000" />
          </GeoLocationClose>
        </>
      </GeoLocationWrapper>
    )
  );
};

GeoLocationArea.propTypes = {};

export default GeoLocationArea;

export const GeoLocationWrapper = styled(Container)`
  display: ${({ show }) => (show ? 'flex' : 'none')};
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;

  ${media.XL`
    flex-direction: row;
    align-items: center;
  `}
`;

export const GeoLocationTitleWrapper = styled.div``;

export const GeoLocationTitle = styled.h2`
  font-size: 22px;
  line-height: 150%;
  margin: 4px 0;
  font-weight: normal;
`;

export const GeoLocationDescription = styled.p`
  font-size: 16px;
  letter-spacing: 0.02em;
  margin: 4px 0 16px 0;
  ${fonts.GoudyOldStyleRegular};

  ${media.XL`
    margin: 4px 0;
  `};
`;

export const GeoLocationSelect = styled.div`
  border: 1px solid #e5e5e5;
  height: ${({ open }) => (open ? 'auto' : '50px')};
  overflow: ${({ open }) => (open ? 'scroll' : 'hidden')};
  max-height: 300px;
  width: 100%;
  cursor: pointer;
  background: white;
  right: 0;
  margin-top: 4px;
  margin-bottom: 20px;

  ${media.XL`
    margin-left: 16px;
    margin-bottom: 0;
    width: 280px;
  `};
`;

export const GeoLocationLabel = styled.label`
  ${media.XL`
    padding-left: 16px;
  `};
`;

export const GeoLocationFormWrapper = styled.div`
  position: relative;
  width: 100%;
`;

export const GeoLocationForm = styled.form`
  ${media.XL`
    position: absolute;
    display: flex;
    right: 0;
    z-index: 9999;
    transform: translateY(-35px);
  `};
`;

export const GeoLocationOptions = styled.div`
  display: flex;
  flex-direction: column;
`;

export const GeoLocationOption = styled.span`
  font-weight: 300;
  font-size: 14px;
  line-height: 50px;
  height: 50px;
  letter-spacing: 0.02em;
  order: ${({ active }) => (active ? 1 : 2)};
  user-select: none;
  padding-left: 16px;
`;

export const GeoLocationFlag = styled(FlagComponent)`
  width: 14px;
  height: auto;
  margin-right: 8px;
`;

export const GeoLocationSelectWrapper = styled.div`
  z-index: 999;
  position: relative;
`;

export const GeoLocationSelectIcon = styled(Icon)`
  position: absolute;
  right: 16px;
  transform: ${({ open }) => (open ? 'rotate(0deg)' : 'rotate(180deg)')};
  transition: 0.1s ease-out;
  width: 14px;
  z-index: 1;
  height: 10px;
  pointer-events: none;
  top: 42px;
`;

export const GeoLocationSubmitButton = styled.button`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 18px 32px;
  height: 50px;
  left: 0;
  top: 0;
  background: black;
  margin-top: 20px;
  border: none;
  outline: none;
  color: white;
  width: 100%;

  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
      cursor: crosshair;
      opacity: 0.5;
    `};

  ${media.XL`
    margin-left: 16px;
    width: auto;
  `};
`;

export const GeoLocationClose = styled.div`
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;

  ${media.XL`
    display: none;
  `};
`;

export const GeoLocationDesktopClose = styled.div`
  display: none;

  ${media.XL`
    margin-left: 16px;
    margin-top: 32px;
    display: block;
    cursor: pointer;
  `};
`;
