import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { get, reduce } from 'lodash/fp';
import { ICONS } from '../../constants/icons';
import { connect } from '../../store/store';
import { setViewstate } from '../../store/actions/actions';
import { Icon } from '../Icon/Icon';
import { CART } from '../../constants/modals';
import { CartLink, CartQuantity } from './styles';
import { Conditional } from '../Conditional/Conditional';

const CartButtonComponent = props => {
  const { cart } = props;

  const showModal = () => {
    props.setViewstate('activeModal', CART);
  };

  const numberOfProductsInCart = reduce((memory, item) => memory + get('quantity', item), 0, get('orderRows', cart));

  return (
    <Fragment>
      <CartLink onClick={() => showModal()}>
        <Icon icon={ICONS.CART} size={20} color="#000000" />
        <Conditional show={numberOfProductsInCart > 0}>
          <CartQuantity>
            <span>{numberOfProductsInCart}</span>
          </CartQuantity>
        </Conditional>
      </CartLink>
    </Fragment>
  );
};

CartButtonComponent.defaultProps = {
  cart: {}
};

CartButtonComponent.propTypes = {
  setViewstate: PropTypes.func.isRequired,
  cart: PropTypes.object
};

export const CartButton = connect(
  CartButtonComponent,
  { setViewstate },
  store => ({
    cart: get(['cart', 'data'], store)
  })
);
