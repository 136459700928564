import React from 'react';
import PropTypes from 'prop-types';

import { FullWidthContainer, ContainerRoot } from './styles';

export function Container(props) {
  if (props.wide && props.inner) {
    return (
      <FullWidthContainer color={props.color}>
        <ContainerRoot>{props.children}</ContainerRoot>
      </FullWidthContainer>
    );
  }

  if (props.wide) {
    return (
      <FullWidthContainer
        pt={props.pt}
        pb={props.pb}
        noMargin={props.noMargin}
        noPadding={props.noPadding}
        color={props.color}
        className={props.className || undefined}
      >
        {props.children}
      </FullWidthContainer>
    );
  }

  return (
    <ContainerRoot
      pt={props.pt}
      pb={props.pb}
      noMargin={props.noMargin}
      noMarginStrong={props.noMarginStrong}
      noPadding={props.noPadding}
      noRightPadding={props.noRightPadding}
      className={props.className || undefined}
    >
      {props.children}
    </ContainerRoot>
  );
}

Container.defaultProps = {
  wide: false,
  inner: false,
  color: null,
  noPadding: false,
  noRightPadding: false,
  noMargin: false,
  pt: null,
  pb: null,
  noMarginStrong: null,
  className: null
};

Container.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  wide: PropTypes.bool,
  inner: PropTypes.bool,
  color: PropTypes.string,
  noPadding: PropTypes.bool,
  noRightPadding: PropTypes.bool,
  noMargin: PropTypes.bool,
  noMarginStrong: PropTypes.bool,
  pt: PropTypes.string,
  pb: PropTypes.string,
  className: PropTypes.string
};
