/* eslint-disable react/prop-types */
import { useSearchModal } from '@depict-ai/react-ui';
import { get } from 'lodash/fp';

import { connect } from 'store/store';

import { Icon } from 'components/Icon/Icon';

import { ICONS } from 'constants/icons';

import { SCProps } from 'types';

import { SearchButtonContainer } from './styles';

type DepictSearchProps = {
  isMobile: boolean;
};

const TypedButtonContainer = SearchButtonContainer as SCProps<
  'button',
  {
    isMobile: boolean;
  }
>;

const DepictSearchComponent: React.FC<DepictSearchProps> = (props) => {
  const { isMobile } = props;

  const { open } = useSearchModal({
    location: 'centered',
  });

  return (
    <TypedButtonContainer isMobile={isMobile} onClick={open}>
      <Icon icon={ICONS.SEARCH} size={20} />
    </TypedButtonContainer>
  );
};

const DepictSearch = connect(DepictSearchComponent, {}, (store) => ({
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  isMobile: get('viewState.isMobile', store),
}));

export default DepictSearch;
