import React, { Component } from 'react';
import { get, compose } from 'lodash/fp';
import PropTypes from 'prop-types';
import { withCookies } from 'react-cookie';
import { connect } from '../../store/store';
import { Conditional } from '../Conditional/Conditional';
import { SubHeading } from '../SubHeading/SubHeading';
import { CheckboxHOC } from '../CheckboxHOC/CheckboxHOC';
import { LocalizedLink } from '../LocalizedLink/LocalizedLink';
import { String } from '../String/String';
import { axiosSingleton } from '../../api/houdini';
import * as GTM from '../../helpers/gtm';
import { voyadoContactId } from '../../helpers/voyado';
import {
  Root,
  PrivacyPolicyLink,
  CenteredRoot,
  InputGroup,
  NewsletterHtmlContainer,
  ValidationError,
  NewsletterInput,
  NewsletterButton
} from './styles';

class NewsletterSignupComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      submitted: false,
      emailInput: null,
      policyInput: false,
      emailInputErrorActive: false,
      policyInputErrorActive: false
    };

    this.onChangeHandler = this.onChangeHandler.bind(this);
  }

  componentDidMount() {
    if (this.props.openInModal) {
      this.props.cookies.set('NEWSLETTER_SHOWED', true, {
        maxAge: 60 * 60 * 24 * 365 // 1 year, time is set in seconds
      });
    }
  }

  onChangeHandler(event) {
    const { target } = event;
    const { name, value, checked, type } = target;

    const valid = target.checkValidity();

    const newValue = type === 'checkbox' ? checked : value;

    this.toggleInputValidationError(name, true);

    this.setState(() => ({
      [name]: newValue,
      [`${name}Valid`]: valid
    }));
  }

  onSubmitHandler(event) {
    const { market } = this.props;
    const { emailInputValid, policyInputValid, emailInput } = this.state;
    event.preventDefault();

    if (emailInputValid && policyInputValid) {
      axiosSingleton.post(`/forms/newsletter-signup`, { email: emailInput }, { params: { market } }).then(response => {
        if (response.status === 200) {
          GTM.newsletterSignupEvent();
          voyadoContactId(response?.data?.contactId);
          this.setState(() => ({ submitted: true }));
        }
      });
    } else {
      this.toggleInputValidationError('emailInput', emailInputValid);
      this.toggleInputValidationError('policyInput', policyInputValid);
    }
  }

  toggleInputValidationError(inputName, inputValidation) {
    this.setState(() => ({ [`${inputName}ErrorActive`]: !inputValidation }));
  }

  render() {
    const { bootstrap, heading, body } = this.props;
    const { newsletter, websiteStrings } = bootstrap;
    const { privacyPolicyPage } = newsletter;
    const { emailInputErrorActive, policyInputErrorActive, submitted } = this.state;

    if (submitted) {
      return (
        <CenteredRoot>
          <SubHeading mt={0} center>
            {get('signupSuccessMessage', newsletter)}
          </SubHeading>
        </CenteredRoot>
      );
    }

    return (
      <Root>
        <SubHeading mt={0} center>
          {heading}
        </SubHeading>

        <NewsletterHtmlContainer dangerouslySetInnerHTML={{ __html: body }} />

        <form onSubmit={event => this.onSubmitHandler(event)} noValidate>
          <InputGroup>
            <NewsletterInput
              placeholder={get('emailFormLabel', websiteStrings)}
              name="emailInput"
              id="emailId"
              type="email"
              required
              onChange={event => this.onChangeHandler(event)}
            />
            <Conditional show={emailInputErrorActive}>
              <ValidationError>
                <String id="emailInputValidationText" />
              </ValidationError>
            </Conditional>
          </InputGroup>

          <InputGroup>
            <CheckboxHOC
              required
              id={`policyInput-${this.props.type}`}
              name="policyInput"
              checked={this.state.policyInput}
              onChange={event => this.onChangeHandler(event)}
            >
              <span>
                {get('checkboxText', newsletter)}
                <LocalizedLink
                  as={get('id', privacyPolicyPage)}
                  data={{ id: get('id', privacyPolicyPage) }}
                  page={get('type', privacyPolicyPage)}
                >
                  <PrivacyPolicyLink>{get('privacyPolicyLinkText', newsletter)}</PrivacyPolicyLink>
                </LocalizedLink>
              </span>
            </CheckboxHOC>
            <Conditional show={policyInputErrorActive}>
              <ValidationError>
                <String id="policyInputValidationText" />
              </ValidationError>
            </Conditional>
          </InputGroup>

          <NewsletterButton>
            <String id="newsletterSubscriptionButtonLabel" />
          </NewsletterButton>
        </form>
      </Root>
    );
  }
}

NewsletterSignupComponent.defaultProps = {
  bootstrap: {},
  heading: undefined,
  body: undefined,
  openInModal: false,
  type: 'default'
};

NewsletterSignupComponent.propTypes = {
  cookies: PropTypes.object.isRequired,
  bootstrap: PropTypes.object,
  heading: PropTypes.string,
  body: PropTypes.string,
  market: PropTypes.string.isRequired,
  openInModal: PropTypes.bool,
  type: PropTypes.string
};

export const NewsletterSignup = compose(withCookies, connect)(NewsletterSignupComponent, {}, store => ({
  bootstrap: get(['bootstrap', 'data'], store),
  market: get(['viewState', 'market'], store)
}));
