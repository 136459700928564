import styled, { css } from 'styled-components';
import { fonts } from '../../styles/typography';
import { hover, media } from '../../styles/mixins';
import { RocketImage } from '../RocketImage/RocketImage';

export const ProductCardRoot = styled.div`
  position: relative;
  width: 100%;
  text-align: center;
  margin: 20px 0;
  color: ${(props) => props.theme.color.black};
`;

export const ProductCardImage = styled(RocketImage)``;

export const ProductCardImageHideOnHover = styled(ProductCardImage)`
  opacity: 1;
  transition: opacity 300ms ease-out;
`;

export const ProductCardImageHover = styled(RocketImage)`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  opacity: 0;
  transform: scale(1.025);
  transition: opacity 600ms ease-out, transform 600ms ease-out;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;

  &:hover {
    opacity: 1;
  }
`;

export const SustainabilityIconsContainer = styled.ul`
  position: absolute;
  top: 10px;
  right: 10px;
  list-style: none;
  margin: 0;
  padding: 0;
`;

export const SustainabilityIcon = styled.li`
  padding: 4px 0;

  @media screen and (min-width: 620px) {
    padding: 6px 4px;
  }

  > img {
    pointer-events: none;
  }
`;

export const SizeAndStockContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  background-color: ${(props) => props.theme.color.lightGrey};
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 6px 10px;
  transform: translateY(100%);
  transition: transform 300ms ease-in-out;

  & > div {
    text-align: left;
    display: flex;
    justify-content: center;
    width: 100%;

    ${media.M`
					width: auto;
				`};
  }

  & > p {
    ${fonts.NeoSansLight};
    font-size: 12px;
    margin: 0.5em 0.5em 0.5em 0;
    font-weight: 300;
    display: none;
  }

  ${({ showSize }) =>
    showSize &&
    css`
      transform: translateY(0);
    `};

  ${media.M`
				& > p {
					display: block;
				}
		`};

  ${media.L`
				transform: translateY(100%);
		`};
`;

export const SizeColor = styled.span`
  display: none;

  ${media.L`
			display: inline-block;
		`};
`;

export const SizeAndStock = styled.p`
  ${fonts.NeoSansLight};
  display: inline-block;
  padding: 0.25em 0.5em 0.25em 0.5em;
  margin: 0;
  font-size: 10px;
  font-weight: 300;

  ${media.XS`
    font-size: 11px;
    padding: 0.3em .5em 0.3em .5em;
  `};

  ${media.S`
    font-size: 12px;
    padding: 0.5em .75em 0.5em .75em;
  `};

  &:first-of-type {
    padding-left: 0;
  }

  ${(props) =>
    props.stock <= 0 &&
    css`
      text-decoration: line-through;
      color: #8d8d8d;
    `};
`;

export const ProductCardTitle = styled.a`
  ${fonts.NeoSansRegular};
  color: ${(props) => props.theme.color.black};
  text-decoration: none;
  font-size: 16px;
  line-height: 1.25;
  margin: 16px 10px 12px;
  letter-spacing: normal;
  text-align: center;
  cursor: pointer;
  display: inline-block;
  transition: opacity 250ms ease-in-out;
  min-height: 20px;

  ${hover`
    opacity: .5;
  `};
`;

export const ProductCardDescription = styled.p`
  ${fonts.GoudyOldStyleRegular};
  min-height: 3em;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  font-family: goudy-old-style, Georgia, serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 1.43;
  margin: 0 auto 7px;
  max-width: 320px;
  overflow: hidden;
  overflow-wrap: anywhere;
  text-align: center;
  text-overflow: ellipsis;
  white-space: pre-line;
  width: 100%;
  word-break: break-word;
`;

export const ProductCardLabelContainer = styled.div`
  position: absolute;
  top: -12px;
  left: 11px;
  z-index: 100;
  text-align: left;
  max-height: 26px;
  overflow: hidden;
`;

export const ProductCardLabel = styled.p`
  display: inline-block;
  ${fonts.NeoSansRegular};
  background-color: #cabfb1;
  padding: 6px 10px 5px;
  margin: 0 4px 4px;
  font-size: 12px;
  line-height: normal;
  letter-spacing: normal;
  color: ${(props) => props.theme.color.white};

  ${({ color }) => {
    if (color && color.toLowerCase() === 'red') {
      return css`
        background-color: ${(props) => props.theme.color.red};
      `;
    }

    if (color && color.toLowerCase() === 'green') {
      return css`
        background-color: ${(props) => props.theme.color.activeGreen};
      `;
    }

    return null;
  }}
`;

export const ProductCardPrice = styled.p`
  ${fonts.NeoSansRegular};
  display: inline-block;
  margin: 3px 5px;
  font-size: 13px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  min-height: 17px;

  ${(props) =>
    props.oldPrice &&
    css`
      text-decoration: line-through;
      color: ${props.theme.color.superGrey};
    `};
`;

export const RadioButtons = styled.div`
  margin: 13px auto 15px;
  max-width: 250px;
  min-height: 21px;
  @media screen and (min-width: 621px) {
    max-width: 200px;
  }
`;

export const RadioButtonContainer = styled.form`
  position: relative;
  height: auto;
  margin: 0 -10px -5px 0;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;
  > div {
    display: block;
    > div {
      display: block;
    }
  }
  input {
    + label {
      display: block;
      margin: 0 10px 5px 0 !important;
      padding: 0 20px 20px 0 !important;
      @media screen and (min-width: 621px) {
        padding: 0 16px 16px 0 !important;
      }
      &:before,
      &:after {
        top: 0 !important;
        left: 0 !important;
      }
    }
  }
`;

export const ProductCardImageContainer = styled.a`
  color: ${(props) => props.theme.color.black};
  text-decoration: none;
  display: block;
  width: 100%;
  height: auto;
  position: relative;
  cursor: pointer;
  overflow: hidden;
  background-color: ${(props) => props.theme.color.productImageBackground};
  font-size: 0;

  ${hover`
    ${SizeAndStockContainer} {
      transform: translateY(0);
    }

    ${ProductCardImageHideOnHover} {
      opacity: 0;
    }

    ${ProductCardImageHover} {
      opacity: 1;
      transform: scale(1);
    }
  `};
`;
