import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash/fp';
import { connect } from '../../store/store';
import { Modal } from '../Modal/Modal';
import { LocalizedLink } from '../LocalizedLink/LocalizedLink';
import { NavigationDrawer } from '../NavigationDrawer/NavigationDrawer';
import { LogoSvg } from '../Icon/reactIcons';
import { setViewstate, removeViewstate } from '../../store/actions/actions';
import { MOBILE_MENU } from '../../constants/modals';
import DepictSearch from './DepictSearch';
import { FadeIn } from '../../styles/animations';
import { CartButton } from './CartButton';
import {
  MobileContainer,
  NavBurger,
  NavigationButton,
  LogoLink,
  LogoContainer,
  SearchAndCartAreaMobile,
} from './styles';
import { WiggleAnimation } from '../WiggleAnimation/WiggleAnimation';

class HeaderMobileComponent extends Component {
  constructor(props) {
    super(props);

    this.searchRef = React.createRef();
  }

  toggleMobileMenu() {
    if (get('activeModal', this.props) === MOBILE_MENU) {
      this.closeMobileMenu();
    } else {
      this.props.setViewstate('activeModal', MOBILE_MENU);
    }
  }

  closeMobileMenu() {
    this.props.removeViewstate('activeModal');
  }

  render() {
    const {
      navigation,
      scrollAtTop,
      country,
      language,
      activeModal,
      campaignCodeBannerActive,
      myPagesEnabled,
    } = this.props;
    const { mobileMainMenu, mobileMainMenuSecondary } = navigation;

    return (
      <div style={{ height: '60px' }}>
        <MobileContainer>
          <NavigationButton
            onClick={() => this.toggleMobileMenu()}
            active={activeModal === MOBILE_MENU}
          >
            <WiggleAnimation>
              <NavBurger />
            </WiggleAnimation>
          </NavigationButton>

          <Modal
            onClose={() => this.closeMobileMenu()}
            in={activeModal === MOBILE_MENU}
            slideIn
            fromLeft
            mobileMenu
            belowTop={!scrollAtTop}
            campaignCodeBannerActive={campaignCodeBannerActive}
          >
            <NavigationDrawer
              items={mobileMainMenu}
              topBarPages={mobileMainMenuSecondary}
              country={country}
              language={language}
              myPagesEnabled={myPagesEnabled}
            />
          </Modal>

          <LocalizedLink as="" page="StartPage" data={{ id: '' }}>
            <LogoLink>
              <LogoContainer isMobile simplified belowTop={!scrollAtTop}>
                <LogoSvg width="100%" height="25px" />
              </LogoContainer>
            </LogoLink>
          </LocalizedLink>

          <SearchAndCartAreaMobile>
            <FadeIn
              in={activeModal !== MOBILE_MENU}
              appear
              timeout={300}
              nodeRef={this.searchRef}
            >
              <DepictSearch />
              <CartButton />
            </FadeIn>
          </SearchAndCartAreaMobile>
        </MobileContainer>
      </div>
    );
  }
}

HeaderMobileComponent.defaultProps = {
  navigation: {},
  scrollAtTop: true,
  country: null,
  language: null,
  activeModal: null,
  campaignCodeBannerActive: false,
  myPagesEnabled: false,
};

HeaderMobileComponent.propTypes = {
  setViewstate: PropTypes.func.isRequired,
  removeViewstate: PropTypes.func.isRequired,
  navigation: PropTypes.object,
  scrollAtTop: PropTypes.bool,
  country: PropTypes.string,
  language: PropTypes.string,
  activeModal: PropTypes.string,
  campaignCodeBannerActive: PropTypes.bool,
  myPagesEnabled: PropTypes.bool,
};

export const HeaderMobile = connect(
  HeaderMobileComponent,
  { setViewstate, removeViewstate },
  (store) => ({
    country: get('viewState.country', store),
    language: get('viewState.language', store),
    activeModal: get('viewState.activeModal', store),
    navigation: get(['bootstrap', 'data', 'navigation'], store),
  })
);
